import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { ButtonIcon } from '../../../../../components/ModalStatus/ModalStatus.style';
import CloseIcon from '@mui/icons-material/Close';
import { SeuTipoDeRegistro } from './AgendadaModal';

interface PendenteModalProps {
  open: boolean;
  onClose: () => void;
  registro: SeuTipoDeRegistro;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 577,
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 24,
  padding: '16px 24px',
};

function NaoAtendida({ open, onClose, registro }: PendenteModalProps) {
  const nome = registro?.pessoa?.nome;
  const mudou_endereco = registro?.pessoa?.conv_mudou_endereco;
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={onClose}
    >
      <Box sx={style}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h6"
            fontWeight="bold"
          >
            Busca ativa
          </Typography>
          <ButtonIcon onClick={onClose}>
            <CloseIcon fontSize="small" style={{ color: '#BDBDBD' }} />
          </ButtonIcon>
        </Box>
        <Box
          style={{
            border: '1px solid #bdbdbd',
            borderRadius: '8px',
            padding: '16px',
            marginBottom: '20px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
            <img src={'/exams.svg'} alt="exams" width={50} />
            <Typography fontWeight={700} fontSize={20}>
              {nome}
            </Typography>
          </Box>
          <Divider style={{ marginTop: 10 }} />
          <Typography fontWeight={400} fontSize={16} mt={2} mb={0}>
            Resultados da convocação <span style={{ color: '#f88078' }}>*</span>
          </Typography>
          <FormControl>
            <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
              <FormControlLabel
                disabled
                style={{ marginRight: '14px' }}
                value="sim"
                control={<Radio style={{ fontSize: '14px', padding: '3px' }} />}
                label={<Typography fontSize={16}>Agendada</Typography>}
              />
              <FormControlLabel
                checked
                disabled
                value="nao"
                control={<Radio />}
                label={<Typography fontSize={16}>Não atendida</Typography>}
              />
              <FormControlLabel
                disabled
                value="nao"
                control={<Radio />}
                label={<Typography fontSize={16}>Não inelegível</Typography>}
              />
            </RadioGroup>
          </FormControl>
          <Typography fontWeight={400} fontSize={16} mt={2} mb={0}>
            Motivo <span style={{ color: '#f88078' }}>*</span>
          </Typography>
          <FormControl>
            <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
              <FormControlLabel
                disabled
                style={{ marginRight: '14px' }}
                value="sim"
                control={<Radio style={{ fontSize: '14px', padding: '3px' }} />}
                label={
                  <Typography fontSize={16}>Não estava em casa</Typography>
                }
              />
              <FormControlLabel
                disabled
                value="nao"
                control={<Radio style={{ fontSize: '14px', padding: '3px' }} />}
                label={<Typography fontSize={16}>Não atendeu</Typography>}
              />
              <FormControlLabel
                disabled
                checked={mudou_endereco === 'sim' || mudou_endereco === null}
                value={mudou_endereco}
                control={<Radio style={{ fontSize: '14px', padding: '3px' }} />}
                label={<Typography fontSize={16}>Mudou de endereço</Typography>}
              />
            </RadioGroup>
          </FormControl>
          <TextField
            multiline
            style={{ marginTop: '28px' }}
            sx={{ width: '100%' }}
            label="Observação adicional"
            disabled
          />
        </Box>
        <Box></Box>
      </Box>
    </Modal>
  );
}

export default NaoAtendida;
