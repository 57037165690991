import { useState } from 'react';
import { CardDetailPatientConvocation } from '../CardDetailPatientConvocation';

export const ListHistoric = ({ data }) => {
  const [openedCard, setOpenedCard] = useState<any>(null);

  const listStatus1 = data.filter((item) => item.status === 1);
  const listStatus2 = data.filter((item) => item.status === 2);
  const listStatus3 = data.filter((item) => item.status === 3);
  const listStatus4 = data.filter((item) => item.status === 4);

  const colorBorder = {
    1: '#4CAF50',
    2: '#2196F3',
    3: '#FFB547',
    4: '#F44336',
  };
  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', width: '100%' }}>
        <div
          style={{
            width: '16px',
            marginBottom: '24px',
            marginLeft: '32px',
            marginRight: '24px',
            borderRadius: '19px',
            backgroundColor: colorBorder[1],
          }}
        />
        <div style={{ width: '100%' }}>
          {listStatus1.map((item) => {
            return (
              <CardDetailPatientConvocation
                convocation={item.convocation}
                cpf={item.cpf}
                dtAgendamento={item.dtAgendamento}
                dtResult={item.dtResult}
                patientName={item.patientName}
                status={item.status}
                opened={openedCard === item.index}
                setOpenedCard={setOpenedCard}
                index={item.index}
              />
            );
          })}
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <div
          style={{
            width: '16px',
            marginBottom: '24px',
            marginLeft: '32px',
            marginRight: '24px',
            borderRadius: '19px',
            backgroundColor: colorBorder[2],
          }}
        />

        <div style={{ width: '100%' }}>
          {listStatus2.map((item) => {
            return (
              <CardDetailPatientConvocation
                convocation={item.convocation}
                cpf={item.cpf}
                dtAgendamento={item.dtAgendamento}
                attendedAt={item.attendedAt}
                patientName={item.patientName}
                status={item.status}
                opened={openedCard === item.index}
                setOpenedCard={setOpenedCard}
                index={item.index}
              />
            );
          })}
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <div
          style={{
            width: '16px',
            marginBottom: '24px',
            marginLeft: '32px',
            marginRight: '24px',
            borderRadius: '19px',
            backgroundColor: colorBorder[3],
          }}
        />
        <div style={{ width: '100%' }}>
          {listStatus3.map((item) => {
            return (
              <CardDetailPatientConvocation
                cpf={item.cpf}
                patientName={item.patientName}
                status={item.status}
                opened={openedCard === item.index}
                setOpenedCard={setOpenedCard}
                index={item.index}
                estado={item.estado}
                municipio={item.municipio}
                estabelecimento={item.estabelecimento}
              />
            );
          })}
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <div
          style={{
            width: '16px',
            marginBottom: '24px',
            marginLeft: '32px',
            marginRight: '24px',
            borderRadius: '19px',
            backgroundColor: colorBorder[4],
          }}
        />
        <div style={{ width: '100%' }}>
          {listStatus4.map((item) => {
            return (
              <CardDetailPatientConvocation
                cpf={item.cpf}
                patientName={item.patientName}
                status={item.status}
                opened={openedCard === item.index}
                setOpenedCard={setOpenedCard}
                index={item.index}
                convocation={item.convocation}
                resultConvocation={item.resultConvocation}
                confirmPresent={item.confirmPresent}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
