import { Box, Grid, Paper, Typography } from '@mui/material';
import { AreaChart } from '../components/areaChart';
import { ChartCard } from '../components/chartCard';
import { SampleStatus } from '../components/sampleStatus';
import { TableWithCharts } from '../components/tableWithCharts';
import { TypesOfViruses } from '../components/typesOfViruses';
import { WomenOutsideGroup } from '../components/womenOutsideGroup';
import { getWindowDimensions } from '../../../../utils/getWindowDimensions';
import { useEffect, useState } from 'react';
import { TableMobile } from '../components/tableWithCharts/tableMobile';
import FilterIndicadores from '../components/filters/FilterIndicadores';
import { useForm } from 'react-hook-form';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';
import api from '../../../../api';
import moment from 'moment';

const sampleStatusData = [0, 0, 0];
// const womenOutsideGroupData = [35, 2916];

const today = moment().format('YYYY-MM-DD');
const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'regiao', value: '' },
  {
    inputName: 'periodo',
    value: `Período: ${moment()
      .subtract(12, 'months')
      .format('DD/MM/YYYY')} a ${moment().format('DD/MM/YYYY')}`,
  },
];

export function Indicadores() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [regiaoLabel, setRegiaoLabel] = useState('');

  const [listFilter, setListFilter] = useState<any>(initialStateListFilter);
  const [listFilterShow, setListFilterShow] = useState<any>(
    initialStateListFilter
  );
  const [isMobile, setIsMobile] = useState(false);
  const [needsRequiredFilters, setNeedsRequiredFilters] = useState(true);

  const { control, watch, setValue } = useForm();
  const [totalTestsPerMonth, setTotalTestsPerMonth] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [womenOutsideGroupData, setwomenOutsideGroupData] = useState<any>([]);
  const [examResultsChartData, setExamResultsChartData] = useState<any>([]);
  const [examCollectionOriginData, setExamCollectionOriginData] = useState<any>(
    []
  );
  const [period, setPeriod] = useState<any>({
    periodo_fim: today,
    periodo_inicio: twelveMonths,
  });
  const [datesLabel, setDatesLabel] = useState('Últimos 12 meses');
  const [metas, setMetas] = useState<any>();

  const [payload, setPayload] = useState<any>({
    params: {
      periodo_inicio: twelveMonths,
      periodo_fim: today,
    },
  });

  const [detectableAndVirusTypeData, setdetectableAndVirusTypeData] =
    useState<any>([]);
  const [reasonsNotProcedureChartData, setreasonsNotProcedureChartData] =
    useState<any>([]);

  const getTestesPerMonth = async (payload) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/quantity-month',
      payload
    );

    if (response.data.data.length === 0) {
      setCategories(['NÃO HÁ DADOS']);
    } else {
      setCategories(
        response.data?.data.map((item) => String(item?.month)?.toUpperCase())
      );
    }

    setTotalTestsPerMonth(
      response.data?.data.map((item) => Number(item?.count))
    );
  };

  const getAgeRange = async (payload) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/faixa',
      payload
    );

    setwomenOutsideGroupData([
      Number(response?.data?.data?.[2]?.count),
      Number(response?.data?.data?.[1]?.count) +
        Number(response?.data?.data?.[0]?.count) +
        Number(response?.data?.data?.[3]?.count),
    ]);
  };

  const getexamCollectionOrigin = async (payload) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/natureza',
      payload
    );

    setExamCollectionOriginData([
      Number(response.data.data.autocoleta),
      Number(response.data.data.profissional),
    ]);
  };

  const getExamResultsChart = async (payload) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/resultados',
      payload
    );

    setExamResultsChartData([
      Number(response.data.data.NEGATIVO_INDETECTADO),
      Number(response.data.data.POSITIVO),
      Number(response.data.data.INVALIDO),
    ]);
  };

  const getdetectableAndVirusTypeData = async (payload) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/resultados-tipos',
      payload
    );

    setdetectableAndVirusTypeData([
      Number(response.data.data.hpv_16),
      Number(response.data.data.hpv_18),
      Number(response.data.data.hpv_outros),
      Number(response.data.data['hpv_16-18-outros']),
      Number(response.data.data.invalido),
      Number(response.data.data.negativo),
    ]);
  };

  const getreasonsNotProcedure = async (payload) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/nao-realizados',
      payload
    );

    setreasonsNotProcedureChartData([
      Number(response.data.data.NAO_REALIZOU),
      Number(response.data.data.MENSTRUADA),
      Number(response.data.data.NAO_FAIXA_ETARIA),
      Number(response.data.data.HISTERECTOMIZADA),
      Number(response.data.data.OUTRO),
    ]);
  };

  const getMetas = async (payload) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/metas',
      payload
    );

    let list: any = [];

    Object.keys(response.data.data).forEach((cityName) => {
      const objName = {
        ...response.data.data[cityName],
        municipio: cityName,
      };

      list.push(objName);
    });

    setMetas(list);
  };

  const getAllCharts = ({ payload }) => {
    setListFilterShow(listFilter);

    setwomenOutsideGroupData([]);
    setExamResultsChartData([]);
    setExamCollectionOriginData([]);

    setdetectableAndVirusTypeData([]);
    setreasonsNotProcedureChartData([]);

    getTestesPerMonth(payload);
    getAgeRange(payload);
    getexamCollectionOrigin(payload);
    getExamResultsChart(payload);
    getdetectableAndVirusTypeData(payload);
    getreasonsNotProcedure(payload);
    getMetas(payload);
  };

  useEffect(() => {
    getAllCharts({
      payload,
    });
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    if (windowDimensions.width < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  return (
    <Box mt="31px">
      <Box sx={{ marginBottom: '42px' }}>
        <FilterIndicadores
          setRegiaoLabel={setRegiaoLabel}
          setValue={setValue}
          setPayload={setPayload}
          setListFilter={setListFilter}
          setListFilterShow={setListFilterShow}
          watch={watch}
          control={control}
          getAllCharts={getAllCharts}
          payload={payload}
          initialStateListFilter={initialStateListFilter}
          setDatesLabel={setDatesLabel}
          setPeriod={setPeriod}
          period={period}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="2px">
        <Typography variant="h5" fontWeight="bold" fontSize="24px">
          Testes de HPV - Indicadores
        </Typography>
        <Typography variant="h6" fontWeight="bold" fontSize="20px">
          Período: {datesLabel}
        </Typography>

        <DisplayFilter style={{ marginTop: '17px', marginBottom: '37px' }}>
          <p style={{ margin: 0, width: '138px' }}>Filtros aplicados:</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              gap: '10px',
              flexWrap: 'wrap',
            }}
          >
            {listFilterShow?.map((item) => (
              <>
                {item.value ? (
                  <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                ) : undefined}
              </>
            ))}
          </div>
        </DisplayFilter>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" fontSize="48px" mb={2}>
            {regiaoLabel}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Paper
            variant="outlined"
            sx={{
              paddingTop: '16px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              height: '512px',
              overflowX: 'scroll',
              overflowY: 'hidden',
            }}
          >
            <Typography variant="h5" fontWeight="bold" pl="15px">
              {regiaoLabel}
              <br />
              Total de Amostras Coletadas
            </Typography>
            <Typography
              variant="h5"
              fontWeight="bold"
              pl="15px"
              fontSize="76px"
            >
              {totalTestsPerMonth
                ?.reduce((prev, curr) => prev + curr, 0)
                .toLocaleString('pt-BR')}
            </Typography>
            <AreaChart
              data={totalTestsPerMonth}
              categories={categories}
              height={240}
              payload={payload}
              needsRequiredFilters={needsRequiredFilters}
            />
            <span
              style={{
                fontSize: '10px',
                textAlign: 'end',
                // marginTop: '14px',
                marginRight: '16px',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            >
              Fonte de Dados: JORDANA
            </span>
          </Paper>
        </Grid>
        {/* <Grid item xs={12} lg={6}>
          <ChartCard title={regiaoLabel ?? ''} subtitle="Situação das Amostras">
            <SampleStatus data={sampleStatusData} />
          </ChartCard>
        </Grid> */}
        <Grid item xs={12} lg={6}>
          <ChartCard title={regiaoLabel ?? ''} subtitle="Tipos de Vírus">
            <TypesOfViruses data={detectableAndVirusTypeData} />
          </ChartCard>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ChartCard
            title={regiaoLabel ?? ''}
            subtitle="Mulheres Fora da Faixa Etária"
          >
            <WomenOutsideGroup data={womenOutsideGroupData} />
          </ChartCard>
        </Grid>
      </Grid>

      <Box mt="28px">
        {isMobile ? (
          <TableMobile data={metas} />
        ) : (
          <TableWithCharts data={metas} />
        )}
      </Box>
    </Box>
  );
}
