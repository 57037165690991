import { Paper } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const mock1 = [2, 55, 14, 8, 62, 40, 35, 47, 59, 37, 37, 3, 9];
const mock2 = [2, 55, 14, 8, 62, 40, 35, 47, 59, 37, 37, 3, 9];
const mock3 = [2, 55, 14, 8, 62, 40, 35, 47, 59, 37, 37, 3, 9];
const mock4 = [2, 55, 14, 8, 62, 40, 35, 47, 59, 37, 37, 3, 9];

const mockCategories = [
  ['DEZ', '2023'],
  ['JAN', '2024'],
  'FEV',
  'MAR',
  'ABR',
  'MAI',
  'JUN',
  'JUL',
  'AGO',
  'SET',
  'OUT',
  'NOV',
  'DEZ',
];

export const TotalChart = () => {
  const data1 = mock1;
  const data2 = mock2;
  const data3 = mock3;
  const data4 = mock4;
  const categories = mockCategories;
  const [chartData, setChartData] = useState<any>();

  const series = [
    {
      name: '1º Convocação',
      data: data1,
      color: '#357A38',
    },
    {
      name: '2º Convocação',
      data: data2,
      color: '#3B873E',
    },
    {
      name: '3º Convocação',
      data: data3,
      color: '#4CAF50',
    },
    {
      name: 'Outras Convocações',
      data: data4,
      color: '#7BC67E99',
    },
  ];

  useEffect(() => {
    // if (data && categories.length > 0) {

    setChartData({
      series,
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
      },
      chart: {
        type: 'area',
        height: 350,
        stacked: true,
        events: {
          // click: handleBarClick,
          // toolbarButtonClicked: (event, chartContext, config) => {
          //   // Identificar qual ferramenta foi clicada
          //   const toolName = config.tool;
          //   handleToolbarClick(toolName);
          // },
        },

        toolbar: {
          show: true,
          tools: {
            download: false,
            zoom: true,
            zoomin: true,
            zoomout: true,
            reset: true,
          },
        },
        sparkline: {
          enabled: false,
        },
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories,
        labels: {
          style: {
            fontSize: '12px',
          },

          minHeight: 40,
        },
      },
      // colors: series.map((item) => item.color),
      colors: ['#357b38', '#3B873E', '#4CAF50', '#7BC67E99'],
      grid: {
        show: true,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 10,
        },
      },
      yaxis: {
        tickAmount: 5,
        min: 0,
        labels: {
          show: true,
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      fill: {
        type: 'solid',
        opacity: 0.4,
      },
      // responsive: [
      //   {
      //     breakpoint: 768,
      //     options: {
      //       xaxis: {
      //         labels: {
      //           // minHeight: '60px',
      //           style: {
      //             fontSize: '5px',
      //           },
      //         },
      //       },
      //     },
      //   },
      // ],
    });
    // }
  }, [categories]);

  return (
    <>
      <Paper
        variant="outlined"
        sx={{ padding: '14px', marginTop: '24px', borderColor: '#BBC5CA' }}
      >
        {chartData && !!categories.length ? (
          <Chart
            options={chartData}
            series={chartData.series}
            type={chartData?.chart?.type || 'area'}
            height={310}
          />
        ) : undefined}
      </Paper>
      <p
        style={{
          fontFamily: 'Roboto',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '12px',
          textAlign: 'end',
          color: '#00000061',
          marginTop: '24px',
        }}
      >
        1-3 Convocação: Convocação por Autoatendimento <br />
        Outras Convocações: Convocação por Profissionais de Saúde
      </p>
    </>
  );
};
