import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { CustomizedFunnelBar } from '../../../../../../../components/Charts/customizedFunnelBar';
import { Paper, Typography } from '@mui/material';

interface FunnelChartProps {
  data: { x: string; y: number }[];
}

export function PatientConfirmationChart() {
  const [chartData, setChartData] = useState<ApexOptions>();
  const data = [
    {
      x: 'Confirmaram o Agendamento',
      y: 242,
    },
    {
      x: 'Não Confirmaram o Agendamento',
      y: 174,
    },
    {
      x: 'Reagendaram',
      y: 9,
    },
    {
      x: 'Não Responderam',
      y: 239,
    },
  ];

  useEffect(() => {
    if (data.length === 0 || data.every((item) => item.y === 0)) {
      setChartData(undefined);
      return;
    }

    const sortedData = [...data].sort((a, b) => b.y - a.y);
    const maxValue = Math.max(...sortedData.map((item) => item.y));

    setChartData({
      series: [
        {
          name: 'Quantidade de lesões ',
          data: sortedData.map((item) => item.y),
        },
      ],
      colors: ['#238884', '#F44336', '#3F51B5'],
      chart: {
        type: 'bar',

        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          barHeight: '62%',
          isFunnel: true,
          isFunnel3d: true,

          colors: {
            backgroundBarColors: ['#238884', '#F44336', '#3F51B5', '#9E9E9E'],
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
          const label = sortedData[opt.dataPointIndex].x;
          const value = Number(val);
          const percentage = (value / maxValue) * 100;

          // Função para truncar o texto
          const truncateText = (text: string, maxLength: number) => {
            if (text.length <= maxLength) return text;
            const truncated = text.substr(0, maxLength - 3) + '...';
            return truncated;
          };

          // Estima o número de caracteres que cabem na barra
          const estimatedCharsFit = Math.floor(percentage * 0.8);

          // Decide se deve truncar o texto
          let finalLabel;
          if (estimatedCharsFit < label.length) {
            finalLabel = truncateText(label, Math.max(3, estimatedCharsFit));
          } else {
            finalLabel = label;
          }

          return `${finalLabel} (${value})`;
        },
        dropShadow: {
          enabled: false,
        },
        style: {
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 'bold',
          fontSize: '16px',
          colors: ['#FFFFFF'],
        },
      },
      fill: {
        colors: ['#238884', '#F44336', '#3F51B5'],
      },
      stroke: {
        show: false,
        // width: 1,
        // colors: ['#F44336'],
      },
      xaxis: {
        categories: sortedData.map((item) => item.x),
      },
      grid: {
        padding: {
          left: 16,
          right: 16,
        },
      },
      legend: {
        show: false,
      },
    });
  }, []);

  const dataGraph = [
    { label: 'Confirmaram o Agendamento', total: 2500 },
    { label: 'Não Confirmaram o Agendamento', total: 1750 },
    { label: 'Reagendaram', total: 800 },
    { label: 'Não Responderam', total: 30 },
  ];

  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: '10px',
        borderColor: '#BBC5CA',
        marginTop: '24px',
        position: 'relative',
        padding: '16px',
        height: '473px',
      }}
    >
      <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>
        Índice de Pacientes que Confirmaram o Agendamento / Não Confirmaram /
        Reagendaram / Não Responderam
      </Typography>
      <Typography sx={{ fontSize: '12px', color: '#00000061' }}>
        Todos os Períodos
      </Typography>

      <div
        style={{
          padding: '0 44.75px',
          marginTop: '24px',
          position: 'relative',
        }}
      >
        <CustomizedFunnelBar data={dataGraph} />
        <p
          style={{
            marginTop: '0',

            marginRight: '16px',

            right: '0',
            position: 'absolute',
            fontSize: '10px',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          Fonte dos Dados: JORDANA
        </p>
      </div>
    </Paper>
  );
}
