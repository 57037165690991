import {
  structureChartBarAcsSelected,
  structureChartBarAutoatendimentoSelected,
} from './mockStrutureChartBar';

const mockPie = [18202, 2457];
const dataPie = mockPie;

export const structurePieBar = ({ setchartBarData }: any) => {
  const obj = {
    series: dataPie,

    chart: {
      width: 284,
      type: 'pie',
      events: {
        dataPointSelection: function (event, chartContext, config) {
          if (config.dataPointIndex === 0) {
            setchartBarData(structureChartBarAutoatendimentoSelected);
          } else {
            setchartBarData(structureChartBarAcsSelected);
          }
        },
      },
    },
    labels: [`Autoatendimento`, `ACS`],
    responsive: [
      {
        breakpoint: 768,
        options: {
          legend: {
            position: 'bottom',
            offsetY: 0,
            horizontalAlign: 'left',
            width: 250,
          },
        },
      },
    ],
    colors: ['#debed2', '#5f2b01'],
    yaxis: {
      min: 0,
    },
    xaxis: {
      min: 0,
    },
    legend: {
      position: 'right',
      offsetY: 80,
    },
    plotOptions: {
      pie: {
        startAngle: -135,
        endAngle: 225,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        colors: ['rgba(0,0,0,0.7)'],
      },
      background: {
        enabled: true,
        foreColor: '#ffffff',
        borderRadius: 6,
        borderColor: 'transparent',
      },
    },
  };

  return obj;
};
