import moment from 'moment';
import smartphone from '../../../../../../../assets/imgs/Convocation/smartphone.svg';
import dot from '../../../../../../../assets/imgs/Convocation/dot.svg';
import connector from '../../../../../../../assets/imgs/Convocation/connector.svg';

type Props = {
  convocations: Convocations[];
};

type Convocations = {
  acs: string;
  resultConvocation: {
    status: string;
    activeSearch: string;
  };
};

const OhterConvocationItens = ({ convocations }: Props) => {
  console.log(convocations);
  return (
    <>
      {convocations.map((item, index) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img src={smartphone} style={{ width: '64px' }} />
            <p
              style={{
                fontSize: '16px',
                fontFamily: 'Roboto',
                fontWeight: 700,
              }}
            >
              {index + 1}º Convocatória
            </p>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px' }} src={dot} />
              <p
                style={{
                  fontWeight: 500,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  margin: '0 0 6.5px 14px',
                }}
              >
                ACS Responsável
              </p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px', height: '35px' }} src={connector} />
              <p
                style={{
                  fontWeight: 400,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  margin: '0 0 0 14px',
                  paddingTop: '7px',
                }}
              >
                {item.acs}
              </p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px' }} src={dot} />
              <p
                style={{
                  fontWeight: 500,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  margin: '0 0 6.5px 14px',
                }}
              >
                Resultado da Convocação
              </p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px', height: '35px' }} src={connector} />
              <p
                style={{
                  fontWeight: 400,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  margin: '0 0 0 14px',
                  paddingTop: '7px',
                }}
              >
                {item.resultConvocation.status}
                <br />
                <span
                  style={{
                    width: '199px',
                    display: 'block',
                    fontStyle: 'italic',
                  }}
                >
                  Busca Ativa registrada em
                  {moment(item.resultConvocation.activeSearch).format(
                    'DD/MM/YYYY'
                  )}
                </span>
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default OhterConvocationItens;
