import { ApexOptions } from 'apexcharts';
import { useEffect, useRef, useState } from 'react';
import { PieChart } from '../pieChart';
import './RealizationProcedurePosNotRealizedStyles.scss';
import ItemGraphic from './ItemGraphic';
import EmptyData from '../emptyData';
import { TablePagination } from '@mui/material';

interface ReasonsNotProcedureChartProps {
  data: number[];
  categories?: String[];
  height?: number;
  payload: any;
  needsRequiredFilters: boolean;
}

export function HorizontalBar({
  data,
  payload,
  needsRequiredFilters,
}: ReasonsNotProcedureChartProps) {
  const [chartData, setChartData] = useState<ApexOptions>();
  const [page, setPage] = useState(1);
  const [lastItemSliced, setLastItemSliced] = useState(5);
  const [firstItemSliced, setFirstItemSliced] = useState(0);

  const emptyData = Object.keys(data).length === 0;

  const [elementUnderMouse, setElementUnderMouse] = useState<any>();
  const [disableGraphicsOnHover, setDisableGraphicsOnHover] = useState<any>();

  const handleMouseMove = (event) => {
    const x = event.clientX;
    const y = event.clientY;
    const element = document.elementFromPoint(x, y);

    setElementUnderMouse(element);
  };

  const handlePageChange = (event, newPage) => {
    const isNextPage = newPage + 1 > page ? true : false;

    setPage(newPage + 1);
    setFirstItemSliced((prev) => (isNextPage ? prev + 5 : prev - 5));
    setLastItemSliced((prev) => (isNextPage ? prev + 5 : prev - 5));
    // if (page === 1 && previous) {
    //   return;
    // }

    // setPage((prev) => (next ? prev + 1 : prev - 1));
  };

  const dataPaginated = data?.slice(firstItemSliced, lastItemSliced);

  const refRealized = useRef(null);
  const refNotRealized = useRef(null);

  useEffect(() => {
    switch (elementUnderMouse) {
      case refRealized.current:
        setDisableGraphicsOnHover(1);
        break;

      case refNotRealized.current:
        setDisableGraphicsOnHover(2);
        break;
      default:
        setDisableGraphicsOnHover(0);
        break;
    }
  }, [elementUnderMouse]);

  return (
    <>
      <div className="boxData" onMouseMove={handleMouseMove}>
        <div className="boxData__legends">
          <div
            className="boxData__legends__colorizedLegend"
            style={{
              backgroundColor: '#238884',
            }}
          />
          <span ref={refRealized}>Responderam</span>
          <div
            className="boxData__legends__colorizedLegend"
            style={{
              backgroundColor: '#F44336',
              marginLeft: '12px',
            }}
          />
          <span ref={refNotRealized}>Não Responderam</span>
        </div>

        {emptyData ? (
          <div style={{ height: '357px', marginTop: '-128px' }}>
            <EmptyData needsRequiredFilters={needsRequiredFilters} />
          </div>
        ) : (
          <>
            <div className="boxData__content">
              {dataPaginated.map((item: any) => {
                return (
                  <ItemGraphic
                    value1={item.values[0]}
                    value2={item.values[1]}
                    label={item.itemLabel}
                    disableGraphicsOnHover={disableGraphicsOnHover}
                  />
                );
              })}
            </div>
          </>
        )}
        <TablePagination
          sx={{
            '.MuiTablePagination-selectLabel': {
              display: 'none',
            },
            '.MuiTablePagination-select': { display: 'none' },
            '.MuiTablePagination-selectIcon': {
              display: 'none',
            },
          }}
          component="div"
          count={data?.length}
          rowsPerPage={5}
          page={page - 1}
          onPageChange={handlePageChange}
          labelRowsPerPage={'Itens por página'}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} de ${
              count !== -1 ? count : `mais do que ${to}`
            }`;
          }}
        />
      </div>
    </>
  );
}
