import { Typography } from '@mui/material';
import styles from './styles.module.css';
import { CollectionGoal } from '../collectionGoal';

const data = [
  {
    municipio: 'Amaraji',
    populacaoElegivel: 6057,
    coletasRealizadas: 714,
    metaColeta: 848,
    atingimentoMeta: 84.2,
  },
  {
    municipio: 'Barreiros',
    populacaoElegivel: 11321,
    coletasRealizadas: 586,
    metaColeta: 1585,
    atingimentoMeta: 37.0,
  },
  {
    municipio: 'Cortês',
    populacaoElegivel: 3250,
    coletasRealizadas: 376,
    metaColeta: 455,
    atingimentoMeta: 82.6,
  },
  {
    municipio: 'Lagoa dos Gatos',
    populacaoElegivel: 4142,
    coletasRealizadas: 265,
    metaColeta: 580,
    atingimentoMeta: 45.7,
  },
  {
    municipio: 'Primavera',
    populacaoElegivel: 3857,
    coletasRealizadas: 313,
    metaColeta: 540,
    atingimentoMeta: 58.0,
  },
  {
    municipio: 'Ribeirão',
    populacaoElegivel: 13035,
    coletasRealizadas: 346,
    metaColeta: 1825,
    atingimentoMeta: 19.0,
  },
  {
    municipio: 'São Benedito do Sul',
    populacaoElegivel: 4392,
    coletasRealizadas: 177,
    metaColeta: 615,
    atingimentoMeta: 28.8,
  },
  {
    municipio: 'Tamandaré',
    populacaoElegivel: 6242,
    coletasRealizadas: 224,
    metaColeta: 874,
    atingimentoMeta: 25.6,
  },
];

export function TableWithCharts({ data }) {
  const calcularTotal = () => {
    const total = {
      populacaoElegivel: 0,
      coletasRealizadas: 0,
      metaColeta: 0,
      atingimentoMeta: '',
    };

    data?.forEach((row) => {
      total.populacaoElegivel += row.elegivel;
      total.coletasRealizadas += row.coletas;
      total.metaColeta += row.metaColeta;
    });

    total.atingimentoMeta = `${(
      (total.coletasRealizadas / total.metaColeta) *
      100
    ).toFixed(1)}%`;

    return total;
  };

  const total = calcularTotal();

  return (
    <div className={styles.container}>
      <Typography variant="h5" fontWeight="bold" fontSize="20px">
        Testes Realizados do HPV
        <br />
        Número de Coletas x Metas
      </Typography>

      <Typography className={styles.info}>
        Observações: Os dados da coluna Meta de Coleta é o resultado de 20% de
        70% da quantidade total de mulheres elegíveis segundo o IBGE. Os dados
        da coluna População Elegível (IBGE 2022) são fixos e não recebem
        influência dos filtros de visualização.
      </Typography>

      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Município</th>
              <th>
                População Elegível{' '}
                <span className={styles.textSmall}>Fonte: IBGE 2022</span>
              </th>
              <th>
                Coletas Realizadas{' '}
                <span className={styles.textSmall}>Fonte: JORDANA</span>
              </th>

              <th>
                Meta de Coleta (20%){' '}
                <span className={styles.textSmall}>Fonte: OMS</span>{' '}
              </th>
              <th>
                Atingimento da Meta (%){' '}
                <span className={styles.textSmall}>
                  Cálculo sobre a Meta de Coleta
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((row, index) => (
              <tr key={index}>
                <td>{row.municipio}</td>
                <td>{row.elegivel}</td>
                <td>{row.coletas}</td>
                <td>{row.metaColeta}</td>
                <td>
                  <div className={styles.labelWithChart}>
                    {String(row.atingimentoPercent).replace('%', '')}%{' '}
                    {row.percentNumber > 0 && (
                      <div className={styles.donutWrapper}>
                        <CollectionGoal value={row.percentNumber} />
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td style={{ textAlign: 'right' }}>Total</td>
              <td>{total.populacaoElegivel}</td>
              <td>{total.coletasRealizadas}</td>
              <td>{total.metaColeta}</td>
              <td>{total.atingimentoMeta}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
