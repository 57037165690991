import { Button, Chip, Collapse, Paper, Tooltip } from '@mui/material';
import { CardDetailPatient } from './CardDetailPatientConvocationStyle';

import './CardDetailPatientConvocationStyles.scss';
import { ExpandIconWrapper } from '../../../../../components/CardExam/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

import DetailTestRealized from './Details/DetailTestRealized';
import DetailConcluded from './Details/DetailConcluded';
import DetailInProgress from './Details/DetailInProgress';
import DetailCanceled from './Details/DetailCanceled';

export const CardDetailPatientConvocation = ({
  patientName,
  cpf,
  convocation,
  dtAgendamento,
  dtResult,
  status,
  index,
  opened,
  setOpenedCard,
  attendedAt,
  resultConvocation,
  confirmPresent,
  estado,
  municipio,
  estabelecimento,
}: any) => {
  const colorChip = {
    1: '#4CAF50',
    2: '#2196F3',
    3: '#FFB547',
    4: '#F44336',
  };

  const labelChip = {
    1: 'Concluída',
    2: 'Teste Realizado',
    3: 'Em Andamento',
    4: 'Cancelada',
  };

  const getLabelChip = (label) => {
    if (label === 'Colposcopia' || label === 'Histopatologia') {
      return 'Disponivel';
    }

    if (label === 'Intraepitelial LIEAG' && status === 'COLETADO') {
      return 'Resultado Disponível';
    }

    if (label === 'Intraepitelial LIEAG' && status === 'FALHA') {
      return 'Excluir Registro';
    }
    // return statusJson[status || 'FALHA'].label;
  };

  return (
    <CardDetailPatient borderLeftColor="#238884">
      <table className="table-detail-patient">
        <thead>
          <tr>
            <th>Paciente</th>
            <th>CPF</th>
            {status === 1 && (
              <>
                <th>Convocação</th>
                <th>Data do Agendamento</th>
                <th>Data do Resultado</th>
              </>
            )}
            {status === 2 && (
              <>
                <th>Convocação</th>
                <th>Data do Agendamento</th>
                <th>Compareceu em</th>
              </>
            )}

            {status === 3 && (
              <>
                <th>Estado</th>
                <th>Município</th>
                <th>Estabelecimento</th>
              </>
            )}

            {status === 4 && (
              <>
                <th>Convocação</th>
                <th>Resultado da Convocação</th>
                <th>Motivo</th>
              </>
            )}

            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ width: '149px' }}>
              <Tooltip
                title={patientName}
                placement="top"
                sx={{
                  position: 'relative',
                }}
              >
                <span>
                  {patientName.length > 16
                    ? patientName.slice(0, 16) + '...'
                    : { patientName }}
                </span>
              </Tooltip>
            </td>
            <td style={{ width: '149px' }}>{cpf}</td>
            {status === 1 && (
              <>
                <td style={{ width: '149px' }}>{convocation}</td>
                <td style={{ width: '149px' }}>{dtAgendamento}</td>
                <td style={{ width: '149px' }}>{dtResult}</td>
              </>
            )}

            {status === 2 && (
              <>
                <td style={{ width: '149px' }}>{convocation}</td>
                <td style={{ width: '149px' }}>{dtAgendamento}</td>
                <td style={{ width: '149px' }}>{attendedAt}</td>
              </>
            )}
            {status === 3 && (
              <>
                <td style={{ width: '149px' }}>{estado}</td>
                <td style={{ width: '149px' }}>{municipio}</td>
                <td style={{ width: '149px' }}>{estabelecimento}</td>
              </>
            )}

            {status === 4 && (
              <>
                <td style={{ width: '149px' }}>{convocation}</td>
                <td style={{ width: '149px' }}>{resultConvocation}</td>
                <td style={{ width: '149px' }}>{confirmPresent}</td>
              </>
            )}

            <td
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '35px',
              }}
            >
              <div style={{ width: '149px' }}>
                <Chip
                  // data-testid={`exam-${index}-status-item`}
                  label={labelChip[status]}
                  sx={{
                    color: `${'white'}`,
                    backgroundColor: colorChip[status],
                  }}
                />
              </div>
              <div style={{ width: '40px' }}>
                <ExpandIconWrapper
                  // data-testid={`exam-${index}-collapse`}
                  onClick={() => {
                    setOpenedCard(opened ? null : index);
                  }}
                >
                  <ExpandMoreIcon
                    fontSize="medium"
                    sx={{
                      color: 'white',
                      transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                  />
                </ExpandIconWrapper>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <Collapse
        in={opened && status === 1}
        sx={{ width: '100%' }}
        timeout="auto"
        unmountOnExit
      >
        <DetailConcluded />
      </Collapse>

      <Collapse
        in={opened && status === 2}
        sx={{ width: '100%' }}
        timeout="auto"
        unmountOnExit
      >
        <DetailTestRealized />
      </Collapse>
      <Collapse
        in={opened && status === 3}
        sx={{ width: '100%' }}
        timeout="auto"
        unmountOnExit
      >
        <DetailInProgress />
      </Collapse>
      <Collapse
        in={opened && status === 4}
        sx={{ width: '100%' }}
        timeout="auto"
        unmountOnExit
      >
        <DetailCanceled />
      </Collapse>
    </CardDetailPatient>
  );
};
