import { Box, Paper } from '@mui/material';
import { FiltersHistoric } from './FiltersHistoric';
import { useForm } from 'react-hook-form';
import { CardDetailPatientConvocation } from './CardDetailPatientConvocation';
import { ListHistoric } from './ListHistoric';

const dataMock = [
  {
    patientName: 'Maria Elaine Santos',
    cpf: '13663287911',
    convocation: 1,
    dtAgendamento: '17/02/2025',
    dtResult: '17/02/2025',
    status: 1,
    index: 0,
  },
  {
    patientName: 'Maria Elaine Santos',
    cpf: '13663287911',
    convocation: 1,
    dtAgendamento: '17/02/2025',
    dtResult: '17/02/2025',
    status: 1,
    index: 1,
  },
  {
    patientName: 'Maria Elaine Santos',
    cpf: '13663287911',
    convocation: 1,
    dtAgendamento: '17/02/2025',
    attendedAt: '17/02/2025',
    status: 2,
    index: 2,
  },
  {
    patientName: 'Maria Elaine Santos',
    cpf: '13663287911',
    estado: 'Pernambuco',
    municipio: 'Amaraji',
    estabelecimento: 'PSF ALICE II',
    status: 3,
    index: 3,
  },
  {
    patientName: 'Maria Elaine Santos',
    cpf: '13663287911',
    convocation: 1,
    resultConvocation: 'Não Elegível',
    confirmPresent: 'Paciente Histerec...',
    status: 4,
    index: 4,
  },
];

export const Historic = () => {
  const { control } = useForm();
  const handleSetFilter = (newValue: any, inputName: string, origin?: any) => {
    // setListFilter((prev) => {
    //   return prev.map((item) =>
    //     item.inputName === inputName ? { ...item, value: newValue } : item
    //   );
    // });
  };

  return (
    <Box mt={5}>
      <FiltersHistoric handleSetFilter={handleSetFilter} control={control} />
      <Paper
        variant="outlined"
        sx={{
          paddingTop: '32px',
          paddingRight: '32px',
          marginTop: '24px',
          borderRadius: '10px',
          borderColor: 'rgba(0, 0, 0, 0.23)',
        }}
      >
        <ListHistoric data={dataMock} />
      </Paper>
    </Box>
  );
};
