import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface TypesOfVirusesProps {
  data: number[];
  categories?: String[];
  height?: number;
}

export function TypesOfViruses({ data }: TypesOfVirusesProps) {
  const total = data.reduce((acc, item) => acc + item, 0);
  const categories = [
    'HPV 16',
    'HPV 18',
    'HPV Outros',
    'HPV 16, 18 e Outros',
    'Inconclusivos',
    'Não Detectáveis',
  ];

  const [chartData, setChartData] = useState<ApexOptions>();

  const handleCustomLabel = () => {
    if (total) {
      const formattedCategories = categories.map((category, index) => {
        const porcentagem = ((data[index] / total) * 100).toFixed(2);
        return `${category} (${porcentagem}%)`;
      });

      setChartData((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions?.xaxis,
          categories: formattedCategories,
        },
      }));
    }
  };

  useEffect(() => {
    setChartData({
      series: [
        {
          name: 'Resultados dos Testes',
          data,
        },
      ],
      chart: {
        type: 'bar',
        height: 284,
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '26px',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 40,
        offsetY: -1,
        style: {
          fontSize: '12px',
          colors: ['rgba(0,0,0,0.9)'],
        },
        background: {
          enabled: true,
          foreColor: '#ffffff',
          borderRadius: 6,
          borderColor: 'transparent',
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        type: 'category',
        categories: [
          ['HPV 16 (0%)'],
          ['HPV 18 (0%)'],
          ['HPV Outros (0%)'],
          ['HPV', '16, 18 e Outros (0%)'],
          ['Inconclusivos (0%)'],
          ['Não Detectáveis', '(0%)'],
        ],
        labels: {
          rotate: 0,
        },
      },
      yaxis: {
        min: 0,
        stepSize: 1000,
      },
      fill: {
        opacity: 1,
      },
      colors: ['#775DD0'],
      legend: {
        show: false,
        width: 100,
      },
      responsive: [
        {
          breakpoint: 768,

          options: {
            xaxis: {
              labels: {
                style: {
                  fontSize: '10px',
                },
              },
            },
          },
        },
      ],
    });
  }, [data]);

  useEffect(() => {
    handleCustomLabel();
  }, [data]);

  return (
    <>
      {chartData ? (
        <ReactApexChart
          options={chartData}
          series={chartData.series}
          type="bar"
          height={284}
        />
      ) : undefined}
    </>
  );
}
