import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import { Controller } from 'react-hook-form';
import { StyledAsteriskTextField } from '../../../../components/StyledAsteriskTextField/styles';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';
import SearchIcon from '@mui/icons-material/Search';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import { useContext, useEffect, useState } from 'react';
import useHandleChangesFilters from './hooks/useHandleChangesFilters';
import {
  optionsInspecao,
  optionsMotivoExame,
  optionsMotivoNaoAtendida,
  optionsResultadoHPV,
  optionsTiposVirus,
} from '../../../../utils/variables';

import useRequestFilter from './hooks/useRequestFilter';
import useVerifyAccessLevel from './hooks/useVerifyAccessLevel';
import useSetPayload from './hooks/useSetPayload';
import { EstablishmentContext } from '../../../../context/EstablishmentContext';
import useClearValues from './hooks/useClearValues';
import api from '../../../../api';
import MultipleFilters from '../../../../components/MultipleFilters';
import useStatesFields from '../../../../components/MultipleFilters/hooks/useStatesFields';
import useActions from '../../../../components/MultipleFilters/hooks/useActions';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { SelectWithSearch } from '../../../../components/SelectWithSearch';
import { getUserToken } from '../../../../lib/auth';

const FiltersReportsHpv = ({
  setTableData,
  control,
  handleSubmit,
  setValue,
  watch,
  setError,
  clearErrors,
  errors,
  estadosSelected,
  setEstadosSelected,
  municipiosSelected,
  setMunicipiosSelected,
  estabelecimentosSelected,
  setEstabelecimentosSelected,
  setShowTableData,
  showTableData,
  setListFilter,
  listFilter,
  initialStateListFilter,
  listFilterShow,
  setListFilterShow,
  period,
  periodResultado,
  setPeriodResultado,
  setPeriod,
  disableInputEstado,
  setListFilterToShow,
  dtColetaIni,
  setDataColetaIni,
  dtColetaFinal,
  setDataColetaFinal,
  dtResultadoIni,
  setDataResultadoIni,
  dtResultadoFinal,
  setDataResultadoFinal,
  disabledLaboratorioResultado,
  selectedOptionsLaboratorio,
  setSelectedOptionsLaboratorio,
  setSelectedLaboratorio,
  selectedLaboratorio,
  selectedOptinsProfissionalResponsavel,
  setSelectedOptinsProfissionalResponsavel,
}) => {
  const [disableOtherInputs, setDisableOtherInputs] = useState(true);

  const [estados] = useState<any[]>([]);
  const [, setCidades] = useState<any[]>([]);
  const [, setEstabelecimentos] = useState<any[]>([]);
  const [loadingResponsaveis, setLoadingResponsaveis] = useState(false);

  // const [arrayEquipes, setArrayEquipes] = useState([]);
  // const [equipesSelected, setEquipesSelected] = useState<any>([]);
  const [, setShowMotivoNaoAtendimento] = useState(false);

  const [, setFirstLoadPage] = useState(true);

  const [errorInputDate] = useState<any>();
  const [errorInputDateResultado, setErrorInputDateResultado] = useState<any>();

  const [responsaveis, setResponsaveis] = useState<any>([]);

  const [, setDisableFilters] = useState({
    estado: false,
    municipio: false,
    estabelecimento: false,
    equipe: false,
  });

  const [, setDisableResponsaveisInput] = useState(true);

  const { currentEstablishment } = useContext(EstablishmentContext);

  const location = useLocation();

  const {
    handleSetFilter,
    handleChangeRealizouAtendimento,
    getCidadesEstabelecimentos,
    getEstabelecimento,
  } = useHandleChangesFilters({
    setCidades,
    setEstabelecimentos,
    setValue,
    setListFilter,
    estados,
  });

  const {
    acsSelected,
    arrayAcs,
    searchAcs,
    allAcsOptions,
    anchorElAcs,
    disableAcsInput,

    setAnchorElAcs,
    setArrayAcs,
    setAcsSelected,
    setAllAcsOptions,
    setDisableAcsInput,

    anchorElEstados,
    searchEstado,
    estadosOptions,
    allEstadosOptions,

    setAnchorElEstados,
    setSearchEstado,
    setEstadosOptions,
    setAllEstadosOptions,

    allMunicipiosOptions,
    searchMunicipio,
    anchorElMunicipios,
    optionsMunicipios,
    disableMunicipioInput,

    setAllMunicipiosOptions,
    setSearchMunicipio,
    setAnchorElMunicipios,
    setOptionsMunicipios,
    setDisabledMunicipioInput,

    optionsEstabelecimentos,
    allEstabelecimentosOptions,
    searchEstabelecimentos,
    anchorElEstabelecimentos,
    disableEstabelecimentoInput,

    setOptionsEstabelecimentos,
    setAllEstabelecimentosOptions,
    setSearchEstabelecimentos,
    setAnchorEstabelecimentos,
    setDisabledEstabelecimentoInput,

    allEquipesOptions,
    searchEquipes,
    anchorElEquipe,
    arrayEquipes,
    equipesSelected,
    disableEquipeInput,
    errorsFields,
    optionsLaboratorios,

    setAllEquipesOptions,
    setOptionsLaboratorios,
    setSearchEquipes,
    setAnchorElEquipe,
    setArrayEquipes,
    setEquipesSelected,
    setDisableEquipeInput,
    setErrorsFields,
    setAllLaboratoriosOptions,

    setLoadingEstados,
  } = useStatesFields();

  const userLaboratorio =
    getUserToken().permissao_atual?.contexto === 'LABORATÓRIO';

  const { loadReportsHpv } = useRequestFilter({
    setTableData,
  });

  const { setPayload } = useSetPayload({
    watch,
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    dtColetaFinal,
    dtColetaIni,
    dtResultadoIni,
    dtResultadoFinal,
    selectedLaboratorio,
    selectedOptinsProfissionalResponsavel,
    selectedOptionsLaboratorio,
  });

  const { permissao } = useVerifyAccessLevel({
    setValue,
    getCidadesEstabelecimentos,
    getEstabelecimento,
    currentEstablishment,
    setDisableFilters,
    handleSetFilter,
    setTableData,
    watch,
  });

  const { validationSubmit } = useActions();

  const { clearValues } = useClearValues({
    permissao,
    setValue,
    handleSetFilter,
    loadReportsHpv,
    watch,
    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    showTableData,
    setTableData,
    setShowTableData,
    setErrorsFields,
    setListFilterToShow,
    setDataColetaIni,
    setDataColetaFinal,
    setDataResultadoFinal,
    setDataResultadoIni,
    setSelectedOptinsProfissionalResponsavel,
    setSelectedOptionsLaboratorio,
    setArrayEquipes,
    initialStateListFilter,
    setListFilterShow,
  });

  const clearValuesInputs = () => {};

  useEffect(() => {
    const loadEstados = async () => {
      // setLoadingEstados(true);
      try {
        const response = await api.get('/estados/hpv');
        const estados = response.data;
        setEstadosOptions(estados);
        setAllEstadosOptions(estados);
        setLoadingEstados(false);
      } catch (error) {
        setLoadingEstados(false);

        console.error('Erro ao carregar estados:', error);
        // setEstadosLoadError(true);
      }
    };

    loadEstados();
  }, []);

  const getProfissionaisList = async ({ bySearchInput, nome }: any) => {
    setLoadingResponsaveis(true);

    const response = await api.get(
      '/dashboard/exames/hpv/panel/profissionais-coleta',
      {
        params: {
          nome: bySearchInput ? nome : null,
          estados:
            estadosSelected.length > 0 ? estadosSelected.join(', ') : null,
          municipios:
            municipiosSelected.length > 0
              ? municipiosSelected.join(', ')
              : null,
          estabelecimentos:
            estabelecimentosSelected.length > 0
              ? estabelecimentosSelected
                  .filter((estabId) => estabId !== 'null')
                  .join(', ')
              : null,
        },
      }
    );
    let arrayProfissionais: any = [];

    response.data.data.forEach((profissional) => {
      const objProfissionalFormated = {
        value: profissional.prof_id,
        label: profissional.pessoa.nome,
        // estabelecimentoprofissional:
        //   profissional.estabelecimentos[0].estabelecimento.id,
        // nomefantasiaestabelecimento:
        //   profissional.estabelecimentos[0].estabelecimento.nome_fantasia,
      };

      arrayProfissionais.push(objProfissionalFormated);
    });

    const sortedArray = arrayProfissionais.sort((a, b) => {
      const nameA = a.label.trim().toUpperCase(); // Remover espaços e ignorar maiúsculas/minúsculas
      const nameB = b.label.trim().toUpperCase();
      return nameA.localeCompare(nameB);
    });

    setResponsaveis(sortedArray);
    setDisableResponsaveisInput(false);
    setDisableResponsaveisInput(false);
    setLoadingResponsaveis(false);
  };

  const fnWhenFormIsValid = async () => {
    if (!errorInputDate && !errorInputDateResultado) {
      setTableData([]);
      setListFilterShow(listFilter);

      setShowTableData(true);

      await setPayload().then((payload) => {
        try {
          loadReportsHpv(payload);
        } catch (error) {}
      });
    }
  };

  const onSubmit = async () => {
    validationSubmit({
      fnWhenFormIsValid,
      estadosSelected,
      municipiosSelected,
      estabelecimentosSelected,
      setErrorsFields,
      setDisableOtherInputs,
      watch,
      setError,
      clearErrors,
    });
  };

  const handleSetPeriod = (e) => {
    setPeriod(e.target.value);
    setDataColetaFinal(null);
    setDataColetaIni(null);

    setListFilterShow((prev) => {
      return prev.filter((field) => field.inputName !== 'dt_coleta_inicio');
    });
    setListFilterShow((prev) => {
      return prev.filter((field) => field.inputName !== 'dt_coleta_fim');
    });

    handleSetFilter('', 'dt_coleta_inicio');
    handleSetFilter('', 'dt_coleta_fim');
  };

  const handleSetPeriodResultado = (e) => {
    setPeriodResultado(e.target.value);
    setDataResultadoIni(null);
    setDataResultadoFinal(null);

    setListFilterShow((prev) => {
      return prev.filter((field) => field.inputName !== 'dt_resultado_inicio');
    });
    setListFilterShow((prev) => {
      return prev.filter((field) => field.inputName !== 'dt_resultado_fim');
    });

    handleSetFilter('', 'dt_resultado_inicio');
    handleSetFilter('', 'dt_resultado_fim');
    // handleSetFilter('', 'dt_resultado_fim');
  };

  const handleSetSelectedOptions =
    ({ setSelected, fieldName }) =>
    (options) => {
      setSelected(options);
      const optionLabel = () => {
        if (options.length === 0) {
          return '';
        }

        if (options.length === 1) {
          return `${fieldName}: ${options[0].label}`;
        }

        if (options.length > 1) {
          return `${fieldName}: Vários`;
        }
      };
      handleSetFilter(optionLabel(), 'resultado');
    };

  useEffect(() => {
    setValue('estado', estadosSelected);
    setValue('municipio', municipiosSelected);
    setValue('estabelecimentoSaudeId', estabelecimentosSelected);
    setValue('estabelecimentoSaudeId', estabelecimentosSelected);
    setValue('equipes', equipesSelected);
  }, [estadosSelected, municipiosSelected, estabelecimentosSelected]);

  useEffect(() => {
    async function loadResponsaveis() {
      // const profissionais = response.data.map((profissional) => {
      //   return {
      //     idprofissional: profissional.id,
      //     nomeprofissional: `${profissional.pessoa.nome}`,
      //     label: `${profissional.pessoa.nome}`,
      //   };
      // });
      // setResponsaveis(profissionais);
      // if (isAdminMunicipal) {
      //   const user: any = getUserToken();
      //   const idMunicipio = user.estabelecimento_saude?.endereco?.municipio?.id;
      //
      //   setResponsaveis(profissionais.data);
      // } else {
      //
      //   setResponsaveis(profissionais.data);
      // }
    }
    loadResponsaveis();
  }, []);

  const selectedChartIsResult = !!localStorage.getItem('result');
  const selectedChartIsTypeVirus = !!localStorage.getItem('typeVirus');
  const selectedChartIsReason = !!localStorage.getItem('reasonNotProcedure');
  const selectedChartOriginCollection =
    localStorage.getItem('collectionOrigin');

  const setListFilterDisplayByCharts = () => {
    if (selectedChartIsResult) {
      const value = localStorage.getItem('result');
      handleSetFilter(
        optionsResultadoHPV.find((item) => item.value === value)?.label,
        'resultado'
      );
    }
  };

  useEffect(() => {
    if (
      !!location.state ||
      selectedChartIsResult ||
      selectedChartIsTypeVirus ||
      selectedChartIsReason ||
      selectedChartOriginCollection
    ) {
      setListFilterDisplayByCharts();
    }
  }, []);

  // useEffect(() => {
  //   if (showTableData) {
  //     setDefaultValues().then((payload) => {
  //       if (payload === 'waiting') {
  //         return;
  //       } else {
  //         loadReportsHpv(payload);
  //       }
  //     });
  //   }
  // }, [estados, currentEstablishment, showTableData]);

  const estabelecimentoInputValue = watch('estabelecimento_saude');

  useEffect(() => {
    if (!estabelecimentoInputValue) {
      setValue('motivo', null);
      setValue('idade_ini', null);
      setValue('idade_fin', null);
      setValue('realizou_atendimento', null);
      setValue('atendimento_status', null);
      setValue('inspecao_colo', null);
      setValue('responsavel_coleta', null);
      setValue('dt_coleta', null);
      setValue('resultado', null);
      setValue('estabelecimentoLaudoId', null);
      setValue('dt_resultado', null);
      setValue('search', null);

      initialStateListFilter.forEach((field) => {
        const isMunicipio = field.inputName === 'municipio';
        const isEstado = field.inputName === 'estado';
        if (isEstado || isMunicipio) {
          return;
        } else {
          handleSetFilter('', field.inputName);
        }
      });
    }
  }, [estabelecimentoInputValue]);

  useEffect(() => {
    const isAllEmpty = !!listFilter.find((item) => item.value !== '') === false;

    if (isAllEmpty) {
      setListFilterShow([]);
    }
  }, [listFilter]);

  useEffect(() => {
    // const hasEstadosSelected = estadosSelected.length > 0;
    // const hasMunicipioSelected = municipiosSelected.length > 0;
    const hasEstabelecimentoSelected = estabelecimentosSelected.length > 0;

    if (
      // hasEstadosSelected &&
      // hasMunicipioSelected &&
      hasEstabelecimentoSelected
    ) {
      setDisableOtherInputs(false);
    }
  }, [estadosSelected, municipiosSelected, estabelecimentosSelected]);

  useEffect(() => {
    if (
      optionsMunicipios?.length > 0 &&
      municipiosSelected?.length === optionsMunicipios?.length
    ) {
      setDisableOtherInputs(false);
    } else if (municipiosSelected?.length === 0) {
      setDisableOtherInputs(true);
    }
  }, [optionsMunicipios, municipiosSelected]);

  const searchInput = watch('search');

  useEffect(() => {
    if (!searchInput) {
      clearErrors('search');
    }
  }, [searchInput]);

  const [errorInputDateColeta, setErrorInputDateColeta] = useState<any>({});

  useEffect(() => {
    const dtIni = moment(dtColetaIni, 'DD-MM-YYYY');
    const dtFin = moment(dtColetaFinal, 'DD-MM-YYYY');

    if (dtIni.isAfter(dtFin)) {
      setErrorInputDateColeta({
        ini: '',
        fin: 'Data final deve ser maior que a data inicial',
      });
    } else {
      setErrorInputDateColeta('');
    }
  }, [dtColetaFinal, dtColetaIni]);

  useEffect(() => {
    const dtIni = moment(dtResultadoIni, 'DD-MM-YYYY');
    const dtFin = moment(dtResultadoFinal, 'DD-MM-YYYY');

    if (dtIni.isAfter(dtFin)) {
      setErrorInputDateResultado({
        fin: 'Data final deve ser maior que a data inicial',
        ini: '',
      });
    } else {
      setErrorInputDateResultado('');
    }
  }, [dtResultadoIni, dtResultadoFinal]);

  useEffect(() => {
    if (municipiosSelected.length > 0) {
      getProfissionaisList({});
    } else {
      setResponsaveis([]);
    }
  }, [municipiosSelected]);

  useEffect(() => {
    if (estabelecimentosSelected.length > 0) {
      getProfissionaisList({});
    }
  }, [estabelecimentosSelected]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DisplayFilter style={{ marginTop: '24px' }}>
            {(listFilterShow?.every(
              (item) => item.value === '' || item.value === null
            ) ||
              listFilterShow.length === 0) && (
              <p style={{ margin: 0, width: '413px', color: '#838383' }}>
                Os filtros selecionados aparecerão aqui.
              </p>
            )}

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                gap: '10px',
                flexWrap: 'wrap',
              }}
            >
              {listFilterShow.map((item) => (
                <>
                  {item.value ? (
                    <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                  ) : undefined}
                </>
              ))}
            </div>
          </DisplayFilter>
        </Grid>

        <MultipleFilters
          requiredMunicipio={estadosSelected.length > 1 ? false : true}
          setFirstLoadPage={setFirstLoadPage}
          clearValuesInputs={clearValuesInputs}
          setDisableOtherInputs={setDisableOtherInputs}
          setAllLaboratoriosOptions={setAllLaboratoriosOptions}
          setOptionsLaboratorios={setOptionsLaboratorios}
          disableInputEstado={disableInputEstado}
          handleSetFilter={handleSetFilter}
          mdEstado={3}
          mdMunicipio={3}
          mdEstabelecimento={3}
          mdEquipe={3}
          fieldEquipe
          acsSelected={acsSelected}
          arrayAcs={arrayAcs}
          searchAcs={searchAcs}
          allAcsOptions={allAcsOptions}
          setAllAcsOptions={setAllAcsOptions}
          anchorElAcs={anchorElAcs}
          disableAcsInput={disableAcsInput}
          setAnchorElAcs={setAnchorElAcs}
          setArrayAcs={setArrayAcs}
          setSearchAcs={setArrayAcs}
          setAcsSelected={setAcsSelected}
          anchorElEstados={anchorElEstados}
          searchEstado={searchEstado}
          estadosOptions={estadosOptions}
          allEstadosOptions={allEstadosOptions}
          estadosSelected={estadosSelected}
          setAnchorElEstados={setAnchorElEstados}
          setSearchEstado={setSearchEstado}
          setEstadosOptions={setEstadosOptions}
          setAllEstadosOptions={setAllEstadosOptions}
          setEstadosSelected={setEstadosSelected}
          allMunicipiosOptions={allMunicipiosOptions}
          searchMunicipio={searchMunicipio}
          anchorElMunicipios={anchorElMunicipios}
          municipiosSelected={municipiosSelected}
          optionsMunicipios={optionsMunicipios}
          disableMunicipioInput={disableMunicipioInput}
          setAllMunicipiosOptions={setAllMunicipiosOptions}
          setSearchMunicipio={setSearchMunicipio}
          setAnchorElMunicipios={setAnchorElMunicipios}
          setMunicipiosSelected={setMunicipiosSelected}
          setOptionsMunicipios={setOptionsMunicipios}
          optionsEstabelecimentos={optionsEstabelecimentos}
          allEstabelecimentosOptions={allEstabelecimentosOptions}
          searchEstabelecimentos={searchEstabelecimentos}
          anchorElEstabelecimentos={anchorElEstabelecimentos}
          estabelecimentosSelected={estabelecimentosSelected}
          disableEstabelecimentoInput={disableEstabelecimentoInput}
          setOptionsEstabelecimentos={setOptionsEstabelecimentos}
          setAllEstabelecimentosOptions={setAllEstabelecimentosOptions}
          setSearchEstabelecimentos={setSearchEstabelecimentos}
          setAnchorEstabelecimentos={setAnchorEstabelecimentos}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          allEquipesOptions={allEquipesOptions}
          searchEquipes={searchEquipes}
          anchorElEquipe={anchorElEquipe}
          arrayEquipes={arrayEquipes}
          equipesSelected={equipesSelected}
          disableEquipeInput={disableEquipeInput}
          setAllEquipesOptions={setAllEquipesOptions}
          setSearchEquipes={setSearchEquipes}
          setAnchorElEquipe={setAnchorElEquipe}
          setArrayEquipes={setArrayEquipes}
          setEquipesSelected={setEquipesSelected}
          setDisabledMunicipioInput={setDisabledMunicipioInput}
          setDisabledEstabelecimentoInput={setDisabledEstabelecimentoInput}
          setDisableEquipeInput={setDisableEquipeInput}
          setDisableAcsInput={setDisableAcsInput}
          errorsFields={errorsFields}
          setErrorsFields={setErrorsFields}
        />

        <Grid item xs={12} md={3}>
          <Controller
            name="idade_ini"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  data-testid="inputIdadeInicio"
                  size="small"
                  disabled={disableOtherInputs}
                  label="Idade Início"
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleSetFilter(
                      'Idade Início: ' + e.target.value,
                      'idade_ini'
                    );
                  }}
                  value={value ? value : ''}
                  fullWidth
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Controller
            name="idade_fin"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  data-testid="inputIdadeFim"
                  size="small"
                  disabled={disableOtherInputs}
                  label="Idade Fim"
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleSetFilter(
                      'Idade Fim: ' + e.target.value,
                      'idade_fin'
                    );
                  }}
                  value={value ? value : ''}
                  fullWidth
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Controller
            name="realizou_atendimento"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  disabled={disableOtherInputs}
                  options={['Sim', 'Não']}
                  onChange={(_, newValue) => {
                    handleSetFilter(
                      'Realizou o atendimento:' + newValue,
                      'realizou_atendimento'
                    );
                    handleChangeRealizouAtendimento(
                      newValue,
                      setShowMotivoNaoAtendimento,
                      onChange
                    );
                  }}
                  value={value ? value : ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      data-testid="inputRealizouAtendimento"
                      size="small"
                      label="Realizou o atendimento"
                    />
                  )}
                />
              );
            }}
          />
        </Grid>
        {watch('realizou_atendimento') === 'Não' && (
          <Grid item xs={12} md={3}>
            <Controller
              name="atendimento_status"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    <Autocomplete
                      options={optionsMotivoNaoAtendida}
                      getOptionLabel={(option) => option.label}
                      value={value ? value : null}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                        clearErrors('atendimento_status');
                        handleSetFilter(
                          'Motivo do Não Atendimento: ' +
                            optionsMotivoNaoAtendida.find(
                              (option) => option.value === newValue?.value
                            )?.label,
                          'atendimento_status'
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          data-testid="inputMotivoNaoAtendimento"
                          size="small"
                          label="Motivo do Não Atendimento"
                          error={!!errors.atendimento_status}
                          helperText={errors.atendimento_status?.message}
                        />
                      )}
                    />
                    {/* <TextField
                      disabled={disableOtherInputs}
                      size="small"
                      data-testid="inputMotivoNaoAtendimento"
                      fullWidth
                      value={value ? value : ''}
                      select
                      label="Motivo do Não Atendimento"
                      error={!!errors.atendimento_status}
                      helperText={errors.atendimento_status?.message}
                      onChange={(e) => {
                        onChange(e.target.value);
                        clearErrors('atendimento_status');
                        handleSetFilter(
                          'Motivo do Não Atendimento: ' +
                            optionsMotivoNaoAtendida.find(
                              (option) => option.value === e.target.value
                            )?.label,
                          'atendimento_status'
                        );
                      }}
                    >
                      {optionsMotivoNaoAtendida.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </TextField> */}
                  </>
                );
              }}
            />
          </Grid>
        )}

        <Grid item xs={12} md={3}>
          <Controller
            name="natureza"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <StyledAsteriskTextField
                  select
                  data-testid="inputMetodoColeta"
                  disabled={disableOtherInputs}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleSetFilter(
                      'Método de Coleta de HPV: ' + e.target.value,
                      'natureza'
                    );
                  }}
                  value={value ? value : ''}
                  fullWidth
                  label="Método de Coleta de HPV"
                  size="small"
                  variant="outlined"
                >
                  <MenuItem value={'autocoleta'}>Autocoleta</MenuItem>
                  <MenuItem value={'profissional'}>
                    Profissional de Saúde
                  </MenuItem>
                </StyledAsteriskTextField>
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={watch('realizou_atendimento') === 'Não' ? 3 : 4}>
          <Controller
            name="motivo"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <StyledAsteriskTextField
                  select
                  data-testid="inputMotivoExame"
                  disabled={disableOtherInputs}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleSetFilter(
                      'Motivo do Exame: ' +
                        optionsMotivoExame.find(
                          (opt) => opt.value === e.target.value
                        )?.label,
                      'motivo'
                    );
                  }}
                  value={value ? value : ''}
                  fullWidth
                  label="Motivo do exame"
                  size="small"
                  variant="outlined"
                >
                  {optionsMotivoExame.map((opt) => {
                    return <MenuItem value={opt.value}>{opt.label}</MenuItem>;
                  })}
                </StyledAsteriskTextField>
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={watch('realizou_atendimento') === 'Não' ? 3 : 4}>
          <Controller
            name="inspecao_colo"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  disabled={disableOtherInputs}
                  label="Inspeção do Colo"
                  data-testid="inputInspecaoColo"
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleSetFilter(
                      'Inspeção do Colo: ' +
                        optionsInspecao.find(
                          (opt) => opt.value === e.target.value
                        )?.label,
                      'inspecao_colo'
                    );
                  }}
                  value={value ? value : ''}
                  select
                  fullWidth
                  size="small"
                  variant="outlined"
                >
                  {optionsInspecao.map((opt) => (
                    <MenuItem value={opt.value}>{opt.label}</MenuItem>
                  ))}
                </TextField>
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={watch('realizou_atendimento') === 'Não' ? 3 : 4}>
          <SelectWithSearch
            requestInSearch
            // requestOptionsBySearch={(text) => {
            //   getProfissionaisList({ bySearchInput: false, nome: text });
            // }}
            loading={loadingResponsaveis}
            options={responsaveis}
            isLaboratorio
            label="Profissional Responsável pela Coleta"
            disabled={disableOtherInputs}
            selectedOptions={selectedOptinsProfissionalResponsavel}
            setSelectedOptions={setSelectedOptinsProfissionalResponsavel}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedOptinsProfissionalResponsavel,
              fieldName: 'Profissional Responsável pela Coleta',
            })}
            dataTestIdModal="modalLaboratorioLaudoInput"
            dataTestIdInputSearch="inputSearchLaboratorioLaudo"
            dataTestSelectAll="selectAllLaboratorioLaudo"
            dataTestClearAll="clearLaboratorioLaudo"
            // emptyCondition={estabelecimentosSelected.length !== 1}
            // emptyMessage="Selecione um Estabelecimento de Saúde"
          />
          {/* <Controller
            name="profissionalColetaId"
            data-testid="inputProfissionalResponsavelColeta"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  disabled={disableResponsaveisInput}
                  options={responsaveis}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                    handleSetFilter(
                      newValue?.nomeprofissional,
                      'responsavel_coleta'
                    );
                  }}
                  value={value ? value : ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Profissional Responsável pela Coleta"
                    />
                  )}
                />
              );
            }}
          /> */}
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
            gap={3}
          >
            <Typography fontWeight="bold">Data da Coleta:</Typography>

            <FormControl>
              <RadioGroup
                aria-labelledby="handleSetPeriod"
                name="handleSetPeriod"
                value={period}
                onChange={handleSetPeriod}
                row
              >
                <FormControlLabel
                  value="date"
                  data-testid="optionFiltrarDataColeta"
                  control={<Radio />}
                  label="Filtrar por Data"
                />
                <FormControlLabel
                  value="period"
                  data-testid="optionFiltrarPeriodoColeta"
                  control={<Radio />}
                  label="Filtrar por período"
                />
              </RadioGroup>
            </FormControl>

            {period === 'period' && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
                flex="1"
                rowGap={1}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs}
                    data-testid="periodDatepickerInitialDataColeta"
                    onChange={(date) => {
                      setDataColetaIni(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                      handleSetFilter(
                        `Início da Data da Coleta: ${moment(date).format(
                          'DD/MM/YYYY'
                        )}`,
                        'dt_coleta_inicio'
                      );
                    }}
                    maxDate={new Date()}
                    value={
                      dtColetaIni
                        ? moment(dtColetaIni, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Início da Data da Coleta"
                        size="small"
                        variant="outlined"
                        sx={{ minWidth: '200px' }}
                        error={!!errorInputDate?.ini}
                        helperText={errorInputDate?.ini}
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs}
                    data-testid="periodDatepickerFinalDataColeta"
                    onChange={(date) => {
                      {
                        setDataColetaFinal(
                          date ? moment(date).format('DD/MM/YYYY') : null
                        );
                        handleSetFilter(
                          `Fim da Data da Coleta: ${moment(date).format(
                            'DD/MM/YYYY'
                          )}`,
                          'dt_coleta_fim'
                        );
                      }
                    }}
                    maxDate={new Date()}
                    value={
                      dtColetaFinal
                        ? moment(dtColetaFinal, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Fim da Data da Coleta"
                        error={!!errorInputDateColeta}
                        helperText={errorInputDateColeta.fin}
                        size="small"
                        variant="outlined"
                        sx={{
                          minWidth: '200px',
                          marginBottom: !!errorInputDateColeta?.fin
                            ? '-22px'
                            : '',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            )}

            {period === 'date' && (
              <>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    data-testid="datepickerDataColeta"
                    onChange={(date) => {
                      setDataColetaIni(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                      setDataColetaFinal(
                        date ? moment(date).format('DD/MM/YYYY') : null
                      );
                    }}
                    maxDate={new Date()}
                    value={
                      dtColetaIni && moment(dtColetaIni, 'DD-MM-YYYY').isValid()
                        ? moment(dtColetaIni, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    disabled={disableOtherInputs}
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Data da Coleta"
                        size="small"
                        variant="outlined"
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </>
            )}
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={watch('resultado')?.value === 'POSITIVO' ? 3 : 6}
        >
          <Controller
            name="resultado"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  data-testid="inputResultado"
                  disabled={disableOtherInputs}
                  options={optionsResultadoHPV}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                    handleSetFilter(newValue?.label, 'resultado');
                  }}
                  value={value ? value : ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Resultado do Teste de HPV"
                    />
                  )}
                />
              );
            }}
          />
        </Grid>

        {watch('resultado')?.value === 'POSITIVO' && (
          <Grid item xs={12} md={3}>
            <Controller
              name="tipo_virus"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Autocomplete
                    data-testid="inputTiposVirus"
                    disabled={disableOtherInputs}
                    options={optionsTiposVirus}
                    onChange={(_, newValue) => {
                      onChange(newValue);
                      handleSetFilter(newValue?.label, 'resultado');
                    }}
                    value={value ? value : ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Tipos de Vírus"
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <SelectWithSearch
            options={optionsLaboratorios}
            isLaboratorio
            label="Laboratório Responsável pelo Laudo"
            disabled={
              userLaboratorio
                ? true
                : disableOtherInputs || disabledLaboratorioResultado
            }
            selectedOptions={selectedOptionsLaboratorio}
            setSelectedOptions={setSelectedOptionsLaboratorio}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedLaboratorio,
              fieldName: 'Laboratório Responsável pelo Laudo',
            })}
            dataTestIdModal="modalLaboratorioLaudoInput"
            dataTestIdInputSearch="inputSearchLaboratorioLaudo"
            dataTestSelectAll="selectAllLaboratorioLaudo"
            dataTestClearAll="clearLaboratorioLaudo"
          />
          {/* <Controller
            name="estabelecimentoLaudoId"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  disabled={disableOtherInputs}
                  data-testid="inputLaboratorioResponsavelLaudo"
                  options={[
                    {
                      id: 1473,
                      created_at: '2023-03-21T00:40:02.336Z',
                      updated_at: '2023-03-21T00:40:02.336Z',
                      razao_social: 'SECRETARIA DE SAUDE',
                      nome_fantasia:
                        'LAB CENTRAL DE SAUDE PUB DR MILTON BEZERRA SOBRAL LACEN',
                      cnes: '2712075',
                      cnpj_proprio: '10572048001361',
                      email: null,
                      cnpj_mantenedor: '10572048000128',
                      inativo: false,
                      dt_inicio: '2022-01-01T00:00:00.000Z',
                      dt_fim: null,
                      tipo_estab_id: null,
                      telefone: null,
                      gestao: null,
                      dependencia: null,
                      regional: null,
                      endereco: {
                        id: 1474,
                        created_at: '2023-03-21T00:40:02.336Z',
                        updated_at: '2023-03-21T00:40:02.336Z',
                        cep: '50050250',
                        logradouro: 'PRACA OSWALDO CRUZ',
                        numero: 'S/N',
                        complemento: null,
                        referencia: null,
                        zona: 'Urbana',
                        bairro: 'BOA VISTA',
                        municipio: {
                          id: 1597,
                          created_at: '2023-03-21T00:36:08.490Z',
                          updated_at: '2023-03-21T00:36:08.490Z',
                          nome: 'Recife',
                          codigo: '261160',
                          estadoSigla: 'PE',
                          ibge7: '2611606',
                          estado: {
                            id: 13,
                            created_at: '2023-03-21T00:36:08.490Z',
                            updated_at: '2023-03-21T00:36:08.490Z',
                            nome: 'Pernambuco',
                            sigla: 'PE',
                            codigo: '26',
                          },
                        },
                      },
                    },
                  ]}
                  getOptionLabel={(option) => option.nome_fantasia}
                  onChange={(_, newValue) => {
                    onChange(newValue ? newValue : null);
                    handleSetFilter(
                      newValue?.nome_fantasia,
                      'estabelecimentoLaudoId'
                    );
                  }}
                  value={value ? value : null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Laboratório Responsável pelo Laudo"
                    />
                  )}
                />
              );
            }}
          /> */}
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
            gap={3}
          >
            <Typography fontWeight="bold">Data do Resultado:</Typography>

            <FormControl>
              <RadioGroup
                aria-labelledby="handleSetPeriod"
                name="handleSetPeriod"
                value={periodResultado}
                onChange={handleSetPeriodResultado}
                row
              >
                <FormControlLabel
                  data-testid="optionFiltrarDataResultado"
                  value="date"
                  control={<Radio />}
                  label="Filtrar por Data"
                />
                <FormControlLabel
                  data-testid="optionFiltrarPeriodoResultado"
                  value="period"
                  control={<Radio />}
                  label="Filtrar por período"
                />
              </RadioGroup>
            </FormControl>

            {periodResultado === 'period' && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
                flex="1"
                // rowGap={1}
                gap={1}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    data-testid="periodDatepickerInitialDataResultado"
                    disabled={disableOtherInputs}
                    onChange={(date) => {
                      setDataResultadoIni(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                      handleSetFilter(
                        `Início da Data do Resultado: ${moment(date).format(
                          'DD/MM/YYYY'
                        )}`,
                        'dt_resultado_inicio'
                      );
                    }}
                    maxDate={new Date()}
                    value={
                      dtResultadoIni
                        ? moment(dtResultadoIni, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Início da Data do Resultado"
                        size="small"
                        error={!!errorInputDateResultado?.ini}
                        helperText={errorInputDateResultado?.ini}
                        variant="outlined"
                        // sx={{ minWidth: '200px' }}
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    disabled={disableOtherInputs}
                    data-testid="periodDatepickerFinalDataResultado"
                    onChange={(date) => {
                      setDataResultadoFinal(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                      handleSetFilter(
                        `Fim da Data do Resultado: ${moment(date).format(
                          'DD/MM/YYYY'
                        )}`,
                        'dt_resultado_fim'
                      );
                    }}
                    maxDate={new Date()}
                    value={
                      dtResultadoIni
                        ? moment(dtResultadoFinal, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Fim da Data do Resultado"
                        error={!!errorInputDateResultado}
                        helperText={errorInputDateResultado?.fin}
                        size="small"
                        variant="outlined"
                        sx={{
                          marginBottom: !!errorInputDateResultado?.fin
                            ? '-20px'
                            : '',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            )}

            {periodResultado === 'date' && (
              <>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    data-testid="datepickerDataResultado"
                    onChange={(date) => {
                      setDataResultadoFinal(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                      setDataResultadoIni(
                        date ? moment(date).format('DD-MM-YYYY') : null
                      );
                      handleSetFilter(
                        `Data do Resultado: ${moment(date).format(
                          'DD/MM/YYYY'
                        )}`,
                        'dt_resultado_inicio'
                      );
                    }}
                    maxDate={new Date()}
                    disabled={disableOtherInputs}
                    value={
                      dtResultadoFinal &&
                      moment(dtResultadoFinal, 'DD-MM-YYYY').isValid()
                        ? moment(dtResultadoFinal, 'DD-MM-YYYY').toDate()
                        : null
                    }
                    renderInput={(params) => (
                      <StyledAsteriskTextField
                        {...params}
                        label="Data do Resultado"
                        size="small"
                        variant="outlined"
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={9}>
          <Controller
            name="search"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  disabled={disableOtherInputs}
                  data-testid="inputSearchByNameCpf"
                  label="Pesquisar"
                  placeholder="Pesquisar por NOME, Cartão SUS e CPF da Paciente"
                  size="small"
                  error={!!errors?.search}
                  helperText={errors?.search?.message}
                  value={value ? value : ''}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleSetFilter(e.target.value, 'search');
                  }}
                  sx={{
                    width: '100%',
                    '.MuiOutlinedInput-root': {
                      paddingRight: '0',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        type="button"
                        onClick={() => {
                          validationSubmit({
                            fnWhenFormIsValid,
                            estadosSelected,
                            municipiosSelected,
                            estabelecimentosSelected,
                            setErrorsFields,
                            setDisableOtherInputs,
                            watch,
                            setError,
                            clearErrors,
                          });
                        }}
                      >
                        <SearchIcon sx={{ cursor: 'pointer', color: 'gray' }} />
                      </Button>
                    ),
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            data-testid="submitFilterReportsHpv"
          >
            FILTRAR
          </Button>
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            onClick={clearValues}
            data-testid="clearFilterReportsHpv"
          >
            LIMPAR
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FiltersReportsHpv;
