import { useContext, useEffect, useState } from 'react';
import { LoadingContext } from '../../context/LoadingContext';
import { getUserToken } from '../../lib/auth';
import { useNavigate } from 'react-router-dom';

const Template = ({ children }) => {
  const [showScreen, setShowScreen] = useState(false);
  const { setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!getUserToken().permissao_atual && window.location.pathname !== '/') {
      navigate('/');
      setLoading(false);
    } else {
      setShowScreen(true);
    }
  }, []);
  return <>{showScreen && <>{children}</>}</>;
};

export default Template;
