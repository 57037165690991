import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Divider, Grid, Paper, Tooltip, Typography } from '@mui/material';
import api from '../../../../api';
import { regex } from '../../../../utils/regex';

import { getProfissionais } from '../../../../lib/profissionais';
import { getConvocations } from '../../../../lib/convocation';

import FiltersConvocation from './FiltersConvocationList';
import { TotalCardsContainer } from './styles';
import ItemTotalCardConvocation from '../../../../components/ItemTotalCardConvocation';
import CardConvocated from './CardConvocated';
import TableConvocation from './TableConvocation';

import Activity from '../../../../assets/imgs/activity.svg';
import AlertInfo from '../../../../assets/imgs/AlertInfo.svg';
import GreenCheck from '../../../../assets/imgs/green-check.svg';
import YellowDots from '../../../../assets/imgs/yellow-dots.svg';
import RedXCircle from '../../../../assets/imgs/red-x-circle.svg';
import PurpleExclamation from '../../../../assets/imgs/purpe-exclamation.svg';
import TotalPatientsTablesReports from '../../../../components/TotalPatientsTablesReports';

interface Inputs {
  estado: any[];
  municipio: any[];
  estabelecimento: any[];
  acs: any;
  idade_inicio: string;
  idade_fim: string;
  status: any[];
  convocacao: any[];
  periodo: string;
  page: number;
  size: number;
}

const mockTableData = {
  rows: [
    {
      pessoa: {
        nome: 'Amélia',
        cpf: '13451991632',
        estabelecimento: {
          id: 0,
          nome_fantasia: 'Estabelecimento Vinculado',
        },
        endereco: {
          logradouro: 'RUA A',
          numero: '123',
          complemento: 'Casa B',
        },
      },
      profissional: {
        pessoa: {
          nome: 'José Maria Jesus',
        },
      },
      tipo_convocacao: 1,
      convocado_em: new Date(),
      agendado_em: new Date(),
      status: 0,
    },
    {
      pessoa: {
        nome: 'Amélia',
        cpf: '13451991632',
        estabelecimento: {
          id: 0,
          nome_fantasia: 'Estabelecimento Vinculado',
        },
        endereco: {
          logradouro: 'RUA A',
          numero: '123',
          complemento: 'Casa B',
        },
      },
      profissional: {
        pessoa: {
          nome: 'José Maria Jesus',
        },
      },
      tipo_convocacao: 1,
      convocado_em: new Date(),
      agendado_em: new Date(),
      status: 1,
    },
    {
      pessoa: {
        nome: 'Amélia',
        cpf: '13451991632',
        estabelecimento: {
          id: 0,
          nome_fantasia: 'Estabelecimento Vinculado',
        },
        endereco: {
          logradouro: 'RUA A',
          numero: '123',
          complemento: 'Casa B',
        },
      },
      profissional: {
        pessoa: {
          nome: 'José Maria Jesus',
        },
      },
      tipo_convocacao: 1,
      convocado_em: new Date(),
      agendado_em: new Date(),
      status: 2,
    },
    {
      pessoa: {
        nome: 'Amélia',
        cpf: '13451991632',
        estabelecimento: {
          id: 0,
          nome_fantasia: 'Estabelecimento Vinculado',
        },
        endereco: {
          logradouro: 'RUA A',
          numero: '123',
          complemento: 'Casa B',
        },
      },
      profissional: {
        pessoa: {
          nome: 'José Maria Jesus',
        },
      },
      tipo_convocacao: 1,
      convocado_em: new Date(),
      agendado_em: new Date(),
      status: 3,
    },
    {
      pessoa: {
        nome: 'Amélia',
        cpf: '13451991632',
        estabelecimento: {
          id: 0,
          nome_fantasia: 'Estabelecimento Vinculado',
        },
        endereco: {
          logradouro: 'RUA A',
          numero: '123',
          complemento: 'Casa B',
        },
      },
      profissional: {
        pessoa: {
          nome: 'José Maria Jesus',
        },
      },
      tipo_convocacao: 1,
      convocado_em: new Date(),
      agendado_em: new Date(),
      status: 2,
    },
  ],
};

type StateListFilter = { inputName: string; value: string };

const ListConvocation = () => {
  const [tableData, setTableData] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filter, setFilter] = useState<any>('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [optionsACS, setOptionsACS] = useState<any>([]);
  const [errorAgeMinMax, setErrorAgeMinMax] = useState(false);
  const [stateToSaveFilter, setStateToSaveFilter] = useState({});
  const [showTableData, setShowTableData] = useState(false);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      estado: [],
      municipio: [],
      estabelecimento: [],
      status: [],
      convocacao: [],
      page: 1,
      size: 1,
    },
  });

  const initialStateListFilter = [
    { inputName: 'estado', value: '' },
    { inputName: 'municipio', value: '' },
    { inputName: 'estabelecimento', value: '' },
    { inputName: 'acs', value: '' },
    { inputName: 'idade_inicio', value: '' },
    { inputName: 'idade_fim', value: '' },
    { inputName: 'status', value: '' },
    { inputName: 'convocacao', value: '' },
    { inputName: 'periodo_inicio', value: '' },
    { inputName: 'periodo_fim', value: '' },
    { inputName: 'search', value: '' },
  ];
  const [listFilter, setListFilter] = useState<StateListFilter[]>(
    initialStateListFilter
  );
  const [listFilterShow, setListFilterShow] = useState([]);

  const optionsStatusField = [
    { statusName: 'Agendado', value: 0 },
    { statusName: 'Pendentes', value: 1 },
    { statusName: 'Não Atendidas', value: 2 },
    { statusName: 'Não Elegível', value: 3 },
  ];

  const optionsAgesField: any = [];

  for (let age = 25; age <= 63; age++) {
    optionsAgesField.push({ ageLabel: `${age} anos`, value: age });
  }

  const optionsLastExamDate = [
    {
      dataLabel: 'Há 1 ano',
      value: 1,
    },
    { dataLabel: 'Há 2 anos', value: 3 },
    { dataLabel: 'Há Mais de 3 anos', value: 4 },
  ];

  // const handleClearFilter = () => {
  //   setListFilter(initialStateListFilter);
  //   listFilter.map((item) => {
  //     return setValue(item.inputName, '');
  //   });

  //   reset();
  // };

  const subtractDays = (dateValue) => {
    const currentDate = new Date();
    const newDate = dateValue
      ? new Date(
          currentDate.getFullYear() - dateValue,
          currentDate.getMonth(),
          currentDate.getDate()
        )
      : undefined;

    const formattedDate = newDate ? newDate?.toISOString().slice(0, 10) : '';

    if (dateValue) {
      return formattedDate;
    } else {
      return undefined;
    }
  };

  const currentEstablishment = localStorage.getItem('id_last_establishment');
  const getProfissionaisList = async () => {
    const response = await getProfissionais({
      estabelecimentoId: String(currentEstablishment),
    });
    setOptionsACS(response.data);
  };

  const submitForm = async (data) => {
    const nascMin = subtractDays(data.nasc_min?.value);
    const nascMax = subtractDays(data.nasc_max?.value);
    const procedimentoDate = subtractDays(data.ultimo_Procedimento?.value);

    const response = await getConvocations({
      getParams: {
        ...data,
        ultimo_Procedimento: procedimentoDate,
        nasc_min: nascMin,
        nasc_max: nascMax,
        size: String(rowsPerPage),
        page: '1',
      },
    });

    setStateToSaveFilter({
      n_convocacao: data.n_convocacao,
      profissionalId: data.profissionalId?.id,
      nasc_min: nascMin,
      nasc_max: nascMax,
      ultimo_Procedimento: procedimentoDate,
      status: data.status?.value,
    });

    setTableData({ ...response, rows: response.data });
  };

  const getInitialConvocationList = async () => {
    return;
    // const response = await getConvocations({
    //   getParams: {
    //     ...stateToSaveFilter,
    //     size: String(rowsPerPage),
    //     page: String(currentPage),
    //   },
    // });
    // setTableData({ ...response, rows: response.data });
  };

  function checkIfIsNameOrCPF(inputString) {
    if (regex.CPF.test(inputString)) {
      return 'CPF';
    }

    if (/^[A-Za-z\s]+$/.test(inputString)) {
      return 'NOME';
    }

    return 'Valor invalido';
  }

  const filterByNameOrCpf = async () => {
    const inputType = checkIfIsNameOrCPF(filter);
    if (!filter) {
      const response = await getConvocations({
        getParams: {
          ...stateToSaveFilter,
          nome: undefined,
          size: String(rowsPerPage),
          page: String(currentPage),
        },
      });

      setTableData({ ...response, rows: response.data });
    }
    if (inputType === 'NOME') {
      const response = await getConvocations({
        getParams: {
          ...stateToSaveFilter,
          nome: filter,
          size: String(rowsPerPage),
          page: String(currentPage),
        },
      });
      setTableData({ ...response, rows: response.data });
    } else if (inputType === 'CPF') {
      await api
        .get(`/convocacao/cpf/${filter}`, {})
        .then((response) => {
          const data: any[] = [];
          if (response.data.pessoa) {
            data.push({
              ...response.data,
              pessoa: response.data.pessoa,
            });
          }
          const res = {
            agendadas: '0',
            agendadasNestaSemana: '0',
            count: data.length,
            data,
            naoAtendidas: '0',
            naoAtendidasNestaSemana: '0',
            naoElegiveis: '0',
            naoElegiveisNestaSemana: '0',
            page: data.length,
            pendentes: data.length,
            pendentesNestaSemana: '0',
            totalItems: data.length,
            totalPages: data.length,
          };

          setTableData({ ...res, rows: res.data });
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  };

  useEffect(() => {
    getInitialConvocationList();
    getProfissionaisList();
  }, []);

  // const valueAgeMin = watch('nasc_min')?.value;
  // const valueAgeMax = watch('nasc_max')?.value;

  const totalCount =
    Number(tableData?.pendentes) +
    Number(tableData?.naoElegiveis) +
    Number(tableData?.naoAtendidas) +
    Number(tableData?.agendadas);

  // useEffect(() => {
  //   if (valueAgeMin > valueAgeMax) {
  //     setErrorAgeMinMax(true);
  //   } else {
  //     setErrorAgeMinMax(false);
  //   }
  // }, [valueAgeMin, valueAgeMax]);

  return (
    <Box mt={5}>
      <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          style={{ marginBottom: '30px', fontSize: '24px' }}
        >
          Listagem de Convocações
        </Typography>
        <Divider />
        <FiltersConvocation
          setValue={setValue}
          handleSubmit={handleSubmit}
          control={control}
          clearErrors={clearErrors}
          watch={watch}
          setError={setError}
          errors={errors}
          initialStateListFilter={initialStateListFilter}
          listFilter={listFilter}
          setListFilter={setListFilter}
          setListFilterShow={setListFilterShow}
          listFilterShow={listFilterShow}
          setShowTableData={setShowTableData}
          showTableData={showTableData}
        />
        {/* <TotalCardsContainer>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              lg={2}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '139px',
                  justifyContent: 'space-between',
                }}
              >
                <img src={Activity} alt="" />
                <p
                  style={{
                    margin: 0,
                    color: 'rgba(0, 0, 0, 0.60)',
                    fontWeight: 700,
                  }}
                >
                  Todas
                </p>
                <Tooltip
                  title="Quantidade total de  convocações feitas"
                  placement="top"
                >
                  <img src={AlertInfo} alt="" style={{ marginLeft: '13px' }} />
                </Tooltip>
              </div>
              <h1
                style={{
                  fontSize: '96px',
                  margin: 0,
                  lineHeight: '68px',
                  color: 'rgba(0, 0, 0, 0.60)',
                  marginTop: '20px',
                }}
              >
                {totalCount ? totalCount : '0'}
              </h1>
              <p style={{ margin: 0, color: 'rgba(0, 0, 0, 0.60)' }}>
                Convocações
              </p>
              <span style={{ color: 'rgba(0, 0, 0, 0.60)' }}>
                Quarta, 26 Julho 2023
              </span>
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <ItemTotalCardConvocation
                color="#4CAF50"
                title="Agendadas"
                value={tableData.agendadas}
                bottomPhrase={`${tableData.agendadasNestaSemana} + essa semana`}
                icon={GreenCheck}
                tooltipTitle="Quantidade de convocações  que geraram agendamentos "
              />
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <ItemTotalCardConvocation
                color="#FFB547"
                title="Pendentes"
                value={tableData.pendentes}
                bottomPhrase={`${tableData.pendentesNestaSemana} + essa semana`}
                icon={YellowDots}
                tooltipTitle="Quantidade de convocações que ainda não tiveram resposta da convocação"
              />
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <ItemTotalCardConvocation
                color="#FC5555"
                title="Não Atendidas"
                value={tableData.naoAtendidas}
                bottomPhrase={`${tableData.naoAtendidasNestaSemana} + essa semana`}
                icon={RedXCircle}
                tooltipTitle="Quantidade de convocações  que não geraram agendamentos"
              />
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <ItemTotalCardConvocation
                color="#7986CB"
                title="Não Elegíveis"
                value={tableData.naoElegiveis}
                bottomPhrase={`${tableData.naoElegiveisNestaSemana} + essa semana`}
                icon={PurpleExclamation}
                tooltipTitle="Quantidade de convocações que estavam foram do público-alvo do exame"
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CardConvocated
                convocacaoPosition="1º Convocação"
                titleTooltip="teste"
                value={321}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CardConvocated
                convocacaoPosition="2º Convocação"
                titleTooltip="teste"
                value={321}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CardConvocated
                convocacaoPosition="3º Convocação"
                titleTooltip="teste"
                value={321}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CardConvocated
                convocacaoPosition="4º Convocação"
                titleTooltip="teste"
                value={321}
              />
            </Grid>
          </Grid>
          <Grid xs={12} textAlign="end">
            <p
              style={{
                color: '#00000061',
                fontSize: '12px',
                lineHeight: '12px',
                fontWeight: '400',
                marginTop: '24px',
                marginBottom: '8px',
              }}
            >
              1-3 Convocação: Convocação por Autoatendimento <br />
              4+ Convocação: Convocação por Profissionais de Saúde
            </p>
          </Grid>
        </TotalCardsContainer> */}
        <TotalPatientsTablesReports watch={watch} totalItems={25} />
        <TableConvocation
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          setCurrentPage={setCurrentPage}
          setRowsPerPage={setRowsPerPage}
          setTableData={setTableData}
          tableData={mockTableData}
          stateToSaveFilter={stateToSaveFilter}
        />
      </Paper>
    </Box>
  );
};

export default ListConvocation;
