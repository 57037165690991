import { Paper, TablePagination, Typography } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { useEffect, useState } from 'react';

export const PatientsResponseChart = () => {
  const series = [
    {
      name: 'Quiseram Agendar',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 66, 66],
    },
    {
      name: 'Não Quiseram',
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 66, 66],
    },
    {
      name: 'Mulheres Ausentes',
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 66, 66],
    },
  ];
  const [chartData, setChartData] = useState<any>();

  useEffect(() => {
    setChartData({
      series,
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        itemMargin: {
          horizontal: 40,
        },
      },
      colors: ['#238884', '#F44336', '#9E9E9E'],

      chart: {
        type: 'bar',
        height: 391,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Dez',
        ],
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return '$ ' + val + ' thousands';
          },
        },
      },
    });
  }, []);

  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: '10px',
        borderColor: '#BBC5CA',
        marginTop: '24px',
        position: 'relative',
        padding: '16px',
        height: '535px',
      }}
    >
      <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>
        Tempo Médio das Respostas
      </Typography>
      <Typography sx={{ fontSize: '12px', color: '#00000061' }}>
        Todos os Períodos
      </Typography>

      {chartData ? (
        <ReactApexChart
          options={chartData}
          series={chartData?.series}
          type="bar"
          height={391}
        />
      ) : undefined}

      <p
        style={{
          marginTop: '0',
          marginBottom: '16px',
          marginRight: '16px',
          bottom: '0',
          right: '0',
          position: 'absolute',
          fontSize: '10px',
          color: 'rgba(0, 0, 0, 0.6)',
        }}
      >
        Fonte dos Dados: JORDANA
      </p>
    </Paper>
  );
};
