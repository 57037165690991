import moment from 'moment';

import smartphone from '../../../../../../../assets/imgs/Convocation/smartphone.svg';
import dot from '../../../../../../../assets/imgs/Convocation/dot.svg';
import connector from '../../../../../../../assets/imgs/Convocation/connector.svg';
type Props = {
  convocations: Convocations[];
};

type Convocations = {
  receivedAt: string;
  numberCorret: {
    is: boolean;
    responsedAt: string;
  };
};

const ConvocationItens = ({ convocations }: Props) => {
  return (
    <>
      {convocations.map((item, index) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img src={smartphone} style={{ width: '64px' }} />
            <p
              style={{
                fontSize: '16px',
                fontFamily: 'Roboto',
                fontWeight: 700,
              }}
            >
              {index + 1}º Convocatória
            </p>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px' }} src={dot} />
              <p
                style={{
                  fontWeight: 500,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  margin: '0 0 6.5px 14px',
                }}
              >
                Recebida em:
              </p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px', height: '35px' }} src={connector} />
              <p
                style={{
                  fontWeight: 400,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  margin: '0 0 0 14px',
                  paddingTop: '7px',
                }}
              >
                {item.receivedAt}
              </p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px' }} src={dot} />
              <p
                style={{
                  fontWeight: 500,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  margin: '0 0 6.5px 14px',
                }}
              >
                Número estava correto?
              </p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px', height: '35px' }} src={connector} />
              <p
                style={{
                  fontWeight: 400,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  margin: '0 0 0 14px',
                  paddingTop: '7px',
                }}
              >
                {item.numberCorret.is ? 'Sim' : 'Não'}
                <br />
                <span
                  style={{
                    width: '199px',
                    display: 'block',
                    fontStyle: 'italic',
                  }}
                >
                  Respondido em{' '}
                  {moment(item.numberCorret.responsedAt).format(
                    'DD/MM/YYYY [às] HH[h]mm'
                  )}
                </span>
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ConvocationItens;
