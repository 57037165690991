import smartphone from '../../../../../../../assets/imgs/Convocation/smartphone.svg';
import dot from '../../../../../../../assets/imgs/Convocation/dot.svg';
import connector from '../../../../../../../assets/imgs/Convocation/connector.svg';
import flask from '../../../../../../../assets/imgs/Convocation/flask.svg';

import moment from 'moment';
type Props = {
  hpv: Hpv[];
};

type Hpv = {
  present: boolean;
  receivedResult: string;
};

const HpvItens = ({ hpv }: Props) => {
  return (
    <>
      {hpv.map((item, index) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img src={flask} style={{ width: '64px' }} />
            <p
              style={{
                fontSize: '16px',
                fontFamily: 'Roboto',
                fontWeight: 700,
              }}
            >
              Teste de HPV
            </p>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px' }} src={dot} />
              <p
                style={{
                  fontWeight: 500,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  margin: '0 0 6.5px 14px',
                }}
              >
                Compareceu?
              </p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px', height: '35px' }} src={connector} />
              <p
                style={{
                  fontWeight: 400,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  margin: '0 0 0 14px',
                  paddingTop: '7px',
                  width: '196px',
                }}
              >
                {item.present ? 'Sim' : 'Não'}
              </p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px' }} src={dot} />
              <p
                style={{
                  fontWeight: 500,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  margin: '0 0 6.5px 14px',
                }}
              >
                Recebeu o resultado em:
              </p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px', height: '35px' }} src={connector} />
              <p
                style={{
                  fontWeight: 400,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  margin: '0 0 0 14px',
                  paddingTop: '7px',
                }}
              >
                {moment(item.receivedResult).format('DD/MM/YYYY')}
                <br />
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default HpvItens;
