import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import ModalEstado from '../../../../../components/MultipleFilters/Modals/ModalEstado';
import useStatesFields from '../../../../../components/MultipleFilters/hooks/useStatesFields';
import api from '../../../../../api';
import { StyledAsteriskTextField } from '../../../../../components/StyledAsteriskTextField/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import InputMask from 'react-input-mask';
import CheckIcon from '@mui/icons-material/Check';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { getUserToken } from '../../../../../lib/auth';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const FilterIndicadores = ({
  control,
  getAllCharts,
  setPayload,
  watch,
  payload,
  setListFilter,
  setListFilterShow,
  initialStateListFilter,
  setDatesLabel,
  setValue,
  setPeriod,
  period,
  setRegiaoLabel,
}) => {
  const today = moment().format('YYYY-MM-DD');
  const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');

  const [estadosSelected, setEstadosSelected] = useState<any[]>([]);
  const [anchorRegiao, setAnchorRegiao] = useState<any>(null);
  const [regioesOptions, setRegioesOptions] = useState<any>([]);
  const [searchRegiao, setSearchRegiao] = useState('');
  const [allRegioesOptions, setAllRegioesOptions] = useState<any>([]);
  const [regiaoSelected, setRegiaoSelected] = useState<any>([]);
  const [municipiosRegiaoSelected, setMunicipiosRegiaoSelected] = useState<any>(
    []
  );
  const [openModalPeriodo, setOpenModalPeriodo] = useState(false);
  const [valuePeriodoModal, setValuePeriodoModal] = useState<any>({
    label: 'Últimos 12 meses',
    value: 'twelve_months',
  });
  const [year, setYear] = useState<any>('');
  const [valueInputPeriod, setValueInputPeriod] =
    useState<any>('Últimos 12 meses');
  const [valueInputQuadrimestreSemestre, setValueInputQuadrimestreSemestre] =
    useState<any>();
  const [endDayAndMonth, setEndDayAndMonth] = useState<any>();
  const [initialDayAndMonth, setInitialDayAndMonth] = useState<any>();
  const permissaoId = getUserToken().permissao_atual?.id;

  const isPadrao =
    permissaoId === 1 ||
    permissaoId === 9 ||
    permissaoId === 10 ||
    permissaoId === 7;

  const isMunicipal = permissaoId === 3;
  const isEstadual = permissaoId === 4;

  useEffect(() => {
    if (isPadrao || isMunicipal || isEstadual) {
      setEstadosSelected(['PE']);
    }
  }, [isPadrao, isMunicipal, isEstadual]);

  const {
    anchorElEstados,
    searchEstado,
    estadosOptions,
    allEstadosOptions,
    loadingEstados,
    setAnchorElEstados,
    setSearchEstado,
    setEstadosOptions,
    setAllEstadosOptions,
    setLoadingEstados,

    disableInputEstado,

    errorsFields,
  } = useStatesFields();

  const handleClearFilter = () => {
    setPeriod({
      periodo_fim: today,
      periodo_inicio: twelveMonths,
    });
    setValueInputPeriod('Últimos 12 meses');
    setValuePeriodoModal({
      label: 'Últimos 12 meses',
      value: 'twelve_months',
    });
    setValueInputQuadrimestreSemestre(null);
    setYear(null);

    setSearchEstado('');
    setValue('periodo_inicio', '');
    setValue('periodo_fim', '');

    setEstadosSelected([]);
    setRegiaoSelected([]);
    setMunicipiosRegiaoSelected([]);

    getAllCharts({
      payload: {
        params: {
          periodo_inicio: twelveMonths,
          periodo_fim: today,
        },
      },
    });
  };

  const handleSetFilter = (newValue: any, inputName: string) => {
    setListFilter((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const loadRegioes = async ({ estadoSigla }) => {
    try {
      const response = await api.get(`/regiao/estado/${estadoSigla}`);
      let arrRegioes: any = [];

      Object.keys(response.data).forEach((regiao, index) => {
        const originalRegiaoObj = response.data[regiao];

        const regiaoObjFormated = {
          regiaoName: `Região ${index + 1}`,
          municipioList: originalRegiaoObj.municipios,
        };

        arrRegioes.push(regiaoObjFormated);
      });

      setRegioesOptions(arrRegioes);
    } catch (error) {}
  };

  const handleSelectMultiplesFields = ({
    value,
    setState,
    state,
    paramToSave,
  }) => {
    const isSelected = state?.find(
      (itemSaved) => String(itemSaved) === String(value[paramToSave])
    );

    if (isSelected) {
      setState((prev) => {
        return prev.filter(
          (itemSaved) => String(itemSaved) !== String(value[paramToSave])
        );
      });
    } else {
      setState((prev) => {
        return [...prev, value[paramToSave]];
      });
    }
  };

  useEffect(() => {
    handleSetFilter(
      `Período: ${moment(period?.periodo_inicio, 'YYYY-MM-DD').format(
        'DD/MM/YYYY'
      )} a ${moment(period?.periodo_fim, 'YYYY-MM-DD').format('DD/MM/YYYY')}`,
      'periodo'
    );
  }, [period]);

  useEffect(() => {
    if (estadosSelected.length === 1) {
      loadRegioes({ estadoSigla: estadosSelected[0] });
      handleSetFilter('Estado: PE', 'estado');
    }

    if (estadosSelected.length > 1) {
      handleSetFilter('Estados: Vários', 'estado');
    }
    if (estadosSelected.length === 0) {
      handleSetFilter('', 'estado');
    }
  }, [estadosSelected]);

  useEffect(() => {
    setPayload({
      params: {
        ...payload.params,
        periodo_inicio: period?.periodo_inicio,
        periodo_fim: period?.periodo_fim,
        estados:
          estadosSelected.length > 0
            ? JSON.stringify(estadosSelected)
            : undefined,

        municipios:
          municipiosRegiaoSelected.length > 0
            ? municipiosRegiaoSelected.join(', ')
            : undefined,
      },
    });
  }, [period, estadosSelected, municipiosRegiaoSelected]);

  useEffect(() => {
    if (regiaoSelected.length === 1) {
      const municipiosOffRegiao = regioesOptions?.find(
        (regiao: any) => regiao?.regiaoName === regiaoSelected[0]
      )?.municipioList;
      handleSetFilter(`Região de Saúde: ${regiaoSelected[0]}`, 'regiao');
      setMunicipiosRegiaoSelected(municipiosOffRegiao);
    }
    if (regiaoSelected.length > 1) {
      let list: any = [];
      regiaoSelected?.forEach((regiaoSelected) => {
        const listSelectedRegiao = regioesOptions.find((regiaoOption) => {
          return regiaoOption.regiaoName === regiaoSelected;
        }).municipioList;

        list.push(...listSelectedRegiao);
      });

      handleSetFilter('Regiões de Saúde: Várias', 'regiao');
      setMunicipiosRegiaoSelected(list);
    }
    if (regiaoSelected.length === 0) {
      handleSetFilter('', 'regiao');
      setMunicipiosRegiaoSelected([]);
    }
  }, [regiaoSelected]);

  const setValuesInLastTwelveMonths = () => {
    setValueInputPeriod('Últimos 12 meses');
    setOpenModalPeriodo(false);
    const today = moment().format('YYYY-MM-DD');
    const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');

    setPeriod({
      periodo_fim: today,
      periodo_inicio: twelveMonths,
    });
  };
  const setValuesQuadrimestreSelected = () => {
    setValueInputPeriod('Quadrimestral');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-04-01');
    setValueInputQuadrimestreSemestre('');
    setPeriod(undefined);
  };

  const setValuesSemestreSelected = () => {
    setValueInputPeriod('Semestral');
    setValueInputQuadrimestreSemestre('');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-06-01');
    setPeriod(undefined);
  };

  const setValuesAnualSelected = () => {
    setValueInputPeriod('Anual');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-12-31');

    setPeriod(undefined);
  };

  const handleChangeWhenYearSelected = (newValue) => {
    setYear(newValue);
    if (valueInputPeriod === 'Quadrimestral') {
      setValueInputQuadrimestreSemestre('');
    }

    if (valueInputPeriod === 'Semestral') {
      setValueInputQuadrimestreSemestre('');
    }
    if (valueInputPeriod === 'Anual') {
      setPeriod({
        periodo_fim: `${newValue}-12-31`,
        periodo_inicio: `${newValue}-01-01`,
      });
    }
  };

  const handleChangeSemestreOrQuadrimestre = (newValue) => {
    setValueInputQuadrimestreSemestre(newValue);
    switch (newValue) {
      case '1º Semestre':
        setPeriod({
          periodo_fim: `${year}-06-01`,
          periodo_inicio: `${year}-01-01`,
        });
        break;
      case '2º Semestre':
        setPeriod({
          periodo_fim: `${year}-12-01`,
          periodo_inicio: `${year}-06-01`,
        });
        break;
      case '1º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-05-01`,
          periodo_inicio: `${year}-01-01`,
        });
        break;
      case '2º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-09-01`,
          periodo_inicio: `${year}-05-01`,
        });
        break;
      case '3º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-12-31`,
          periodo_inicio: `${year}-09-01`,
        });
        break;
      default:
        break;
    }
  };

  const handleCloseModal = () => {
    setPeriod(null);

    setOpenModalPeriodo(false);
    setPeriod(null);
    setYear(null);
    setValueInputPeriod(null);
    setEndDayAndMonth(null);
    setInitialDayAndMonth(null);
    setValuePeriodoModal(null);
    setValueInputQuadrimestreSemestre(null);
  };

  const handleSelectPeriodModal = (newValue) => {
    setValuePeriodoModal(newValue);
    if (!newValue) {
      setPeriod(undefined);
      setYear(undefined);
      setValueInputPeriod(null);
    }
    switch (newValue?.value) {
      case 'twelve_months':
        setValuesInLastTwelveMonths();
        break;
      case 'four_months':
        setValuesQuadrimestreSelected();
        break;
      case 'six_months':
        setValuesSemestreSelected();

        break;
      case 'yearly':
        setValuesAnualSelected();
        break;
      default:
        break;
    }
  };
  const optionsPeriodo = [
    {
      label: 'Últimos 12 meses',
      value: 'twelve_months',
    },
    {
      label: 'Quadrimestral',
      value: 'four_months',
    },
    { label: 'Semestral', value: 'six_months' },
    { label: 'Anual', value: 'yearly' },
  ];

  const optionsQuadrimestre = [
    '1º Quadrimestre',
    '2º Quadrimestre',
    '3º Quadrimestre',
  ];
  const optionsSemestre = ['1º Semestre', '2º Semestre'];

  return (
    <>
      <Dialog
        open={openModalPeriodo}
        onClose={() => setOpenModalPeriodo(false)}
      >
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            Tipo de Período
          </Typography>
          <DialogContent sx={{ padding: '0', marginTop: '30px' }}>
            <Paper
              variant="outlined"
              sx={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                Selecione o Período para Visualização dos Dados
              </Typography>
              <Autocomplete
                placeholder="Tipo de Período"
                value={valuePeriodoModal}
                onChange={(_, newValue: any) => {
                  handleSelectPeriodModal(newValue);
                }}
                options={optionsPeriodo}
                getOptionLabel={(option) => option.label ?? ''}
                renderInput={(params) => (
                  <StyledAsteriskTextField
                    {...params}
                    fullWidth
                    label="Tipo de Período"
                    variant="outlined"
                  />
                )}
              />
              {valueInputPeriod === 'Últimos 12 meses' ||
              !valueInputPeriod ? undefined : (
                <Autocomplete
                  options={['2024', '2023', '2022']}
                  onChange={(_, newValue) => {
                    handleChangeWhenYearSelected(newValue);
                  }}
                  value={year ? year : ''}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecione o Ano" />
                  )}
                />
              )}
              {}

              {(valueInputPeriod === 'Quadrimestral' ||
                valueInputPeriod === 'Semestral') && (
                <Autocomplete
                  options={
                    valueInputPeriod === 'Quadrimestral'
                      ? optionsQuadrimestre
                      : optionsSemestre
                  }
                  value={
                    valueInputQuadrimestreSemestre
                      ? valueInputQuadrimestreSemestre
                      : ''
                  }
                  onChange={(_, newValue) => {
                    handleChangeSemestreOrQuadrimestre(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        valueInputPeriod === 'Quadrimestral'
                          ? 'Selecione o Quadrimestre'
                          : 'Selecione o Semestre'
                      }
                    />
                  )}
                />
              )}
            </Paper>
          </DialogContent>
        </DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              handleCloseModal();
            }}
            startIcon={<ClearIcon />}
          >
            CANCELAR
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenModalPeriodo(false)}
            endIcon={<CheckIcon />}
          >
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <ModalEstado
            anchorElEstados={anchorElEstados}
            searchEstado={searchEstado}
            estadosOptions={estadosOptions}
            allEstadosOptions={allEstadosOptions}
            estadosSelected={estadosSelected}
            setAnchorElEstados={setAnchorElEstados}
            setSearchEstado={setSearchEstado}
            setEstadosOptions={setEstadosOptions}
            setAllEstadosOptions={setAllEstadosOptions}
            setEstadosSelected={setEstadosSelected}
            disableInputEstado={disableInputEstado}
            errors={errorsFields}
            loadingEstados={loadingEstados}
            setLoadingEstados={setLoadingEstados}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <>
            <StyledAsteriskTextField
              size="small"
              disabled={
                estadosSelected.length === 0 || estadosSelected.length > 1
              }
              label="Região de Saúde"
              value={
                regiaoSelected.length > 1 ? 'Vários' : regiaoSelected[0] ?? ''
              }
              sx={{
                padding: 0,
                width: '100%',
                '.MuiOutlinedInput-root': {
                  paddingRight: '9px',
                },
              }}
              InputProps={{
                endAdornment: (
                  <Button
                    sx={{
                      padding: 0,
                      minWidth: '24px !important',
                    }}
                    disabled={
                      estadosSelected.length === 0 || estadosSelected.length > 1
                    }
                    onClick={(event) => {
                      window.scrollTo(0, 0);
                      setAnchorRegiao(event.currentTarget);
                    }}
                  >
                    <ArrowDropDownIcon
                      sx={{
                        cursor: 'pointer',
                        color: 'gray',
                      }}
                    />
                  </Button>
                ),
              }}
            />

            <Menu
              anchorEl={anchorRegiao}
              open={!!anchorRegiao}
              onClose={() => setAnchorRegiao(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                position: 'absolute',
                width: '100%',
                marginTop: '10px',
                left: '10px',

                '.MuiList-root': {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              }}
            >
              <div
                style={{
                  width: '100%',
                  padding: '24px 32px',
                  flexDirection: 'column',
                }}
              >
                <TextField
                  size="small"
                  placeholder="Pesquise por Região"
                  onChange={(e) => {
                    setSearchRegiao(e.target.value);
                  }}
                  value={searchEstado}
                  sx={{
                    width: '289px',
                    '.MuiOutlinedInput-root': {
                      paddingRight: '9px',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        sx={{
                          padding: 0,
                          minWidth: '24px !important',
                        }}
                        onClick={() => {
                          if (!searchRegiao) {
                            setRegioesOptions(allRegioesOptions);
                          } else {
                            const newArrRegioes = allRegioesOptions.filter(
                              (regiao) =>
                                regiao.regiaoName
                                  .toLowerCase()
                                  .includes(searchRegiao.toLowerCase())
                            );
                            setRegioesOptions(newArrRegioes);
                          }
                        }}
                      >
                        <SearchIcon
                          sx={{
                            cursor: 'pointer',
                            color: 'gray',
                          }}
                        />
                      </Button>
                    ),
                  }}
                />
                <div>
                  <Button
                    variant="text"
                    onClick={() => {
                      const allItemsArray = regioesOptions.map(
                        (regiao: any) => regiao.regiaoName
                      );
                      let AllMunicipios: any = [];

                      regioesOptions.forEach((regiao: any) => {
                        regiao.municipioList.forEach((municipio) => {
                          AllMunicipios.push(municipio);
                        });
                      });

                      setMunicipiosRegiaoSelected(AllMunicipios);

                      setRegiaoSelected(allItemsArray);
                    }}
                  >
                    SELECIONAR TODOS -{' '}
                  </Button>
                  <Button variant="text" onClick={() => setRegiaoSelected([])}>
                    LIMPAR
                  </Button>
                </div>
                {regioesOptions?.map((regiao: any) => {
                  return (
                    <div style={{ display: 'flex' }}>
                      <Checkbox
                        onChange={() => {
                          handleSelectMultiplesFields({
                            paramToSave: 'regiaoName',
                            setState: setRegiaoSelected,
                            state: regiaoSelected,
                            value: regiao,
                          });
                        }}
                        checked={
                          regiaoSelected?.find(
                            (item) => item === regiao.regiaoName
                          )
                            ? true
                            : false
                        }
                      />
                      <MenuItem
                        sx={{ padding: 0 }}
                        onClick={() => {
                          handleSelectMultiplesFields({
                            paramToSave: 'regiaoName',
                            setState: setRegiaoSelected,
                            state: regiaoSelected,
                            value: regiao,
                          });
                        }}
                      >
                        {regiao?.regiaoName}
                      </MenuItem>
                    </div>
                  );
                })}
              </div>
            </Menu>
          </>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Controller
            name="periodo"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <StyledAsteriskTextField
                  fullWidth
                  size="small"
                  label="Período"
                  value={valueInputPeriod ? valueInputPeriod : ''}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ padding: 0 }}
                        onClick={() => setOpenModalPeriodo(true)}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                    ),
                  }}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} lg={1.5}>
          <Button
            sx={{ width: '100%' }}
            onClick={() => {
              setDatesLabel(valueInputPeriod);
              getAllCharts({ payload });
              setRegiaoLabel(
                (regiaoSelected.length > 1 && 'Várias Regiões') ||
                  (regiaoSelected.length === 0 && '') ||
                  (regiaoSelected.length === 1 && regiaoSelected[0])
              );
            }}
            variant="contained"
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={12} lg={1.5}>
          <Button
            type="button"
            sx={{ width: '100%' }}
            onClick={() => {
              handleClearFilter();
              setListFilterShow(initialStateListFilter);
              setListFilter(initialStateListFilter);
              setDatesLabel('Últimos 12 meses');
            }}
            variant="outlined"
            color="error"
          >
            Limpar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FilterIndicadores;
