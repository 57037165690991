// import { useContext } from 'react';
// import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
// import { getUserToken } from '../../../../../lib/auth';

// const useClearValues = ({
//   permissao,
//   setValue,
//   handleSetFilter,
//   watch,
//   loadReportsTrackingHpv,
//   setTableData,
//   setUsePayloadFromCharts,
//   showTableData,
//   setShowTableData,
//   clearErrors,
//   setEstadosSelected,
//   setMunicipiosSelected,
//   setEstabelecimentosSelected,
//   setAcsSelected,
//   setListFilterToShow,
//   listFilter,
//   getReportsTracking,
// }) => {
//   const fieldsToClearWhenIsPadrao: any[] = [
//     'acs',
//     'idade_inicio',
//     'idade_final',
//     'faixa',
//     'prioridade',
//     'meio_rastreio',
//     'search',
//     'has_teste',
//     'periodo',
//   ];

//   const fieldsToClearWhenIsMunicipal = [
//     'estabelecimento_saude',
//     ...fieldsToClearWhenIsPadrao,
//   ];

//   const fieldsToClearWhenEstadual = [
//     'municipio',
//     ...fieldsToClearWhenIsMunicipal,
//   ];

//   const fieldsToClearWhenSuperAdmin = ['estado', ...fieldsToClearWhenEstadual];

//   const { currentEstablishment } = useContext(EstablishmentContext);

//   const clearValues = ({
//     setPeriod,
//     setValueInputPeriod,
//     setValuePeriodoModal,
//     setValueInputQuadrimestreSemestre,
//     setYear,
//     setSelectedInicialAge,
//     setSelectedFinalAge,
//     setSelectedFaixa,
//     setSelectedPrioridade,
//     setSelectedMeio,
//     setSelectedRealizacaoTeste,
//   }) => {
//     setPeriod(null);
//     setValueInputPeriod(null);
//     setValuePeriodoModal(null);
//     setValueInputQuadrimestreSemestre(null);
//     setYear(null);

//     setSelectedInicialAge([]);
//     setSelectedFinalAge([]);
//     setSelectedFaixa([]);
//     setSelectedPrioridade([]);
//     setSelectedMeio([]);
//     setSelectedRealizacaoTeste([]);

//     clearErrors([]);
//     setTableData([]);
//     setShowTableData(false);

//     const handleSetFilterToShow = (newValue: any, inputName: string) => {
//       setListFilterToShow((prev) =>
//         prev.map((item) =>
//           item.inputName === inputName ? { ...item, value: newValue } : item
//         )
//       );
//     };
//     const userToken = getUserToken();
//     const isSuperAdmin = userToken.permissao_atual?.nome === 'SuperAdmin';
//     const isEstadual = userToken.permissao_atual?.nome === 'Admin Estadual';
//     const isMunicipal = userToken.permissao_atual?.nome === 'Admin Municipal';
//     const isAdminLaboratorio =
//       userToken.permissao_atual?.nome === 'Admin Estabelecimento';
//     const isPadraoEstabelecimento =
//       userToken.permissao_atual?.contexto === 'UNIDADE DE SAÚDE';

//     const {
//       estabelecimentoId: estabelecimentoIdUser,
//       estabelecimento_saude: {
//         nome_fantasia: estabelecimentoNameUser,
//         endereco: {
//           municipio: {
//             id: municipioUserId,
//             nome: municipioUserName,
//             estadoSigla: estadoUserSigla,
//           },
//         },
//       },
//     } = userToken;
//     if (isPadraoEstabelecimento) {
//       fieldsToClearWhenIsPadrao.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });

//       setShowTableData(true);

//       getReportsTracking({
//         estados: estadoUserSigla,
//         municipios: municipioUserId,
//         estabelecimentoSaudeId: estabelecimentoIdUser,
//         page: 1,
//         size: 25,
//       });
//     }

//     if (isMunicipal) {
//       fieldsToClearWhenIsMunicipal.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });

//       setEstabelecimentosSelected([]);

//       setShowTableData(true);

//       getReportsTracking({
//         estados: estadoUserSigla,
//         municipios: municipioUserId,
//         page: 1,
//         size: 25,
//       });
//     }

//     if (isEstadual || isAdminLaboratorio) {
//       fieldsToClearWhenEstadual.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });

//       setEstabelecimentosSelected([]);
//       setMunicipiosSelected([]);
//     }

//     if (isSuperAdmin) {
//       fieldsToClearWhenSuperAdmin.forEach((field) => {
//         setValue(field, '');
//         handleSetFilter('', field);
//         handleSetFilterToShow('', field);
//       });

//       setEstabelecimentosSelected([]);
//       setMunicipiosSelected([]);
//       setEstadosSelected([]);
//     }
//   };

//   return { clearValues };
// };

// export default useClearValues;

// // const clearValues = () => {
// //   setShowTableData(false);
// //   clearErrors();
// //   setUsePayloadFromCharts(false);
// //   setTableData([]);

// //   if (permissao === 'isPadrao') {
// //     fieldsToClearWhenIsPadrao.forEach((field) => {
// //       setValue(field, null);
// //       handleSetFilter('', field);
// //     });

// //     const payload = {
// //       estado: currentEstablishment.endereco.municipio.estado.nome,
// //       municipio: currentEstablishment.endereco.municipio.nome,
// //       estabelecimentoId: currentEstablishment.id,
// //       size: watch().size,
// //       page: watch().page,
// //     };
// //   }

// //   if (permissao === 'isMunicipal') {
// //     fieldsToClearWhenIsMunicipal.forEach((field) => {
// //       setValue(field, '');
// //       handleSetFilter('', field);
// //     });

// //     setEstabelecimentosSelected([]);

// //     const payload = {
// //       estado: currentEstablishment.endereco.municipio.estado.nome,
// //       municipio: currentEstablishment.endereco.municipio.nome,
// //       size: watch().size,
// //       page: watch().page,
// //     };
// //   }

// //   if (permissao === 'isEstadual') {
// //     fieldsToClearWhenEstadual.forEach((field) => {
// //       setValue(field, '');
// //       handleSetFilter('', field);
// //     });

// //     setEstabelecimentosSelected([]);
// //     setMunicipiosSelected([]);

// //     const payload = {
// //       estado: currentEstablishment.endereco.municipio.estado.nome,
// //       size: watch().size,
// //       page: watch().page,
// //     };
// //   }

// //   if (permissao === 'isSuperAdmin') {
// //     fieldsToClearWhenSuperAdmin.forEach((field) => {
// //       setValue(field, '');
// //       handleSetFilter('', field);
// //     });

// //     setEstadosSelected([]);
// //     setEstabelecimentosSelected([]);
// //     setMunicipiosSelected([]);

// //     const payload = {
// //       size: watch().size,
// //       page: watch().page,
// //     };
// //   }
// // };

import { useContext } from 'react';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';
import { getUserToken } from '../../../../../lib/auth';
import useClearReports from '../../../../../utils/hooks/useClearReports';

const useClearValues = ({
  permissao,
  setValue,
  handleSetFilter,
  watch,
  loadReportsTrackingHpv,
  setEstadosSelected,
  setMunicipiosSelected,
  setEstabelecimentosSelected,
  showTableData,
  setShowTableData,
  setTableData,
  setErrorsFields,
  setListFilterToShow,
  setArrayEquipes,
  initialStateListFilter,
  setListFilterShow,
  setAcsSelected,
  setSelectedFaixa,
  setSelectedPrioridade,
  setSelectedMeio,
  setSelectedRealizacaoTeste,
}) => {
  const { clearReport } = useClearReports({ setListFilterShow });
  const userToken = getUserToken();

  const {
    estabelecimentoId: estabelecimentoIdUser,
    estabelecimento_saude: {
      nome_fantasia: estabelecimentoNameUser,
      endereco: {
        municipio: {
          id: municipioUserId,
          nome: municipioUserName,
          estadoSigla: estadoUserSigla,
        },
      },
    },
  } = userToken;

  const objStatesToClear = {
    setSelectedFaixa,
    setSelectedPrioridade,
    setSelectedMeio,
    setSelectedRealizacaoTeste,
    setArrayEquipes,
    setAcsSelected,
  };

  const fieldNamesToClear = initialStateListFilter.map((fieldObj) => {
    return fieldObj.inputName;
  });

  const handleSetFilterToShow = (newValue: any, inputName: string) => {
    setListFilterToShow((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const isSuperAdmin = userToken.permissao_atual?.nome === 'SuperAdmin';
  const isEstadual = userToken.permissao_atual?.nome === 'Admin Estadual';
  const isMunicipal = userToken.permissao_atual?.nome === 'Admin Municipal';
  const isAdminLaboratorio =
    userToken.permissao_atual?.nome === 'Admin Estabelecimento';
  const isPadraoUnidadeSaude =
    userToken.permissao_atual?.contexto === 'UNIDADE DE SAÚDE';

  const clearValues = () => {
    setErrorsFields([]);
    setTableData([]);

    const clearWhenSuperAdmin = () => {
      setShowTableData(false);

      clearReport({
        objStates: {
          ...objStatesToClear,
          setMunicipiosSelected,
          setEstabelecimentosSelected,
          setEstadosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear,
        handleSetFilter,
        handleSetFilterToShow,
        clearAllDisplayShowed: true,
      });
    };

    const clearWhenEstadual = () => {
      setShowTableData(false);
      clearReport({
        objStates: {
          ...objStatesToClear,
          setMunicipiosSelected,
          setEstabelecimentosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear.slice(1),
        handleSetFilter,
        handleSetFilterToShow,
        clearAllDisplayShowed: true,
      });
    };

    const clearWhenAdminLaboratorio = () => {
      // delete objStatesToClear.setSelectedOptionsLaboratorio;
      setShowTableData(false);
      clearReport({
        objStates: {
          ...objStatesToClear,
          setMunicipiosSelected,
          setEstabelecimentosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear
          .slice(1)
          .filter((field) => field !== 'estabelecimentoLaudoId'),
        handleSetFilter,
        handleSetFilterToShow,
        clearAllDisplayShowed: true,
      });

      handleSetFilter(
        `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude?.nome_fantasia}`,
        'estabelecimentoLaudoId'
      );
    };

    const clearWhenMunicipal = () => {
      clearReport({
        objStates: {
          ...objStatesToClear,
          setEstabelecimentosSelected,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear.slice(2),
        handleSetFilter,
        handleSetFilterToShow,
      });

      const payload = {
        estados: estadoUserSigla,
        municipios: municipioUserId,
        page: 1,
        size: 25,
      };

      loadReportsTrackingHpv({ payload });
    };

    const clearWhenPadraoUnidadeSaude = () => {
      clearReport({
        objStates: {
          ...objStatesToClear,
        },
        fnSetValue: setValue,
        valuesFormToClear: fieldNamesToClear.slice(3),
        handleSetFilter,
        handleSetFilterToShow,
      });

      const payload = {
        estados: estadoUserSigla,
        municipios: municipioUserId,
        estabelecimentoSaudeId: estabelecimentoIdUser,
        page: 1,
        size: 25,
      };

      loadReportsTrackingHpv({ payload });
    };

    if (isSuperAdmin) {
      clearWhenSuperAdmin();
    }

    if (isEstadual) {
      clearWhenEstadual();
    }

    if (isAdminLaboratorio) {
      clearWhenAdminLaboratorio();
    }

    if (isPadraoUnidadeSaude) {
      clearWhenPadraoUnidadeSaude();
    }

    if (isMunicipal) {
      clearWhenMunicipal();
    }
  };

  return { clearValues };
};

export default useClearValues;
