import { Button } from '@mui/material';
import { StyledContainerCookies } from './styles';
import { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
//@ts-ignore
import policyPrivacy from './Documents/Politica-de-Privacidade__JORDANA_jun2023_.pdf';
//@ts-ignore
import termsUse from './Documents/Termo_Uso_JORDANA_jun2023.pdf';
import { getUserToken } from '../../lib/auth';
import api from '../../api';
import { toast } from 'react-toastify';

const AcceptCookies = () => {
  const [displayMessage, setDisplayMessage] = useState(false);

  const auth = getUserToken();

  const handleOpenPDF = (file: string) => {
    fetch(file)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Erro ao abrir o PDF:', error);
      });
  };

  async function sendAcceptTerms() {
    try {
      const term = await api.post('/terms/accept/' + auth?.id, {
        has_accepted: true,
      });

      if (term?.status === 201) {
        window.location.reload();
      } else {
        setDisplayMessage(false);
      }
    } catch {
      toast.error('Não foi possível aceitar os termos de uso');
      setDisplayMessage(false);
    }
  }

  async function verifyTermsOfUse() {
    if (!auth) return;
    try {
      const terms = await api.get('/terms/' + auth?.id);
      if (!terms?.data?.has_accepted) {
        setDisplayMessage(true);
      }
    } catch {
      console.log('Get Terms Error');
    }
  }

  useEffect(() => {
    verifyTermsOfUse();
  }, [window]);

  return (
    <>
      {displayMessage && (
        <Dialog
          open={true}
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
          }}
        >
          <StyledContainerCookies>
            <div>
              <span>
                Nós usamos cookies para melhorar sua experiência de navegação na
                Plataforma. Ao utilizar a plataforma Jordana você concorda com a{' '}
                <button
                  className="links_pdf"
                  onClick={() => handleOpenPDF(policyPrivacy)}
                >
                  política de privacidade
                </button>
                e
                <button
                  className="links_pdf"
                  onClick={() => handleOpenPDF(termsUse)}
                >
                  termos de uso
                </button>
                .
              </span>
            </div>

            <Button variant="contained" onClick={sendAcceptTerms}>
              ACEITAR
            </Button>
          </StyledContainerCookies>
        </Dialog>
      )}
    </>
  );
};

export default AcceptCookies;
