import { TableCell, Typography } from '@mui/material';

const RowsAddressData = ({ row }) => {
  return (
    <>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.pessoa?.endereco?.cep}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.pessoa?.endereco?.logradouro}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.pessoa?.endereco?.numero}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.pessoa?.endereco?.complemento}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.pessoa?.endereco?.bairro}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.pessoa?.endereco?.zona}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.pessoa?.endereco?.municipio?.nome}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.pessoa?.endereco?.municipio?.estadoSigla || row?.localizar?.estado_sigla}
        </Typography>
      </TableCell>
      {/* <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.pessoa?.estabelecimento_saude?.nome_fantasia}
        </Typography>
      </TableCell> */}

      {/* <TableCell align="left" sx={{ width: '162px' }}>
        <Typography color="#000001de" variant="body2" fontSize="14px">
          {row?.pessoa?.vinculo?.profissionalEquipe?.equipe?.ine}
        </Typography>
      </TableCell> */}
    </>
  );
};

export default RowsAddressData;
