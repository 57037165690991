import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import moment from 'moment';
import api from '../../../../../api';

import { getUserToken } from '../../../../../lib/auth';
import { getCidades } from '../../../../../lib/getCidadesEstabelecimentos';

import { StyledAsteriskTextField } from '../../../../../components/StyledAsteriskTextField/styles';
import MultipleFilters from '../../../../../components/MultipleFilters';
import useStatesFields from '../../../../../components/MultipleFilters/hooks/useStatesFields';

interface Period {
  periodo_inicio?: string;
  periodo_fim?: string;
}

const PERIOD_OPTIONS = [
  { label: 'Todos', value: 'all' },
  { label: 'Últimos 12 meses', value: 'twelve_months' },
  { label: 'Quadrimestral', value: 'four_months' },
  { label: 'Semestral', value: 'six_months' },
  { label: 'Anual', value: 'yearly' },
];

const QUADRIMESTER_OPTIONS = [
  '1º Quadrimestre',
  '2º Quadrimestre',
  '3º Quadrimestre',
];
const SEMESTER_OPTIONS = ['1º Semestre', '2º Semestre'];
const YEAR_OPTIONS = ['2024', '2023', '2022'];

const RequiredLabel = ({ children }) => (
  <Box component="span">
    {children}
    <Box component="span" sx={{ color: 'error.main', ml: 0.5 }}>
      *
    </Box>
  </Box>
);

const FiltersVisaoGeral = ({
  control,
  setPayload,
  payload,
  getAllCharts,
  setListFilter,
  initialStateListFilter,
  setShowListFilter,
  listFilter,
  estabelecimentosSelected,
  estadosSelected,
  municipiosSelected,
  setEstabelecimentosSelected,
  setEstadosSelected,
  setMunicipiosSelected,
  valueInputPeriod,
  setValueInputPeriod,
  setPeriodToDisplay,
  setTotal,
  setExamResultsData,
  setAtypicalCells,
  setGlandularAdenoma,
  setSquamousCellAtypia,
  setTableData,
  setTotalTestsPerMonth,
  setTableTotal,
  setTotalTableItems,
  setNeedsRequiredFilters,
  period,
  setPeriod,
  valuePeriodoModal,
  setValuePeriodoModal,
}) => {
  const [openModalPeriodo, setOpenModalPeriodo] = useState(false);
  const [isFilterButtonEnabled, setIsFilterButtonEnabled] = useState(false);
  const [year, setYear] = useState('');
  // const [valuePeriodoModal, setValuePeriodoModal] = useState();
  const [valueInputQuadrimestreSemestre, setValueInputQuadrimestreSemestre] =
    useState('');

  const [firstLoadPage, setFirstLoadPage] = useState(true);

  const userToken = getUserToken();
  const isSuperAdmin = userToken.permissao_atual?.nome === 'SuperAdmin';
  const isPadrao = userToken.permissao_atual?.id === 1;
  const isMunicipal = userToken.permissao_atual?.id === 3;
  const isEstadual = userToken.permissao_atual?.id === 4;
  const isAdminLaboratorio = userToken.permissao_atual?.id === 6;

  const {
    acsSelected,
    arrayAcs,
    searchAcs,
    allAcsOptions,
    anchorElAcs,
    disableAcsInput,
    setAnchorElAcs,
    setArrayAcs,
    setAcsSelected,
    setAllAcsOptions,
    setDisableAcsInput,
    anchorElEstados,
    searchEstado,
    estadosOptions,
    allEstadosOptions,
    setAnchorElEstados,
    setSearchEstado,
    setEstadosOptions,
    setAllEstadosOptions,
    allMunicipiosOptions,
    searchMunicipio,
    anchorElMunicipios,
    optionsMunicipios,
    disableMunicipioInput,
    setAllMunicipiosOptions,
    setSearchMunicipio,
    setAnchorElMunicipios,
    setOptionsMunicipios,
    setDisabledMunicipioInput,
    optionsEstabelecimentos,
    allEstabelecimentosOptions,
    searchEstabelecimentos,
    anchorElEstabelecimentos,
    disableEstabelecimentoInput,
    setOptionsEstabelecimentos,
    setAllEstabelecimentosOptions,
    setSearchEstabelecimentos,
    setAnchorEstabelecimentos,
    setDisabledEstabelecimentoInput,
    allEquipesOptions,
    searchEquipes,
    anchorElEquipe,
    arrayEquipes,
    equipesSelected,
    disableEquipeInput,
    setAllEquipesOptions,
    setSearchEquipes,
    setAnchorElEquipe,
    setArrayEquipes,
    setEquipesSelected,
    setDisableEquipeInput,
    // setDisableInputEstado,
    disableInputEstado,
    errorsFields,
    setErrorsFields,
  } = useStatesFields();

  const isAllFieldsFilled = () => {
    if (!valuePeriodoModal) return false;

    switch (valuePeriodoModal.value) {
      case 'twelve_months':
      case 'all':
        return true;
      case 'four_months':
      case 'six_months':
        return !!year && !!valueInputQuadrimestreSemestre;
      case 'yearly':
        return !!year;
      default:
        return false;
    }
  };

  const getSearch = () => {
    getAllCharts({ cleared: false, payload: payload });
    setShowListFilter(listFilter);
    setPeriodToDisplay(valueInputPeriod);
    setNeedsRequiredFilters(false);
  };

  const verifyIfHasPeriodSelected = () => {
    if (!period.periodo_fim && !period.periodo_inicio) {
      setValuesAllPeriodSelected();
      setValuePeriodoModal({
        label: 'Todos',
        value: 'all',
      });
    }
  };

  const verifyIfHasEstabelecimentoSelected = () => {
    if (estabelecimentosSelected.length === 0) {
      const arrIds = optionsEstabelecimentos.map((estab) => estab.id);

      setEstabelecimentosSelected(arrIds);

      return arrIds;
    }
  };

  const handleSearch = () => {
    const selectedMoreThanOneEstado = estadosSelected.length > 1;
    const selectedAllEstados = estadosSelected.length >= estadosOptions.length;
    const emptyEstado = estadosSelected.length === 0;
    const emptyMunicipio = municipiosSelected.length === 0;

    if (isSuperAdmin) {
      if (emptyEstado) {
        setErrorsFields((prev) => [...prev, 'uf']);
      }

      if (
        emptyMunicipio &&
        (!selectedAllEstados || !selectedMoreThanOneEstado)
      ) {
        setErrorsFields((prev) => [...prev, 'municipio']);
      }

      if (
        !emptyEstado &&
        (!emptyMunicipio || selectedAllEstados || selectedMoreThanOneEstado)
      ) {
        setErrorsFields([]);
        verifyIfHasPeriodSelected();
        verifyIfHasEstabelecimentoSelected();

        getSearch();
      }
    }

    if (isEstadual) {
      if (emptyMunicipio) {
        setErrorsFields((prev) => [...prev, 'municipio']);
      } else {
        setErrorsFields([]);
        verifyIfHasPeriodSelected();
        verifyIfHasEstabelecimentoSelected();

        getSearch();
      }
    }
    if (isMunicipal) {
      if (estabelecimentosSelected.length === 0) {
        const arrIds = optionsEstabelecimentos
          .filter((estab) => estab.id !== 'null')
          .map((estab) => estab.id);

        setEstabelecimentosSelected(arrIds);
      }
      setErrorsFields([]);
      verifyIfHasPeriodSelected();
      verifyIfHasEstabelecimentoSelected();

      getSearch();
    }

    if (isAdminLaboratorio) {
      if (emptyMunicipio) {
        setErrorsFields((prev) => [...prev, 'municipio']);
      } else {
        setErrorsFields([]);
        verifyIfHasPeriodSelected();
        verifyIfHasEstabelecimentoSelected();

        getSearch();
      }
    }
  };

  const handleSelectPeriodModal = (newValue) => {
    setValuePeriodoModal(newValue);
    if (!newValue) {
      // resetPeriodValues();
      setIsFilterButtonEnabled(false);
      return;
    }

    switch (newValue.value) {
      case 'twelve_months':
        setValuesInLastTwelveMonths();
        setIsFilterButtonEnabled(true);
        break;
      case 'four_months':
        setValuesQuadrimestreSelected();
        break;
      case 'six_months':
        setValuesSemestreSelected();
        break;
      case 'yearly':
        setValuesAnualSelected();
        setIsFilterButtonEnabled(false);
        break;
      case 'all':
        setValuesAllPeriodSelected();
        setIsFilterButtonEnabled(true);
        break;
      default:
        setIsFilterButtonEnabled(false);
        break;
    }
  };

  const resetPeriodValues = () => {
    // setValuesAllPeriodSelected();
    setPeriod({});
    setYear('');
    setValueInputPeriod(null);
    setValuePeriodoModal(null);
    setValueInputQuadrimestreSemestre('');
  };

  const setValuesInLastTwelveMonths = () => {
    const today = moment().format('YYYY-MM-DD');
    const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');
    setValueInputPeriod('Últimos 12 meses');
    setOpenModalPeriodo(false);
    setPeriod({ periodo_fim: today, periodo_inicio: twelveMonths });
  };

  const setValuesQuadrimestreSelected = () => {
    setValueInputPeriod('Quadrimestral');
    setYear('');
    setValueInputQuadrimestreSemestre('');
    setPeriod({});
    setIsFilterButtonEnabled(false);
  };

  const setValuesSemestreSelected = () => {
    setValueInputPeriod('Semestral');
    setYear('');
    setValueInputQuadrimestreSemestre('');
    setPeriod({});
    setIsFilterButtonEnabled(false);
  };

  const setValuesAnualSelected = () => {
    setValueInputPeriod('Anual');
    setYear('');
    setPeriod({});
    setIsFilterButtonEnabled(false);
  };

  const setValuesAllPeriodSelected = () => {
    setValueInputPeriod('Todos');
    setPeriod({ periodo_inicio: '2022-01-01' });
  };

  const clearData = () => {
    setTotal('');
    setTotalTestsPerMonth([]);
    setExamResultsData({ negativo: [], alteracao: [], insatisfatorio: [] });
    setAtypicalCells({
      escamosa: { count_neoplasica: 0, count_lesao: 0 },
      glandular: { count_neoplasica: 0, count_lesao: 0 },
      indefinida: { count_neoplasica: 0, count_lesao: 0 },
    });
    setGlandularAdenoma([]);
    setSquamousCellAtypia([]);
    setTableData([]);
    setTableTotal({
      hpv: 0,
      citologia: 0,
      realizar: 0,
    });
    setTotalTableItems(0);
  };

  const handleClearFilter = () => {
    setNeedsRequiredFilters(true);
    resetPeriodValues();
    setPeriodToDisplay('');
    resetSelections();
    resetSearchFields();
    resetListFilter();

    const initialPayload = {
      params: {
        page: 1,
        size: 20,
        tipo: 'Reflexo',
      },
    };

    clearData();

    if (isMunicipal) {
      getAllCharts({
        cleared: true,
        payload: {
          params: {
            ...payload?.params,
            estados:
              userToken.estabelecimento_saude?.endereco.municipio.estadoSigla,
            municipios: userToken.estabelecimento_saude?.endereco.municipio.id,
            estabelecimentos: null,
          },
        },
      });
    }
  };

  const resetSelections = () => {
    if (!isPadrao) setEstabelecimentosSelected([]);
    if (!isMunicipal && !isPadrao) setMunicipiosSelected([]);
    if (!isMunicipal && !isPadrao && !isEstadual && !isAdminLaboratorio)
      setEstadosSelected([]);
  };

  const resetSearchFields = () => {
    setSearchEquipes('');
    setSearchEstabelecimentos('');
    setSearchEstado('');
    setSearchMunicipio('');
  };

  const resetListFilter = () => {
    if (!isMunicipal) {
      setShowListFilter([]);
    }
    if (isEstadual || isAdminLaboratorio) {
      const listFilter = [
        {
          inputName: 'estado',
          value: `Estados: ${userToken.estabelecimento_saude?.endereco.municipio.estado.sigla}`,
        },
        {
          inputName: 'municipio',
          value: ``,
        },
        { inputName: 'estabelecimento_saude', value: '' },
        { inputName: 'equipe', value: '' },
        {
          inputName: 'periodo_inicio',
          value: '',
        },
        { inputName: 'periodo_fim', value: '' },
      ];

      setListFilter(listFilter);
    }

    if (isMunicipal) {
      const listFilter = [
        {
          inputName: 'estado',
          value: `Estados: ${userToken.estabelecimento_saude?.endereco.municipio.estado.sigla}`,
        },
        {
          inputName: 'municipio',
          value: `Município: ${userToken.estabelecimento_saude?.endereco.municipio.nome}`,
        },
        { inputName: 'estabelecimento_saude', value: '' },
        { inputName: 'equipe', value: '' },
        { inputName: 'periodo_inicio', value: '' },
        { inputName: 'periodo_fim', value: '' },
      ];

      setListFilter(listFilter);
      setShowListFilter(listFilter);
    }
  };

  const handleChangeWhenYearSelected = (newValue) => {
    setYear(newValue);
    if (
      valueInputPeriod === 'Quadrimestral' ||
      valueInputPeriod === 'Semestral'
    ) {
      handleChangeSemestreOrQuadrimestre(
        valueInputQuadrimestreSemestre,
        newValue
      );
      setIsFilterButtonEnabled(!!valueInputQuadrimestreSemestre);
    } else if (valueInputPeriod === 'Anual') {
      setIsFilterButtonEnabled(true);
      setPeriod({
        periodo_inicio: `${newValue}-01-01`,
        periodo_fim: `${newValue}-12-31`,
      });

      handleSetFiltersInMultiplesInputs({
        value: valueInputPeriod,
        fieldName: 'periodo',
        arrayValues: [valueInputPeriod],
      });
    }
  };

  const handleChangeSemestreOrQuadrimestre = (
    newValue,
    selectedYear = year
  ) => {
    setValueInputQuadrimestreSemestre(newValue);
    if (!selectedYear || !newValue) {
      setIsFilterButtonEnabled(false);
      return;
    }
    const periods = {
      '1º Semestre': { inicio: '01-01', fim: '06-30' },
      '2º Semestre': { inicio: '07-01', fim: '12-31' },
      '1º Quadrimestre': { inicio: '01-01', fim: '04-30' },
      '2º Quadrimestre': { inicio: '05-01', fim: '08-31' },
      '3º Quadrimestre': { inicio: '09-01', fim: '12-31' },
    };

    const selectedPeriod = periods[newValue];
    if (selectedPeriod) {
      setPeriod({
        periodo_inicio: `${selectedYear}-${selectedPeriod.inicio}`,
        periodo_fim: `${selectedYear}-${selectedPeriod.fim}`,
      });

      handleSetFiltersInMultiplesInputs({
        value: valueInputPeriod,
        fieldName: 'periodo',
        arrayValues: [valueInputPeriod],
      });

      setIsFilterButtonEnabled(true);
    } else {
      console.error('Período inválido selecionado');
      setIsFilterButtonEnabled(false);
    }
  };

  useEffect(() => {
    if (period && period.periodo_inicio && period.periodo_fim) {
      handleSetFilter(
        moment(period.periodo_inicio, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        'periodo_inicio'
      );
      handleSetFilter(
        moment(period.periodo_fim, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        'periodo_fim'
      );
    } else {
      handleSetFilter('Período: Todos os períodos', 'periodo_inicio');
      handleSetFilter('', 'periodo_fim');
    }
  }, [period]);

  const handleCloseModal = () => {
    if (valueInputPeriod && year) {
      if (
        valueInputPeriod === 'Quadrimestral' ||
        valueInputPeriod === 'Semestral'
      ) {
        handleChangeSemestreOrQuadrimestre(valueInputQuadrimestreSemestre);
      } else if (valueInputPeriod === 'Anual') {
        setPeriod({
          periodo_inicio: `${year}-01-01`,
          periodo_fim: `${year}-12-31`,
        });
      }
    } else if (valueInputPeriod === 'Últimos 12 meses') {
      setValuesInLastTwelveMonths();
    } else if (valueInputPeriod === 'Todos') {
      setValuesAllPeriodSelected();
    } else {
      resetPeriodValues();
    }
    setOpenModalPeriodo(false);
  };

  const handleSetFilter = (newValue: any, inputName: string) => {
    setListFilter((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const formatPeriodDisplay = (
    inputPeriod,
    selectedYear,
    selectedPeriodValue
  ) => {
    if (!inputPeriod) return '';

    switch (inputPeriod) {
      case 'Quadrimestral':
        const quadNumber = selectedPeriodValue?.charAt(0) || '';
        return `Quadrimestral - ${selectedYear}.${quadNumber}`;

      case 'Semestral':
        const semNumber = selectedPeriodValue?.charAt(0) || '';
        return `Semestral - ${selectedYear}.${semNumber}`;

      case 'Anual':
        return `Anual - ${selectedYear}`;

      case 'Últimos 12 meses':
      case 'Todos':
        return inputPeriod;

      default:
        return inputPeriod;
    }
  };

  const handleSetFiltersInMultiplesInputs = ({
    value,
    fieldName,
    arrayValues,
  }) => {
    const convertedFieldNames = {
      estado: 'Estados',
      municipio: 'Municípios',
      estabelecimento_saude: 'Estabelecimentos',
      equipe: 'Equipes',
      periodo: 'Período',
    };

    if (fieldName === 'periodo') {
      const formattedPeriod = formatPeriodDisplay(
        value,
        year,
        valueInputQuadrimestreSemestre
      );
      handleSetFilter(
        `${convertedFieldNames[fieldName]}: ${formattedPeriod}`,
        fieldName
      );
      return;
    }

    if (arrayValues.length > 1) {
      handleSetFilter(`${convertedFieldNames[fieldName]}: Vários`, fieldName);
    } else {
      handleSetFilter(`${convertedFieldNames[fieldName]}: ${value}`, fieldName);
    }
  };

  useEffect(() => {
    if (valueInputPeriod) {
      handleSetFiltersInMultiplesInputs({
        value: valueInputPeriod,
        fieldName: 'periodo',
        arrayValues: [valueInputPeriod],
      });
    } else {
      handleSetFilter(null, 'periodo');
    }
  }, [valueInputPeriod, year, valueInputQuadrimestreSemestre]);

  useEffect(() => {
    if (estabelecimentosSelected.length === 0) {
      handleSetFilter(
        'Estabelecimento de Saúde: Vários',
        'estabelecimento_saude'
      );
    }
  }, [estabelecimentosSelected]);

  // useEffect(() => {
  //   if (estadosSelected.length > 0) {
  //     handleSetFiltersInMultiplesInputs({
  //       value: estadosSelected[0] ?? '',
  //       fieldName: 'estado',
  //       arrayValues: estadosSelected,
  //     });
  //   } else {
  //     handleSetFilter(null, 'estado');
  //   }
  // }, [estadosSelected]);

  // useEffect(() => {
  //   if (municipiosSelected.length > 0) {
  //     const municipioNome = optionsMunicipios?.find(
  //       (municipio) => municipio.id === municipiosSelected[0]
  //     )?.nome;
  //     handleSetFiltersInMultiplesInputs({
  //       value: municipioNome ?? '',
  //       fieldName: 'municipio',
  //       arrayValues: municipiosSelected,
  //     });
  //   } else {
  //     handleSetFilter(null, 'municipio');
  //   }
  // }, [municipiosSelected]);

  // useEffect(() => {
  //   const estabelecimentoNome =
  //     optionsEstabelecimentos?.find(
  //       (item) => estabelecimentosSelected[0] === item.id
  //     )?.nome ?? '';

  //   if (estabelecimentosSelected.length > 0) {
  //     handleSetFiltersInMultiplesInputs({
  //       value: estabelecimentoNome,
  //       fieldName: 'estabelecimento',
  //       arrayValues: estabelecimentosSelected,
  //     });
  //   } else {
  //     handleSetFilter(null, 'estabelecimento');
  //   }
  // }, [estabelecimentosSelected]);

  useEffect(() => {
    if (valueInputPeriod) {
      handleSetFiltersInMultiplesInputs({
        value: valueInputPeriod,
        fieldName: 'periodo',
        arrayValues: [valueInputPeriod],
      });
    } else {
      handleSetFilter(null, 'periodo');
    }
  }, [valueInputPeriod]);

  useEffect(() => {
    if (
      year &&
      (valueInputPeriod === 'Quadrimestral' || valueInputPeriod === 'Semestral')
    ) {
      handleChangeSemestreOrQuadrimestre(valueInputQuadrimestreSemestre, year);
    }
  }, [year, valueInputPeriod, valueInputQuadrimestreSemestre]);

  useEffect(() => {
    updatePayload();
  }, [estadosSelected, municipiosSelected, estabelecimentosSelected, period]);

  useEffect(() => {
    if (period.periodo_inicio && period.periodo_fim) {
      updatePayload();
    }
  }, [period]);

  const updatePayload = () => {
    setPayload({
      params: {
        estados: getEstadosParam(),
        municipios: getMunicipiosParam(),
        estabelecimentos: getEstabelecimentosParam(),
        equipe:
          equipesSelected.length > 0 ? equipesSelected.join(', ') : undefined,
        periodo_inicio: period?.periodo_inicio,
        periodo_fim: period?.periodo_fim,
        tipo: 'Reflexo',
        page: payload.params.page || 1,
        size: payload.params.size || 25,
      },
    });
  };

  const getEstadosParam = () => {
    if (isPadrao || isMunicipal || isEstadual) return 'PE';
    return estadosSelected.length > 0
      ? JSON.stringify(estadosSelected)
      : undefined;
  };

  const getMunicipiosParam = () => {
    if (isPadrao || isMunicipal) return userToken.endereco?.municipio_id;
    return municipiosSelected.length > 0
      ? municipiosSelected.join(', ')
      : undefined;
  };

  const getEstabelecimentosParam = () => {
    if (isPadrao) return userToken.estabelecimentoId;
    return estabelecimentosSelected.length > 0
      ? estabelecimentosSelected.join(', ')
      : undefined;
  };

  useEffect(() => {
    const loadEstados = async () => {
      try {
        const response = await api.get('/estados/citologia');
        const estados = response.data;
        setEstadosOptions(estados);
        setAllEstadosOptions(estados);
      } catch (error) {
        console.error('Erro ao carregar estados:', error);
        // setEstadosLoadError(true);
      }
    };

    loadEstados();
  }, []);

  useEffect(() => {
    const getOptionsCidades = async () => {
      if (estadosSelected.length === 1) {
        const optionsCidades = await getCidades(estadosSelected[0]);
        setOptionsMunicipios(optionsCidades);
        setAllMunicipiosOptions(optionsCidades);
      } else if (estadosSelected.length === 0) {
        // setOptionsMunicipios([]);
      }
    };

    getOptionsCidades();
  }, []);

  useEffect(() => {
    const getOptionsEstabelecimentos = async () => {
      if (municipiosSelected.length === 1) {
        try {
          const response = await api.get(
            '/dashboard/exames/citologia/panel/estabelecimentos',
            {
              params: {
                estados:
                  estadosSelected.length > 0
                    ? JSON.stringify(estadosSelected)
                    : undefined,
                municipios: municipiosSelected.join(', '),
              },
            }
          );

          const semVinculacao = {
            id: 'null',
            municipio: '',
            nome: 'SEM VINCULAÇÃO',
            quantidade: '',
            label: '',
          };

          setOptionsEstabelecimentos([semVinculacao, ...response.data]);
          setAllEstabelecimentosOptions([semVinculacao, ...response.data]);

          if (firstLoadPage) {
            setFirstLoadPage(false);
            const estabelecimentosIds = optionsEstabelecimentos.data.map(
              (estab) => estab.id
            );

            setEstabelecimentosSelected([...estabelecimentosIds, 'null']);
          }
        } catch (error) {
          console.error('Error fetching estabelecimentos:', error);
        }
      } else if (municipiosSelected.length === 0 && !isPadrao) {
        setOptionsEstabelecimentos([]);
      }
    };

    getOptionsEstabelecimentos();
  }, [municipiosSelected]);

  return (
    <>
      <Dialog
        open={openModalPeriodo}
        onClose={() => setOpenModalPeriodo(false)}
      >
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            Tipo de Período
          </Typography>
          <DialogContent sx={{ padding: '0', marginTop: '30px' }}>
            <Paper
              variant="outlined"
              sx={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                Selecione o Período para Visualização dos Dados
              </Typography>
              <Autocomplete
                placeholder="Tipo de Período"
                value={valuePeriodoModal}
                onChange={(_, newValue) => handleSelectPeriodModal(newValue)}
                options={PERIOD_OPTIONS}
                getOptionLabel={(option) => option.label ?? ''}
                renderInput={(params) => (
                  <StyledAsteriskTextField
                    {...params}
                    fullWidth
                    label="Tipo de Período"
                    variant="outlined"
                  />
                )}
              />
              {valueInputPeriod &&
                valueInputPeriod !== 'Últimos 12 meses' &&
                valueInputPeriod !== 'Todos' && (
                  <Autocomplete
                    options={YEAR_OPTIONS}
                    onChange={(_, newValue) => {
                      handleChangeWhenYearSelected(newValue);
                    }}
                    value={year}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={<RequiredLabel>Selecione o Ano</RequiredLabel>}
                      />
                    )}
                  />
                )}
              {(valueInputPeriod === 'Quadrimestral' ||
                valueInputPeriod === 'Semestral') && (
                <Autocomplete
                  options={
                    valueInputPeriod === 'Quadrimestral'
                      ? QUADRIMESTER_OPTIONS
                      : SEMESTER_OPTIONS
                  }
                  value={valueInputQuadrimestreSemestre}
                  onChange={(_, newValue) =>
                    handleChangeSemestreOrQuadrimestre(newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <RequiredLabel>
                          {valueInputPeriod === 'Quadrimestral'
                            ? 'Selecione o Quadrimestre'
                            : 'Selecione o Semestre'}
                        </RequiredLabel>
                      }
                    />
                  )}
                />
              )}
            </Paper>
          </DialogContent>
        </DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={handleCloseModal}
            startIcon={<ClearIcon />}
          >
            CANCELAR
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenModalPeriodo(false)}
            endIcon={<CheckIcon />}
            disabled={!isAllFieldsFilled()}
          >
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={2}>
        <MultipleFilters
          requiredMunicipio={estadosSelected.length > 1 ? false : true}
          setFirstLoadPage={setFirstLoadPage}
          disableInputEstado={disableInputEstado}
          fieldNameEstabelecimento="estabelecimento"
          handleSetFilter={handleSetFilter}
          mdEstado={2.6}
          mdMunicipio={2.6}
          mdEstabelecimento={2.6}
          mdEquipe={2}
          acsSelected={acsSelected}
          arrayAcs={arrayAcs}
          searchAcs={searchAcs}
          allAcsOptions={allAcsOptions}
          setAllAcsOptions={setAllAcsOptions}
          anchorElAcs={anchorElAcs}
          disableAcsInput={disableAcsInput}
          setAnchorElAcs={setAnchorElAcs}
          setArrayAcs={setArrayAcs}
          setSearchAcs={setArrayAcs}
          setAcsSelected={setAcsSelected}
          anchorElEstados={anchorElEstados}
          searchEstado={searchEstado}
          estadosOptions={estadosOptions}
          allEstadosOptions={allEstadosOptions}
          estadosSelected={estadosSelected}
          setAnchorElEstados={setAnchorElEstados}
          setSearchEstado={setSearchEstado}
          setEstadosOptions={setEstadosOptions}
          setAllEstadosOptions={setAllEstadosOptions}
          setEstadosSelected={setEstadosSelected}
          allMunicipiosOptions={allMunicipiosOptions}
          searchMunicipio={searchMunicipio}
          anchorElMunicipios={anchorElMunicipios}
          municipiosSelected={municipiosSelected}
          optionsMunicipios={optionsMunicipios}
          disableMunicipioInput={disableMunicipioInput}
          setAllMunicipiosOptions={setAllMunicipiosOptions}
          setSearchMunicipio={setSearchMunicipio}
          setAnchorElMunicipios={setAnchorElMunicipios}
          setMunicipiosSelected={setMunicipiosSelected}
          setOptionsMunicipios={setOptionsMunicipios}
          optionsEstabelecimentos={optionsEstabelecimentos}
          allEstabelecimentosOptions={allEstabelecimentosOptions}
          searchEstabelecimentos={searchEstabelecimentos}
          anchorElEstabelecimentos={anchorElEstabelecimentos}
          estabelecimentosSelected={estabelecimentosSelected}
          disableEstabelecimentoInput={disableEstabelecimentoInput}
          setOptionsEstabelecimentos={setOptionsEstabelecimentos}
          setAllEstabelecimentosOptions={setAllEstabelecimentosOptions}
          setSearchEstabelecimentos={setSearchEstabelecimentos}
          setAnchorEstabelecimentos={setAnchorEstabelecimentos}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          allEquipesOptions={allEquipesOptions}
          searchEquipes={searchEquipes}
          anchorElEquipe={anchorElEquipe}
          arrayEquipes={arrayEquipes}
          equipesSelected={equipesSelected}
          disableEquipeInput={disableEquipeInput}
          setAllEquipesOptions={setAllEquipesOptions}
          setSearchEquipes={setSearchEquipes}
          setAnchorElEquipe={setAnchorElEquipe}
          setArrayEquipes={setArrayEquipes}
          setEquipesSelected={setEquipesSelected}
          setDisabledMunicipioInput={setDisabledMunicipioInput}
          setDisabledEstabelecimentoInput={setDisabledEstabelecimentoInput}
          setDisableEquipeInput={setDisableEquipeInput}
          setDisableAcsInput={setDisableAcsInput}
          errorsFields={errorsFields}
          setErrorsFields={setErrorsFields}
        />
        <Grid item xs={12} md={2}>
          <Controller
            name="periodo"
            control={control}
            render={({ field: { onChange, value } }) => (
              <StyledAsteriskTextField
                data-testid="periodoInput"
                fullWidth
                size="small"
                label="Período"
                value={valueInputPeriod || ''}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ padding: 0 }}
                      onClick={() => setOpenModalPeriodo(true)}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={1}>
          <Button
            variant="contained"
            data-testid="submitFilterPanelCitologia"
            onClick={() => {
              handleSearch();
            }}
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={12} lg={1}>
          <Button
            data-testid="clearFilterPanelCitologia"
            variant="outlined"
            color="error"
            onClick={handleClearFilter}
          >
            Limpar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FiltersVisaoGeral;
