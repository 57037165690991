import { Collapse, Paper } from '@mui/material';
import ConvocationItens from './ConvocationItens';
import ScheduleItens from './ScheduleItens';
import HpvItens from './HpvItens';

const DetailConcluded = () => {
  const convocations = [
    {
      receivedAt: '07/01/2025 às 09h00',
      numberCorret: {
        is: true,
        responsedAt: '2025-01-07T14:00:00Z',
      },
    },
  ];

  const schedules = [
    {
      wantSchedule: {
        is: true,
        when: '2025-01-07T14:00:00Z',
      },
      confirmed: true,
    },
  ];

  const hpv = [
    {
      present: true,
      receivedResult: '2025-01-07T14:00:00Z',
    },
  ];
  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
        borderColor: 'rgba(0, 0, 0, 0.23);',
        borderRadius: '10px',
        padding: '32px',
        marginBottom: '-12.5px',
      }}
    >
      <ConvocationItens convocations={convocations} />
      <ScheduleItens schedules={schedules} />
      <HpvItens hpv={hpv} />
    </Paper>
  );
};

export default DetailConcluded;
