import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import { SideMenu } from '../../../components/SideMenu';
import ReturnButton from '../../../components/ReturnButton';

import FiltersReportsHpv from './FiltersReportsHpv';
import TableReportsHpv from '../../../components/TableReports/TableHpv/TableReportsHpv';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useStatesFields from '../../../components/MultipleFilters/hooks/useStatesFields';
import { LoadingContext } from '../../../context/LoadingContext';
import { Link, useLocation } from 'react-router-dom';
import {
  optionsMotivoNaoAtendida,
  optionsResultadoHPV,
  optionsTiposVirus,
} from '../../../utils/variables';
import useRequestFilter from './FiltersReportsHpv/hooks/useRequestFilter';
import { getUserToken } from '../../../lib/auth';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useSetPayload from './FiltersReportsHpv/hooks/useSetPayload';

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento_saude', value: '' },
  { inputName: 'equipe', value: '' },
  { inputName: 'idade_ini', value: '' },
  { inputName: 'idade_fin', value: '' },
  { inputName: 'realizou_atendimento', value: '' },
  { inputName: 'atendimento_status', value: '' },
  { inputName: 'natureza', value: '' },
  { inputName: 'motivo', value: '' },
  { inputName: 'inspecao_colo', value: '' },
  { inputName: 'responsavel_coleta', value: '' },
  { inputName: 'dt_coleta_inicio', value: '' },
  { inputName: 'dt_coleta_fim', value: '' },
  { inputName: 'resultado', value: '' },
  { inputName: 'tipo_virus', value: '' },
  { inputName: 'estabelecimentoLaudoId', value: '' },
  { inputName: 'dt_resultado_inicio', value: '' },
  { inputName: 'dt_resultado_fim', value: '' },
  { inputName: 'search', value: '' },
  { inputName: 'acs', value: '' },
];

export const ReportsHpv = () => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      page: 1,
      size: 25,
    },
  });
  const [tableData, setTableData] = useState<any[]>([]);
  const [showTableData, setShowTableData] = useState(false);
  const [listFilter, setListFilter] = useState<any[]>(initialStateListFilter);
  const [listFilterShow, setListFilterShow] = useState<any[]>([]);
  const [period, setPeriod] = useState('period');
  const [periodResultado, setPeriodResultado] = useState('period');
  const [dtColetaIni, setDataColetaIni] = useState<any>(null);
  const [dtColetaFinal, setDataColetaFinal] = useState<any>(null);
  const [dtResultadoIni, setDataResultadoIni] = useState<any>(null);
  const [dtResultadoFinal, setDataResultadoFinal] = useState<any>(null);
  const location = useLocation();
  const origin = location?.state?.origin;
  const fromTable =
    origin === 'tables' || !!localStorage.getItem('requestFromTable');
  const userToken = getUserToken();
  const [selectedOptionsLaboratorio, setSelectedOptionsLaboratorio] = useState<
    any[]
  >([]);
  const [selectedLaboratorio, setSelectedLaboratorio] = useState<any>();
  const [
    selectedOptinsProfissionalResponsavel,
    setSelectedOptinsProfissionalResponsavel,
  ] = useState<any>([]);

  const [disabledLaboratorioResultado] = useState(false);

  // const isPadrao =
  //   permissaoId === 1 ||
  //   permissaoId === 9 ||
  //   permissaoId === 10 ||
  //   permissaoId === 7;

  // const isLaboratorio = userToken.permissao_atual?.contexto === 'LABORATÓRIO';

  // const isMunicipal = userToken.permissao_atual?.id === 3;
  const {
    estadosSelected,
    setEstadosSelected,
    municipiosSelected,
    setMunicipiosSelected,
    setOptionsEstabelecimentos,
    // optionsEstabelecimentos,
    estabelecimentosSelected,
    setEstabelecimentosSelected,
    equipesSelected,
    // setDisableInputEstado,
    disableInputEstado,
    // setDisabledMunicipioInput,
    // setDisabledEstabelecimentoInput,
    // optionsMunicipios,
    setOptionsMunicipios,
    setEstadosOptions,
    // setAllEstadosOptions,
    setOptionsLaboratorios,
    // estadosOptions,
  } = useStatesFields();

  // const handleSetListFilterShowFromCharts = (
  //   inputName: string,
  //   newValue: any
  // ) => {
  //   setListFilterShow((prev) => {
  //     return [...prev, { inputName, value: newValue }];
  //   });
  // };

  const handleSetFilter = (newValue: any, inputName: string) => {
    setListFilter((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue ?? '' } : item
      )
    );
  };

  const { setLoading } = useContext(LoadingContext);

  const { loadReportsHpv } = useRequestFilter({
    setTableData,
  });

  // const { getMunicipioDetails, getEstabelecimentoDetails, getAcsDetails } =
  //   useGetDetailsFilters();

  const { setPayload } = useSetPayload({
    watch,
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    dtColetaFinal,
    dtColetaIni,
    dtResultadoIni,
    dtResultadoFinal,
    selectedLaboratorio,
    selectedOptinsProfissionalResponsavel,
    selectedOptionsLaboratorio,
  });

  const {
    estabelecimentoId: estabelecimentoIdUser,
    estabelecimento_saude: {
      nome_fantasia: estabelecimentoNameUser,
      endereco: {
        municipio: {
          id: municipioUserId,
          nome: municipioUserName,
          estadoSigla: estadoUserSigla,
        },
      },
    },
    // endereco: {
    //   municipio_id: municipioUserId,
    //   municipio_nome: municipioUserName,
    // },
  } = userToken;

  const setDefaultEstado = ({ sigla }) => {
    setEstadosOptions([
      {
        sigla: sigla,
      },
    ]);
    handleSetFilter(`Estado: ${sigla}`, 'estado');

    setEstadosSelected([sigla]);
  };

  const setDefaultMunicipio = ({ municipioId, municipioNome }) => {
    setOptionsMunicipios({
      id: municipioId,
      nome: municipioNome,
    });
    handleSetFilter(`Municipio: ${municipioNome}`, 'municipio');
    setMunicipiosSelected([municipioId]);
  };

  const setDefaultEstabelecimento = ({
    estabelecimentoId,
    estabelecimentoNome,
  }) => {
    setOptionsEstabelecimentos({
      id: estabelecimentoIdUser,
      municipio: municipioUserName,
      municipio_id: municipioUserId,
      nome: estabelecimentoNameUser,
      label: estabelecimentoNameUser,
    });

    setEstabelecimentosSelected([estabelecimentoIdUser]);
  };

  const setDefaultLaboratorio = ({ laboratorioId, laboratorioName }) => {
    setOptionsLaboratorios([
      {
        label: laboratorioName,
        value: laboratorioId,
      },
    ]);
    setSelectedOptionsLaboratorio([
      {
        label: laboratorioName,
        value: laboratorioId,
      },
    ]);

    handleSetFilter(
      `Laboratório Responsável pelo Laudo: ${laboratorioName}`,
      'estabelecimentoLaudoId'
    );
  };

  const isSuperAdmin = userToken.permissao_atual?.nome === 'SuperAdmin';
  const isEstadual = userToken.permissao_atual?.nome === 'Admin Estadual';
  const isMunicipal = userToken.permissao_atual?.nome === 'Admin Municipal';
  const isAdminLaboratorio =
    userToken.permissao_atual?.nome === 'Admin Estabelecimento';
  const isPadraoEstabelecimento =
    userToken.permissao_atual?.contexto === 'UNIDADE DE SAÚDE';

  const actionsWhenUserIsEstadual = () => {
    setDefaultEstado({
      sigla: estadoUserSigla,
    });
  };

  const actionsWhenUserIsMunicipal = () => {
    setDefaultEstado({
      sigla: estadoUserSigla,
    });
    setDefaultMunicipio({
      municipioId: String(municipioUserId),
      municipioNome: municipioUserName,
    });

    setListFilterShow((prev) => [
      ...prev,
      { inputName: 'estados', value: `Estados: ${estadoUserSigla}` },
      { inputName: 'municipio', value: `Município: ${municipioUserName}` },
    ]);

    setShowTableData(true);
  };

  const actionsWhenUserIsAdminLaboratorio = () => {
    setDefaultEstado({
      sigla: userToken.estabelecimento_saude?.endereco.municipio.estado.sigla,
    });

    setDefaultLaboratorio({
      laboratorioId: userToken.estabelecimentoId,
      laboratorioName: userToken.estabelecimento_saude?.nome_fantasia,
    });
  };

  const actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento = () => {
    actionsWhenUserIsMunicipal();

    setDefaultEstabelecimento({
      estabelecimentoId: estabelecimentoIdUser,
      estabelecimentoNome: estabelecimentoNameUser,
    });

    setListFilterShow((prev) => [
      { inputName: 'estados', value: `Estados: ${estadoUserSigla}` },
      { inputName: 'municipio', value: `Município: ${municipioUserName}` },
      {
        inputName: 'estabelecimento_saude',
        value: `Estabelecimento de Saúde: ${estabelecimentoNameUser}`,
      },
    ]);
  };

  useEffect(() => {
    if (isSuperAdmin) {
      return;
    }

    if (isEstadual) {
      actionsWhenUserIsEstadual();
    }

    if (isMunicipal) {
      actionsWhenUserIsMunicipal();
      if (!fromTable) {
        loadReportsHpv({
          estados: estadoUserSigla,
          municipios: municipioUserId,
          page: 1,
          size: 25,
        });
      }
    }

    if (isAdminLaboratorio) {
      actionsWhenUserIsAdminLaboratorio();
    }

    if (isPadraoEstabelecimento) {
      actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento();
      if (!fromTable) {
        loadReportsHpv({
          estados: estadoUserSigla,
          municipios: municipioUserId,
          estabelecimentoSaudeId: estabelecimentoIdUser,
          page: 1,
          size: 25,
        });
      }
    }
  }, []);

  const fieldConnectedChart = {
    result: { fieldName: 'resultado', options: optionsResultadoHPV },
    totalValue: {
      fieldName: 'realizou_atendimento',
      options: [
        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },
      ],
    },
    typeVirus: {
      fieldName: 'tipo_virus',
      options: optionsTiposVirus,
    },
    reasonNotProcedure: {
      fieldName: 'atendimento_status',
      options: optionsMotivoNaoAtendida,
    },
  };
  const payloadFromPanelCharts = JSON.parse(
    String(localStorage.getItem('payload'))
  );
  const chartNameSavedInStorage =
    localStorage.getItem('chartName') || location?.state?.table;

  const chartClickedIsTypeVirus = chartNameSavedInStorage === 'typeVirus';
  const chartClickedIsReasonNotProcedure =
    chartNameSavedInStorage === 'reasonNotProcedure';
  const chartClickedIsTotal = chartNameSavedInStorage === 'totalValue';

  const setFieldThatShowFieldClickedInTable = ({ fieldFatherName, value }) => {
    setValue(fieldFatherName, value);
  };

  const fieldFather = {
    typeVirus: {
      fieldFatherName: 'resultado',
      option: {
        value: 'POSITIVO',
        label: 'Detectável',
      },
    },
    reasonNotProcedure: {
      fieldFatherName: 'realizou_atendimento',
      option: 'Não',
    },
  };

  const setDisplayFilters = ({ optionSelected, field }) => {
    handleSetFilter(optionSelected, field.fieldName);
    setListFilterShow((prev) => {
      return [
        ...prev,
        { inputName: field.fieldName, value: optionSelected.label },
      ];
    });
  };

  const setValueInInput = () => {
    let value;
    const field = fieldConnectedChart[chartNameSavedInStorage];

    if (chartClickedIsTotal) {
      setValue('realizou_atendimento', 'Sim');
      setDisplayFilters({
        field: 'realizou_atendimento',
        optionSelected: { label: 'Sim' },
      });
    } else if (chartClickedIsReasonNotProcedure) {
      const value = localStorage.getItem('itemClicked');
      setValue('atendimento_status', value);
      setDisplayFilters({
        field: 'realizou_atendimento',
        optionSelected: optionsMotivoNaoAtendida.find(
          (opt) => opt.value === value
        ),
      });
    } else {
      const value = localStorage.getItem('itemClicked');
      const optionSelected = field.options.find((opt) => opt.value === value);
      setValue(field.fieldName, optionSelected);
      setDisplayFilters({
        field: field.fieldName,
        optionSelected: optionSelected,
      });
    }
  };

  // const nameMunicipioFromGestaoChart = optionsMunicipios?.find(
  //   (mncp) => mncp.id === payloadFromPanelCharts.params.municipios[0]
  // );

  // console.log(nameMunicipioFromGestaoChart);

  const getDataByChartClicked = async () => {
    const isGestao =
      isSuperAdmin || isAdminLaboratorio || isEstadual || isMunicipal;
    if (isGestao) {
      // handleSetListFilterShowFromCharts('estado');
      setEstadosSelected([payloadFromPanelCharts.params.estados]);
      setMunicipiosSelected([payloadFromPanelCharts.params.municipios]);
      setEstabelecimentosSelected(
        payloadFromPanelCharts.params.estabelecimentos
          ? [Number(payloadFromPanelCharts.params.estabelecimentos)]
          : []
      );
    }
    if (chartClickedIsTypeVirus || chartClickedIsReasonNotProcedure) {
      setFieldThatShowFieldClickedInTable({
        fieldFatherName: fieldFather[chartNameSavedInStorage].fieldFatherName,
        value: fieldFather[chartNameSavedInStorage].option,
      });
    }

    setShowTableData(true);

    setValueInInput();

    setPayload().then((payload: any) => {
      loadReportsHpv(
        isGestao
          ? {
              ...payload,
              estados: payloadFromPanelCharts.params.estados,
              municipios: payloadFromPanelCharts.params.municipios,
              estabelecimentoSaudeId:
                payloadFromPanelCharts.params.estabelecimentos ?? null,
            }
          : payload
      );
    });
  };

  useEffect(() => {
    if (fromTable) {
      getDataByChartClicked();
    }
  }, [fromTable]);

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton backPage={fromTable ? '/paineis/teste-hpv' : '/home'} />
          <Typography
            variant="h4"
            fontWeight="bold"
            style={{ marginBottom: '30px' }}
          >
            Relatório Geral do Teste de HPV
          </Typography>

          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{ marginBottom: '16px', marginTop: '16px' }}
            sx={{ marginBottom: '24px' }}
          >
            <Link
              style={{
                textDecoration: 'none',
                color: '#646464',
              }}
              to="/home"
            >
              Home
            </Link>

            {fromTable ? (
              <Link
                style={{
                  textDecoration: 'none',
                  color: '#646464',
                }}
                to={'/paineis/teste-hpv'}
              >
                {'Painel do Teste de HPV'}
              </Link>
            ) : (
              <Typography color="text.primary">{'Relatórios'}</Typography>
            )}

            <Typography key="3" color="text.primary">
              Relatório Geral do Teste de HPV{' '}
            </Typography>
          </Breadcrumbs>

          <Divider />
        </Box>

        <Box mt={5}>
          <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '30px', fontSize: '24px' }}
            >
              Filtros
            </Typography>
            <Divider />
            <p
              style={{
                fontSize: '12px',
                textAlign: 'right',
                color: '#d32f2f',
                margin: '0',
                marginTop: '20px',
                marginBottom: '-20px',
              }}
            >
              <i>* Campo obrigatório</i>
            </p>
            <FiltersReportsHpv
              selectedOptinsProfissionalResponsavel={
                selectedOptinsProfissionalResponsavel
              }
              setSelectedOptinsProfissionalResponsavel={
                setSelectedOptinsProfissionalResponsavel
              }
              selectedLaboratorio={selectedLaboratorio}
              disabledLaboratorioResultado={disabledLaboratorioResultado}
              selectedOptionsLaboratorio={selectedOptionsLaboratorio}
              setSelectedLaboratorio={setSelectedLaboratorio}
              setSelectedOptionsLaboratorio={setSelectedOptionsLaboratorio}
              watch={watch}
              dtResultadoIni={dtResultadoIni}
              setDataResultadoIni={setDataResultadoIni}
              dtResultadoFinal={dtResultadoFinal}
              setDataResultadoFinal={setDataResultadoFinal}
              periodResultado={periodResultado}
              setPeriodResultado={setPeriodResultado}
              dtColetaIni={dtColetaIni}
              setDataColetaIni={setDataColetaIni}
              dtColetaFinal={dtColetaFinal}
              setDataColetaFinal={setDataColetaFinal}
              setListFilterToShow={setListFilterShow}
              disableInputEstado={disableInputEstado}
              period={period}
              setPeriod={setPeriod}
              listFilter={listFilter}
              setListFilter={setListFilter}
              setListFilterShow={setListFilterShow}
              listFilterShow={listFilterShow}
              initialStateListFilter={initialStateListFilter}
              setTableData={setTableData}
              control={control}
              handleSubmit={handleSubmit}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
              estadosSelected={estadosSelected}
              setEstadosSelected={setEstadosSelected}
              setMunicipiosSelected={setMunicipiosSelected}
              setEstabelecimentosSelected={setEstabelecimentosSelected}
              municipiosSelected={municipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              setShowTableData={setShowTableData}
              showTableData={showTableData}
            />

            <TableReportsHpv
              selectedOptionsLaboratorio={selectedOptionsLaboratorio}
              selectedOptinsProfissionalResponsavel={
                selectedOptinsProfissionalResponsavel
              }
              dtResultadoIni={dtResultadoIni}
              dtResultadoFinal={dtResultadoFinal}
              tableData={tableData}
              setValue={setValue}
              watch={watch}
              setTableData={setTableData}
              estadosSelected={estadosSelected}
              municipiosSelected={municipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              equipesSelected={equipesSelected}
              showTableData={showTableData}
              dtColetaFinal={dtColetaFinal}
              dtColetaIni={dtColetaIni}
            />
          </Paper>
        </Box>
      </Container>
    </SideMenu>
  );
};

export default ReportsHpv;
