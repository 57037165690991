import { useEffect, useState } from 'react';
import { Paper, Typography } from '@mui/material';
import Chart from 'react-apexcharts';

export const NumberPhoneChart = () => {
  const [chartData, setChartData] = useState();
  const categories = [
    ['DEZ', '2023'],
    ['JAN', '2024'],
    'FEV',
    'MAR',
    'ABR',
    'MAI',
    'JUN',
    'JUL',
    'AGO',
    'SET',
    'OUT',
    'NOV',
    'DEZ',
  ];

  useEffect(() => {
    // if (data && categories.length > 0) {

    setChartData({
      series: [
        {
          name: 'Número da paciente correto',
          data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
        },
        {
          name: 'Número da paciente errado',
          data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35],
        },

        {
          name: 'Sem resposta',
          data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47],
        },
      ],

      dataLabels: {
        enabled: false,
      },
      legend: {
        position: 'top',
        itemMargin: {
          horizontal: 80,
        },
      },
      chart: {
        height: 370,
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
        width: 4,
      },
      xaxis: {
        categories: categories,
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },

      colors: ['#238884', '#F44336', '#9E9E9E'],
      grid: {
        show: true,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 10,
        },
      },
      yaxis: {
        tickAmount: 5,
        min: 0,
        labels: {
          show: true,
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
    });
    // }
  }, []);

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          position: 'relative',
          padding: '14px',
          marginTop: '24px',
          borderColor: '#BBC5CA',
        }}
      >
        <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>
          Índice de Assertividade com os Números de Telefone
        </Typography>
        <Typography sx={{ fontSize: '12px', color: '#00000061' }}>
          Todos os Períodos
        </Typography>
        {chartData && !!categories.length ? (
          <>
            <Chart
              options={chartData}
              series={chartData?.series}
              type={'line'}
              height={370}
            />
          </>
        ) : undefined}
        <p
          style={{
            marginBottom: '16px',
            marginRight: '16px',
            bottom: '0',
            right: '0',
            position: 'absolute',
            fontSize: '10px',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          Fonte dos Dados: JORDANA
        </p>
      </Paper>
    </>
  );
};
