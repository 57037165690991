import { Button, Checkbox, Menu, MenuItem, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useHandleSelect from '../../hooks/useHandleSelect';
import { StyledAsteriskTextField } from '../../../StyledAsteriskTextField/styles';
import { useEffect, useState } from 'react';
import { getUserToken } from '../../../../lib/auth';
import useStatesFields from '../../hooks/useStatesFields';

const ModalMunicipio = ({
  allMunicipiosOptions,
  searchMunicipio,
  anchorElMunicipios,
  municipiosSelected,
  optionsMunicipios,
  disableMunicipioInput,
  setAllMunicipiosOptions,
  setSearchMunicipio,
  setAnchorElMunicipios,
  setMunicipiosSelected,
  setOptionsMunicipios,
  loadingMunicipios,
  errors,
  setFirstLoadPage,
  requiredMunicipio,
}) => {
  const [nameMunicipio, setNameMunicipio] = useState(undefined);
  const [filterText, setFilterText] = useState<string>('');

  const { handleSelectMultiplesFields } = useHandleSelect();

  const hasError = !!errors.find((error) => error === 'municipio');

  const filteredOptions = optionsMunicipios?.filter((option) =>
    option?.nome?.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    if (optionsMunicipios?.length > 0) {
      setNameMunicipio(
        optionsMunicipios?.find(
          (municipio) => String(municipio.id) === String(municipiosSelected[0])
        )?.nome
      );
    }
  }, [optionsMunicipios, municipiosSelected]);

  const userToken = getUserToken();
  const userIsPadrao =
    userToken.permissao_atual?.id === 1 ||
    userToken.permissao_atual?.id === 7 ||
    userToken.permissao_atual?.id === 8 ||
    userToken.permissao_atual?.id === 9 ||
    userToken.permissao_atual?.id === 10;
  const isMunicipal = userToken.permissao_atual?.id === 3;

  const userIsLaboratorio =
    userToken.permissao_atual?.contexto === 'LABORATÓRIO';

  return (
    <>
      <StyledAsteriskTextField
        required={requiredMunicipio}
        data-testid="modalMunicipioInput"
        error={hasError}
        helperText={hasError ? 'Campo Obrigatório.' : undefined}
        size="small"
        disabled={
          (userIsPadrao && !userIsLaboratorio) || isMunicipal
            ? true
            : disableMunicipioInput
        }
        label="Municipio"
        value={
          municipiosSelected.length > 0
            ? municipiosSelected.length > 1
              ? 'Vários'
              : nameMunicipio
            : ''
        }
        sx={{
          padding: 0,
          width: '100%',
          '.MuiOutlinedInput-root': {
            paddingRight: '9px',
          },
        }}
        InputLabelProps={{
          shrink: municipiosSelected.length > 0 ? true : false,
        }}
        InputProps={{
          endAdornment: (
            <Button
              sx={{
                padding: 0,
                minWidth: '24px !important',
              }}
              data-testid="modalMunicipioDropdownButton"
              disabled={
                (userIsPadrao && !userIsLaboratorio) || isMunicipal
                  ? true
                  : disableMunicipioInput
              }
              onClick={(event) => {
                window.scrollTo(0, 0);
                setAnchorElMunicipios(event.currentTarget);
              }}
            >
              <ArrowDropDownIcon
                sx={{
                  cursor: 'pointer',
                  color:
                    (userIsPadrao && !userIsLaboratorio) ||
                    isMunicipal ||
                    disableMunicipioInput
                      ? '#BDBDBD'
                      : 'gray',
                }}
              />
            </Button>
          ),
        }}
      />
      <Menu
        anchorEl={anchorElMunicipios}
        open={!!anchorElMunicipios}
        onClose={() => {
          setAnchorElMunicipios(null);
          setTimeout(() => {
            setFilterText('');
          }, 500);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          position: 'absolute',
          width: '100%',
          marginTop: '10px',
          left: '10px',

          '.MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <div
          style={{
            width: '100%',
            padding: '24px 32px',
            flexDirection: 'column',
          }}
        >
          <TextField
            size="small"
            data-testid="inputSearchMunicipio"
            placeholder="Pesquise por Nome do Município"
            onChange={(e) => setFilterText(e.target.value)}
            value={filterText}
            sx={{
              width: '289px',
              '.MuiOutlinedInput-root': {
                paddingRight: '9px',
              },
            }}
            // InputProps={{
            //   endAdornment: (
            //     <Button
            //       data-testid="submitSearchMunicipio"
            //       sx={{
            //         padding: 0,
            //         minWidth: '24px !important',
            //       }}
            //       onClick={() => {
            //         if (!searchMunicipio) {
            //           setOptionsMunicipios(allMunicipiosOptions);
            //         } else {
            //           const newArrMunicipios = allMunicipiosOptions.filter(
            //             (municipio) =>
            //               municipio.nome
            //                 .toLowerCase()
            //                 .includes(searchMunicipio.toLowerCase())
            //           );
            //           setOptionsMunicipios(newArrMunicipios);
            //         }
            //       }}
            //     >
            //       <SearchIcon
            //         sx={{
            //           cursor: 'pointer',
            //           color: 'gray',
            //         }}
            //       />
            //     </Button>
            //   ),
            // }}
          />
          <div>
            <Button
              sx={{ padding: '0' }}
              data-testid="selectAllMunicipios"
              variant="text"
              onClick={() => {
                const allItemsArray = optionsMunicipios
                  .map((municipio: any) => municipio.id)
                  .filter((item) => item !== 'null');

                setMunicipiosSelected(allItemsArray);
              }}
            >
              SELECIONAR TODOS -{' '}
            </Button>
            <Button
              sx={{ padding: '0' }}
              data-testid="clearMunicipios"
              variant="text"
              onClick={() => setMunicipiosSelected([])}
            >
              LIMPAR
            </Button>
          </div>
          {loadingMunicipios ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <span
                className="loader"
                style={{ width: '40px', height: '40px' }}
              ></span>
            </div>
          ) : (
            <>
              {filteredOptions?.map((municicpio: any, index) => {
                municicpio.id = String(municicpio.id);

                return (
                  <div style={{ display: 'flex' }}>
                    <Checkbox
                      data-testid={`optionMunicipio-${index + 1}`}
                      onChange={() => {
                        setFirstLoadPage(false);
                        handleSelectMultiplesFields({
                          paramToSave: 'id',
                          setState: setMunicipiosSelected,
                          state: municipiosSelected,
                          value: municicpio,
                        });
                      }}
                      checked={
                        municipiosSelected?.find(
                          (item) => String(item) === String(municicpio.id)
                        )
                          ? true
                          : false
                      }
                    />
                    <MenuItem
                      sx={{ padding: 0 }}
                      onClick={() => {
                        setFirstLoadPage(false);
                        handleSelectMultiplesFields({
                          paramToSave: 'id',
                          setState: setMunicipiosSelected,
                          state: municipiosSelected,
                          value: municicpio,
                        });
                      }}
                    >
                      {municicpio?.nome}
                    </MenuItem>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </Menu>
    </>
  );
};

export default ModalMunicipio;
