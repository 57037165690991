import moment from 'moment';

import calendarPlus from '../../../../../../../assets/imgs/Convocation/calendarPlus.svg';
import dot from '../../../../../../../assets/imgs/Convocation/dot.svg';
import connector from '../../../../../../../assets/imgs/Convocation/connector.svg';

import { Box } from '@mui/material';

type Props = {
  schedules: Schedule[];
};

type Schedule = {
  wantSchedule: { is: boolean; when: string };
  confirmed: boolean;
};

const SchedulesItens = ({ schedules }: Props) => {
  return (
    <>
      {schedules.map((item, index) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img src={calendarPlus} style={{ width: '64px' }} />
            <p
              style={{
                fontSize: '16px',
                fontFamily: 'Roboto',
                fontWeight: 700,
              }}
            >
              Agendamento
              <br />
              <span style={{ fontSize: '12px', fontWeight: '400' }}>
                Lista de espera
              </span>
            </p>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px' }} src={dot} />
              <p
                style={{
                  fontWeight: 500,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  margin: '0 0 6.5px 14px',
                }}
              >
                Quis agendar?
              </p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px', height: '35px' }} src={connector} />
              <p
                style={{
                  fontWeight: 400,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  margin: '0 0 0 14px',
                  paddingTop: '7px',
                  width: '196px',
                }}
              >
                {item.wantSchedule.is ? 'Sim' : 'Não'}{' '}
                {item.confirmed
                  ? `, para o dia ${moment(item.wantSchedule.when).format(
                      'DD/MM/YYYY [às] HH[h]mm'
                    )}`
                  : ''}
              </p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px' }} src={dot} />
              <p
                style={{
                  fontWeight: 500,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  margin: '0 0 6.5px 14px',
                }}
              >
                Confirmou a presença?
              </p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <img style={{ width: '12px', height: '35px' }} src={connector} />
              <p
                style={{
                  fontWeight: 400,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  margin: '0 0 0 14px',
                  paddingTop: '7px',
                }}
              >
                {item.confirmed ? 'Sim' : 'Não'}
                <br />
              </p>
            </div>

            <Box
              display="flex"
              alignItems="center"
              flexDirection="row"
              gap="8px"
              paddingLeft="10px"
              marginTop="8px"
              sx={{
                fontSize: '13px',
                fontWeight: 500,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <span>+</span>
              <p style={{ margin: 0 }}>
                VER TODOS AGENDAMENTOS
                <br />
                (1)
              </p>
            </Box>
          </div>
        );
      })}
    </>
  );
};

export default SchedulesItens;
