import { Paper, Typography } from '@mui/material';

import ReactEcharts from 'echarts-for-react';
import { fontFamily } from 'html2canvas/dist/types/css/property-descriptors/font-family';

export const AttendancePatientChart = () => {
  const option = {
    angleAxis: {
      type: 'category',
      data: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
    },
    color: ['#17BEBB', '#F44336', '#238884'],
    radiusAxis: {},
    polar: {},

    series: [
      {
        type: 'bar',
        data: [1, 2, 3, 4, 3, 5, 1, 2, 3, 4, 3, 5, 1],
        coordinateSystem: 'polar',
        name: 'Compareceram sem Agendamento',
        stack: 'a',
        emphasis: {
          focus: 'series',
        },
      },
      {
        type: 'bar',
        data: [2, 4, 6, 1, 3, 2, 1, 2, 3, 4, 3, 5, 1],
        coordinateSystem: 'polar',
        name: 'Não Compareceram',
        stack: 'a',
        emphasis: {
          focus: 'series',
        },
      },
      {
        type: 'bar',
        data: [1, 2, 3, 4, 1, 2, 5, 2, 3, 4, 3, 5, 1],
        coordinateSystem: 'polar',
        name: 'Compareceram',
        stack: 'a',
        emphasis: {
          focus: 'series',
        },
      },
    ],
    legend: {
      textStyle: {
        fontFamily: 'Roboto',
      },
      icon: 'circle',
      show: true,
      data: [
        'Compareceram',
        'Compareceram sem Agendamento',
        'Não Compareceram',
      ],
      left: '0px',
    },
  };
  return (
    <Paper
      variant="outlined"
      sx={{
        position: 'relative',
        padding: '14px',
        marginTop: '24px',
        borderColor: '#BBC5CA',
        paddingBottom: '62px',
      }}
    >
      <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>
        Comparecimento das Pacientes ao Testes de HPV Agendado
      </Typography>
      <Typography
        sx={{ fontSize: '12px', color: '#00000061', marginTop: '8px' }}
      >
        Todos os Períodos
      </Typography>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '24px',
        }}
      >
        <ReactEcharts
          style={{
            width: '100%',
            height: '370px',
          }}
          option={option}
        />
      </div>
      <p
        style={{
          marginTop: '0',

          marginRight: '16px',

          right: '0',
          position: 'absolute',
          fontSize: '10px',
          color: 'rgba(0, 0, 0, 0.6)',
        }}
      >
        Fonte dos Dados: JORDANA
      </p>
    </Paper>
  );
};
