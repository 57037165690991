import { Paper, Typography } from '@mui/material';
import { HorizontalBar } from '../../../../../../../components/Charts/horizontalBar';

export const IndexResponseChart = () => {
  const legends = [
    {
      title: 'Responderam',
      color: '#238884',
    },
    {
      title: 'Não Responderam',
      color: '#F44336',
    },
  ];

  const data = [
    { itemLabel: 'Amaraji', values: [10, 2] },
    { itemLabel: 'Barreiros', values: [4, 8] },
    { itemLabel: 'Cortês', values: [7, 3] },
    { itemLabel: 'Lagoa dos Gatos', values: [5, 5] },
    { itemLabel: 'Primavera', values: [4, 5] },
    { itemLabel: 'Fernando de Noronha', values: [4, 5] },
    { itemLabel: 'Jaboatão dos Guararapes', values: [7, 9] },
    { itemLabel: 'Jaboatão dos Guararapes', values: [7, 9] },
    { itemLabel: 'Jaboatão dos Guararapes', values: [7, 9] },
    { itemLabel: 'Jaboatão dos Guararapes', values: [7, 9] },
    { itemLabel: 'Jaboatão dos Guararapes', values: [7, 9] },
    { itemLabel: 'Jaboatão dos Guararapes', values: [7, 9] },
  ];
  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: '10px',
        borderColor: '#BBC5CA',
        marginTop: '24px',
        position: 'relative',
        padding: '16px',
        height: '535px',
      }}
    >
      <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>
        Índice de Respostas
      </Typography>
      <Typography sx={{ fontSize: '12px', color: '#00000061' }}>
        Todos os Períodos
      </Typography>
      <HorizontalBar
        data={data as any}
        needsRequiredFilters={false}
        payload={{
          params: {},
        }}
      />
      <p
        style={{
          marginBottom: '16px',
          marginRight: '16px',
          bottom: '0',
          right: '0',
          position: 'absolute',
          fontSize: '10px',
          color: 'rgba(0, 0, 0, 0.6)',
        }}
      >
        Fonte dos Dados: JORDANA
      </p>
    </Paper>
  );
};
