import { Button, Grid, TextField } from '@mui/material';
import { validateSearch } from '../../../../../utils/validateSearchInput';
import { SelectWithSearch } from '../../../../../components/SelectWithSearch';
import PeriodoInput from '../../../../../components/Inputs/PeriodoInput';
import SearchByNameInput from '../../../../../components/Inputs/SearchByNameInput';
import { DisplayFilter } from '../../List/styles';

const FiltersConvocationGeneralView = ({
  handleSubmit,
  control,
  clearErrors,
  setError,
  watch,
  errors,
  setValue,
  initialStateListFilter,
  listFilter,
  setListFilter,
  setListFilterShow,
  listFilterShow,
  setShowTableData,
  showTableData,
  handleSetFilter,
}) => {
  const submitForm = () => {
    const searchNameCpfCnsIsValid = validateSearch({
      clearErrors,
      setError,
      watch,
    });

    if (searchNameCpfCnsIsValid) {
      // setLoading(true);
      // setDataCentral([]);
      // setPayload().then((payload) => loadDataCentralNotification(payload));
      // setListFilterShow(listFilter);
    }
  };

  const handleSetSelectedOptions =
    ({ setSelected, fieldName }) =>
    (options) => {
      setSelected(options);
      const optionLabel = () => {
        if (options.length === 0) {
          return '';
        }

        if (options.length === 1) {
          return `${fieldName}: ${options[0].label}`;
        }

        if (options.length > 1) {
          return `${fieldName}: Vários`;
        }
      };
      handleSetFilter(optionLabel(), 'resultado');
    };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={3} mt="2px">
        <Grid xs={12} md={4} item>
          <SelectWithSearch
            // requestOptionsBySearch={(text) => {
            //   getProfissionaisList({ bySearchInput: false, nome: text });
            // }}
            loading={false}
            options={[]}
            isLaboratorio
            label="Estado"
            disabled={false}
            selectedOptions={[]}
            setSelectedOptions={() => null}
            setSelected={handleSetSelectedOptions({
              setSelected: () => null,
              fieldName: 'Profissional Responsável pela Coleta',
            })}
            dataTestIdModal="modalLaboratorioLaudoInput"
            dataTestIdInputSearch="inputSearchLaboratorioLaudo"
            dataTestSelectAll="selectAllLaboratorioLaudo"
            dataTestClearAll="clearLaboratorioLaudo"
            // emptyCondition={estabelecimentosSelected.length !== 1}
            // emptyMessage="Selecione um Estabelecimento de Saúde"
          />
        </Grid>

        <Grid xs={12} md={4} item>
          <SelectWithSearch
            // requestOptionsBySearch={(text) => {
            //   getProfissionaisList({ bySearchInput: false, nome: text });
            // }}
            loading={false}
            options={[]}
            isLaboratorio
            label="Município"
            disabled={false}
            selectedOptions={[]}
            setSelectedOptions={() => null}
            setSelected={handleSetSelectedOptions({
              setSelected: () => null,
              fieldName: 'Profissional Responsável pela Coleta',
            })}
            dataTestIdModal="modalLaboratorioLaudoInput"
            dataTestIdInputSearch="inputSearchLaboratorioLaudo"
            dataTestSelectAll="selectAllLaboratorioLaudo"
            dataTestClearAll="clearLaboratorioLaudo"
            // emptyCondition={estabelecimentosSelected.length !== 1}
            // emptyMessage="Selecione um Estabelecimento de Saúde"
          />
        </Grid>

        <Grid xs={12} md={4} item>
          <SelectWithSearch
            // requestOptionsBySearch={(text) => {
            //   getProfissionaisList({ bySearchInput: false, nome: text });
            // }}
            loading={false}
            options={[]}
            isLaboratorio
            label="Estabelecimento"
            disabled={false}
            selectedOptions={[]}
            setSelectedOptions={() => null}
            setSelected={handleSetSelectedOptions({
              setSelected: () => null,
              fieldName: 'Profissional Responsável pela Coleta',
            })}
            dataTestIdModal="modalLaboratorioLaudoInput"
            dataTestIdInputSearch="inputSearchLaboratorioLaudo"
            dataTestSelectAll="selectAllLaboratorioLaudo"
            dataTestClearAll="clearLaboratorioLaudo"
            // emptyCondition={estabelecimentosSelected.length !== 1}
            // emptyMessage="Selecione um Estabelecimento de Saúde"
          />
        </Grid>

        <Grid xs={12} md={4} item>
          <TextField label="Idade Início" select fullWidth size="small" />
        </Grid>

        <Grid xs={12} md={4} item>
          <TextField label="Idade Final" select fullWidth size="small" />
        </Grid>

        <Grid xs={12} md={4} item>
          <PeriodoInput control={control} handleSetFilter={handleSetFilter} />
        </Grid>

        <Grid xs={12} item>
          <SelectWithSearch
            // requestOptionsBySearch={(text) => {
            //   getProfissionaisList({ bySearchInput: false, nome: text });
            // }}
            loading={false}
            options={[]}
            isLaboratorio
            label="Agente Comunitário de Saúde (ACS)"
            disabled={false}
            selectedOptions={[]}
            setSelectedOptions={() => null}
            setSelected={handleSetSelectedOptions({
              setSelected: () => null,
              fieldName: 'Profissional Responsável pela Coleta',
            })}
            dataTestIdModal="modalLaboratorioLaudoInput"
            dataTestIdInputSearch="inputSearchLaboratorioLaudo"
            dataTestSelectAll="selectAllLaboratorioLaudo"
            dataTestClearAll="clearLaboratorioLaudo"
            // emptyCondition={estabelecimentosSelected.length !== 1}
            // emptyMessage="Selecione um Estabelecimento de Saúde"
          />
        </Grid>
        <Grid item xs={10}>
          <SearchByNameInput
            control={control}
            errors={errors}
            handleSetFilter={() => null}
          />
        </Grid>
        <Grid item xs={1}>
          <Button variant="contained">Filtrar</Button>
        </Grid>
        <Grid item xs={1}>
          <Button variant="outlined" color="error">
            Limpar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <DisplayFilter />
        </Grid>
      </Grid>
    </form>
  );
};

export default FiltersConvocationGeneralView;
