import { Box, Grid, Paper, Tooltip, Typography } from '@mui/material';

import './DashboardConvocationGeneralViewStyle.scss';

import Activity from '../../../../../assets/imgs/activity.svg';
import AlertInfo from '../../../../../assets/imgs/AlertInfo.svg';
import GreenCheck from '../../../../../assets/imgs/green-check.svg';
import YellowDots from '../../../../../assets/imgs/yellow-dots.svg';
import RedXCircle from '../../../../../assets/imgs/red-x-circle.svg';
import PurpleExclamation from '../../../../../assets/imgs/purpe-exclamation.svg';

import moment from 'moment';
import 'moment/locale/pt-br';
import ItemTotalCardConvocation from '../../../../../components/ItemTotalCardConvocation';
import { TotalChart } from './Charts/TotalChart';
import { useState } from 'react';
import { TypeConvocationChart } from './Charts/TypeConvocationChart';
import { NumberPhoneChart } from './Charts/NumberPhoneChart';
import { IndexResponseChart } from './Charts/IndexResponseChart';
import { TimeResponseChart } from './Charts/TimeResponseChart';
import { PatientsResponseChart } from './Charts/PatientsResponseChart';
import { PatientConfirmationChart } from './Charts/PatientConfirmationChart';
import { AttendancePatientChart } from './Charts/AttendancePatientChart';

const DashboardConvocationGeneralView = () => {
  const [totalConvocationData, setTotalConvocationData] = useState<any[]>([]);
  const [typeConvocationData, setTypeConvocationData] = useState<any[]>([]);
  const [numberPhoneData, setNumberPhoneData] = useState<any[]>([]);
  const [indexResponseData, setIndexResponseData] = useState<any[]>([]);
  const [timeResponseData, setTimeResponseData] = useState<any[]>([]);
  const [responseConvocationData, setResponseConvocationData] = useState<any[]>(
    []
  );
  const [patientConfirmationData, setPatientConfirmationData] = useState<any[]>(
    []
  );
  const [attendancePatientData, setAttendancePatientData] = useState<any[]>([]);

  const [categoriesTotalConvocationData, setCategoriesTotalConvocationData] =
    useState([
      219, 382, 363, 560, 18, 29, 11, 0, 0, 0, 0, 1, 31, 939, 1188, 772, 664,
      1079, 1438, 1719, 1423, 1352, 1972, 1850, 1527, 1771, 1653, 977, 313,
    ]);

  const totalValue = 74;
  moment.locale('pt-br');
  const lastUpdate = moment().format('dddd, D MMMM YYYY');
  return (
    <Paper
      className="dashboard"
      variant="outlined"
      sx={{
        marginTop: '24px',
        padding: '32px',
        borderRadius: '10px',
        borderColor: '#BBC5CA',
      }}
    >
      <Paper
        variant="outlined"
        sx={{ padding: '24px', borderRadius: '10px', borderColor: '#BBC5CA' }}
      >
        <Typography
          variant="h4"
          color="#00000099"
          fontSize="20px"
          fontWeight={700}
        >
          Convocações nos últimos 12 meses
        </Typography>
        <div
          style={{
            display: 'flex',
            width: '129px',
            justifyContent: 'space-between',
            marginTop: '10px',
          }}
        >
          <img src={Activity} alt="" />
          <p
            style={{
              margin: 0,
              color: 'rgba(0, 0, 0, 0.60)',
              fontWeight: 700,
            }}
          >
            Todas
          </p>
          <Tooltip
            title="Quantidade total de  convocações feitas"
            placement="top"
          >
            <img src={AlertInfo} alt="" style={{ marginLeft: '13px' }} />
          </Tooltip>
        </div>
        <Typography fontSize={96} fontWeight={700} color="#00000099">
          {totalValue}
        </Typography>
        <span style={{ color: '#00000099', fontSize: '12px' }}>
          Última atualização: {lastUpdate}
        </span>
        <Grid container spacing={3} marginTop="0">
          <Grid item xs={12} lg={3}>
            <ItemTotalCardConvocation
              color="#4CAF50"
              title="Agendadas"
              value={1}
              bottomPhrase={`${5} + essa semana`}
              icon={GreenCheck}
              tooltipTitle="Quantidade de convocações  que geraram agendamentos "
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <ItemTotalCardConvocation
              color="#FFB547"
              title="Pendentes"
              value={1}
              bottomPhrase={`${1} + essa semana`}
              icon={YellowDots}
              tooltipTitle="Quantidade de convocações que ainda não tiveram resposta da convocação"
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <ItemTotalCardConvocation
              color="#FC5555"
              title="Não Atendidas"
              value={1}
              bottomPhrase={`${1} + essa semana`}
              icon={RedXCircle}
              tooltipTitle="Quantidade de convocações  que não geraram agendamentos"
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <ItemTotalCardConvocation
              color="#7986CB"
              title="Não Elegíveis"
              value={1}
              bottomPhrase={`${1} + essa semana`}
              icon={PurpleExclamation}
              tooltipTitle="Quantidade de convocações que estavam foram do público-alvo do exame"
            />
          </Grid>
        </Grid>
        <TotalChart />
      </Paper>
      <TypeConvocationChart />
      <NumberPhoneChart />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <IndexResponseChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <TimeResponseChart />
        </Grid>
      </Grid>
      <PatientsResponseChart />
      <PatientConfirmationChart />
      <AttendancePatientChart />
    </Paper>
  );
};

export default DashboardConvocationGeneralView;
