const series = [
  {
    name: '',
    data: ['5428'],
  },
];

const mockCategories = [['25 a 34', 'ANOS']];
const categories = ['1º Convocação', '2º Convocação', '3º Convocação'];

export const structureChartBarAcsSelected = {
  series,
  chart: {
    type: 'bar',
    height: 284,
    width: 350,
    events: {
      // click: handleBarClick,
    },
    toolbar: {
      tools: {
        download: false,
      },
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '20%',
      dataLabels: {
        position: 'top',
      },
    },
  },
  dataLabels: {
    enabled: true,
    offsetY: -20,
    style: {
      fontSize: '12px',
      colors: ['rgba(0,0,0,0.7)'],
    },
    background: {
      enabled: true,
      foreColor: '#ffffff',
      borderRadius: 6,
      borderColor: 'transparent',
    },
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  xaxis: {
    type: 'category',
    categories: mockCategories,
    labels: {
      rotate: 0,
    },
  },
  yaxis: {
    //tickAmount: 5,
    min: 0,
    //stepSize: 100,
  },
  fill: {
    opacity: 1,
  },
  colors: [
    '#5f2b01',
    // function ({ value, dataPointIndex, w }) {
    //   const categoryName = w.config.xaxis.categories[dataPointIndex];

    //   if (categoryName[0] === 'FORA DA FAIXA') {
    //     return '#C1CCCD';
    //   } else {
    //     return '#7B61FF';
    //   }
    // },
  ],
  legend: {
    show: false,
  },
  responsive: [
    {
      breakpoint: 768,
      options: {
        xaxis: {
          labels: {
            style: {
              fontSize: '10px',
            },
          },
        },
      },
    },
  ],
};
const data = ['5427', '7777', '8637'];

export const structureChartBarAutoatendimentoSelected = {
  // series: seriesAutoatendimentoSelected,
  // chart: {
  //   type: 'bar',
  //   height: 284,
  //   width: 350,
  //   // events: {

  //   // },
  //   toolbar: {
  //     tools: {
  //       download: false,
  //     },
  //   },
  // },
  // plotOptions: {
  //   bar: {
  //     horizontal: false,
  //     columnWidth: '20%',
  //     dataLabels: {
  //       position: 'top',
  //     },
  //   },
  // },
  // dataLabels: {
  //   enabled: true,
  //   offsetY: -20,
  //   style: {
  //     fontSize: '12px',
  //     colors: ['rgba(0,0,0,0.7)'],
  //   },
  //   background: {
  //     enabled: true,
  //     foreColor: '#ffffff',
  //     borderRadius: 6,
  //     borderColor: 'transparent',
  //   },
  // },
  // stroke: {
  //   show: true,
  //   width: 2,
  //   colors: ['transparent'],
  // },
  // xaxis: {
  //   type: 'category',
  //   categories: mockCategoriesAcsSelected,
  //   labels: {
  //     rotate: 0,
  //   },
  // },
  // yaxis: {
  //   //tickAmount: 5,
  //   min: 0,
  //   //stepSize: 100,
  // },
  // fill: {
  //   opacity: 1,
  // },
  // colors: [
  //   '#a1166b',
  //   '#a1166b',
  //   '#a1166b',
  //   // function ({ value, dataPointIndex, w }) {
  //   //   const categoryName = w.config.xaxis.categories[dataPointIndex];

  //   //   if (categoryName[0] === 'FORA DA FAIXA') {
  //   //     return '#C1CCCD';
  //   //   } else {
  //   //     return '#7B61FF';
  //   //   }
  //   // },
  // ],
  // legend: {
  //   show: false,
  // },
  // responsive: [
  //   {
  //     breakpoint: 768,
  //     options: {
  //       xaxis: {
  //         labels: {
  //           style: {
  //             fontSize: '10px',
  //           },
  //         },
  //       },
  //     },
  //   },
  // ],
  series: [
    {
      name: '',
      data: data,
    },
  ],
  chart: {
    type: 'bar',
    height: 284,
    events: {
      // click: handleBarClick,
    },
    toolbar: {
      tools: {
        download: false,
      },
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '71px',
      dataLabels: {
        position: 'top',
      },
    },
  },
  dataLabels: {
    enabled: true,
    offsetY: -20,
    style: {
      fontSize: '12px',
      colors: ['rgba(0,0,0,0.7)'],
    },
    background: {
      enabled: true,
      foreColor: '#ffffff',
      borderRadius: 6,
      borderColor: 'transparent',
    },
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  xaxis: {
    type: 'category',
    categories: categories,
    labels: {
      rotate: 0,
    },
  },
  yaxis: {
    //tickAmount: 5,
    min: 0,
    //stepSize: 100,
  },
  fill: {
    opacity: 1,
  },
  colors: ['#a1166b'],
  legend: {
    show: false,
  },
  responsive: [
    {
      breakpoint: 768,
      options: {
        xaxis: {
          labels: {
            style: {
              fontSize: '10px',
            },
          },
        },
      },
    },
  ],
};
