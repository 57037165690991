import { PerfilContext } from '../../../../../context/PerfilContext';
import { useContext, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import CustomizedButton from '../../../../../components/CustomizedButton';

import { formatCpf } from '../../../../../utils/formatters';
import moment from 'moment';

import AgendadaModal from '../modais/AgendadaModal';
import NaoAtendida from '../modais/NaoAtendidaModal';
import PendenteModal from '../modais/PendenteModal';
import NaoElegivel from '../modais/NaoElegivel';
import { getConvocations } from '../../../../../lib/convocation';
import HeadersTableConvocation from './Headers';
import RowsTableConvocation, {
  getColorAndLabelStatus,
  ModalType,
  StatusEnum,
} from './Rows';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddIcon from '@mui/icons-material/Add';
import FixedColumnConvocation from './FixedColumn';

const TableConvocation = ({
  tableData,
  setTableData,
  rowsPerPage,
  setRowsPerPage,
  currentPage,
  setCurrentPage,
  stateToSaveFilter,
}) => {
  const [modalAberto, setModalAberto] = useState<string | null>(null);
  const [registroSelecionado, setRegistroSelecionado] = useState(null);

  const { hasPermission } = useContext(PerfilContext);

  const abrirModal = (status, registro) => {
    const modalTypes = Object.values(ModalType);

    if (modalTypes.includes(status)) {
      const modalType = ModalType[status];
      setModalAberto(modalType);
      setRegistroSelecionado(registro);
    } else {
    }
  };

  const fecharModal = () => {
    setModalAberto(null);
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));

    try {
      const response = await getConvocations({
        getParams: {
          ...stateToSaveFilter,
          size: event.target.value,
          page: String(currentPage),
        },
      });
      setTableData({ ...response, rows: response.data });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangePage = async (newPage) => {
    setCurrentPage(newPage + 1);
    try {
      const response = await getConvocations({
        getParams: {
          ...stateToSaveFilter,
          page: newPage + 1,
          size: String(rowsPerPage),
        },
      });
      setTableData({ ...response, rows: response.data });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <TableContainer sx={{ position: 'relative' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <HeadersTableConvocation />
          <TableBody>
            <RowsTableConvocation
              setModalAberto={setModalAberto}
              setRegistroSelecionado={setRegistroSelecionado}
              tableData={tableData}
            />

            {/* Renderizar o modal correto com base no estado */}
            {modalAberto === 'Agendada' && registroSelecionado && (
              <AgendadaModal
                open={modalAberto === 'Agendada'}
                onClose={fecharModal}
                registro={registroSelecionado}
              />
            )}
            {modalAberto === 'NaoAtendida' && registroSelecionado && (
              <NaoAtendida
                open={modalAberto === 'NaoAtendida'}
                onClose={fecharModal}
                registro={registroSelecionado}
              />
            )}
            {modalAberto === 'Pendente' && registroSelecionado && (
              <PendenteModal
                open={modalAberto === 'Pendente'}
                onClose={fecharModal}
                registro={registroSelecionado}
              />
            )}
            {modalAberto === 'NaoElegivel' && registroSelecionado && (
              <NaoElegivel
                open={modalAberto === 'NaoElegivel'}
                onClose={fecharModal}
                registro={registroSelecionado}
              />
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={tableData.totalItems}
          rowsPerPage={rowsPerPage}
          page={tableData.page - 1}
          onPageChange={(e, newPage) => handleChangePage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Itens por página'}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} de ${
              count !== -1 ? count : `mais do que ${to}`
            }`;
          }}
        />
      </TableContainer>
      <FixedColumnConvocation
        tableData={tableData}
        setModalAberto={setModalAberto}
        setRegistroSelecionado={setRegistroSelecionado}
      />
    </div>
  );
};

export default TableConvocation;
