import { Paper, TablePagination, Typography } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { useEffect, useState } from 'react';

export const TimeResponseChart = () => {
  const data = [
    '5414',
    '7777',
    '8637',
    '446',
    '446',
    '446',
    '446',
    '446',
    '446',
  ];
  const [chartData, setChartData] = useState<any>();
  const [page, setPage] = useState(1);

  const [lastItemSliced, setLastItemSliced] = useState(4);
  const [firstItemSliced, setFirstItemSliced] = useState(0);

  const handlePageChange = (event, newPage) => {
    const isNextPage = newPage + 1 > page ? true : false;

    setPage(newPage + 1);
    setFirstItemSliced((prev) => (isNextPage ? prev + 4 : prev - 4));
    setLastItemSliced((prev) => (isNextPage ? prev + 4 : prev - 4));
  };

  const categoriesFaixa = [
    'Amaraji',
    'Barreiros',
    'Cortês',
    ['Lagoa dos', 'Gatos'],
    ['Lagoa dos', 'Gatos'],
    ['Lagoa dos', 'Gatos'],
    ['Lagoa dos', 'Gatos'],
    ['Lagoa dos', 'Gatos'],
    ['Lagoa dos', 'Gatos'],
  ];

  const categoriesPaginated = categoriesFaixa.slice(
    firstItemSliced,
    lastItemSliced
  );

  const dataPaginate = data.slice(firstItemSliced, lastItemSliced);
  useEffect(() => {
    setChartData({
      series: [
        {
          name: '',
          data: dataPaginate,
        },
      ],
      chart: {
        type: 'bar',
        height: 362,
        events: {
          // click: handleBarClick,
        },
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['rgba(0,0,0,0.7)'],
        },
        background: {
          enabled: true,
          foreColor: '#ffffff',
          borderRadius: 6,
          borderColor: 'transparent',
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        type: 'category',
        categories: categoriesPaginated,
        labels: {
          rotate: 0,
        },
      },
      yaxis: {
        //tickAmount: 5,
        min: 0,
        //stepSize: 100,
      },
      fill: {
        opacity: 1,
      },
      // colors: [
      //   function ({ value, dataPointIndex, w }) {
      //     const categoryName = w.config.xaxis.categories[dataPointIndex];

      //     if (categoryName[0] === 'FORA DA FAIXA') {
      //       return '#C1CCCD';
      //     } else {
      //       return '#00A3FF';
      //     }
      //   },
      // ],
      colors: ['#00A3FF'],
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            xaxis: {
              labels: {
                style: {
                  fontSize: '10px',
                },
              },
            },
          },
        },
      ],
    });
  }, [page]);

  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: '10px',
        borderColor: '#BBC5CA',
        marginTop: '24px',
        position: 'relative',
        padding: '16px',
        height: '535px',
      }}
    >
      <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>
        Tempo Médio das Respostas
      </Typography>
      <Typography sx={{ fontSize: '12px', color: '#00000061' }}>
        Todos os Períodos
      </Typography>

      {chartData ? (
        <ReactApexChart
          options={chartData}
          series={chartData?.series}
          type="bar"
          height={362}
        />
      ) : undefined}
      <TablePagination
        sx={{
          '.MuiTablePagination-selectLabel': {
            display: 'none',
          },
          '.MuiTablePagination-select': { display: 'none' },
          '.MuiTablePagination-selectIcon': {
            display: 'none',
          },
        }}
        component="div"
        count={data?.length}
        rowsPerPage={4}
        page={page - 1}
        onPageChange={handlePageChange}
        labelRowsPerPage={'Itens por página'}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} de ${
            count !== -1 ? count : `mais do que ${to}`
          }`;
        }}
      />
      <p
        style={{
          marginBottom: '16px',
          marginRight: '16px',
          bottom: '0',
          right: '0',
          position: 'absolute',
          fontSize: '10px',
          color: 'rgba(0, 0, 0, 0.6)',
        }}
      >
        Fonte dos Dados: JORDANA
      </p>
    </Paper>
  );
};
