import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import useStatesFields from '../../../components/MultipleFilters/hooks/useStatesFields';
import ReturnButton from '../../../components/ReturnButton';
import { SideMenu } from '../../../components/SideMenu';
import { TableReportsCitologiaReflexo } from '../../../components/TableReports/TableCitologiaReflexo/TableReportsCitologiaReflexo';
import { Option } from '../../../types/Option';
import FiltersReportsCitologiaReflexo from './FiltersReportsCitologiaReflexo';
import { getCidades } from '../../../lib/getCidadesEstabelecimentos';
import useGetDetailsFilters from '../ReportsTrackingHpv/FiltersReportsTrackingHpv/hooks/useGetDetailsFilters';
import useHandleChangeFilters from './FiltersReportsCitologiaReflexo/hooks/useHandleChangesFilters';
import { getUserToken } from '../../../lib/auth';
import api from '../../../api';
import { DisplayFilter, ItemsDisplayFilter } from '../../Convocation/styles';
import { LoadingContext } from '../../../context/LoadingContext';
import useSetFilterByPerfil from '../../../utils/hooks/useSetFilterByPerfil';
import { getReportsCitologiaReflexo } from '../../../lib/Reports/getReportsCtiologiaReflexo';
import useRequestFilter from './FiltersReportsCitologiaReflexo/hooks/useRequestFilter';

// const RequiredText = () => {
//   return (
//     <div style={{ margin: '3px 0 0', fontSize: '12px' }}>
//       <p style={{ margin: 0, fontStyle: 'italic', color: 'rgba(0,0,0,0.6)' }}>
//         <span style={{ color: 'red' }}>* </span>Obrigatório
//       </p>
//     </div>
//   );
// };

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento_saude', value: '' },
  { inputName: 'estabelecimentoLaudoId', value: '' },
  { inputName: 'resultado', value: '' },
  { inputName: 'profissionalResultadoId', value: '' },
  { inputName: 'data_ini', value: '' },
  { inputName: 'data_fin', value: '' },
  { inputName: 'search', value: '' },
];

export const ReportsCitologiaReflexo = () => {
  const [tableData, setTableData] = useState({
    data: [],
    page: 1,
    count: 25,
    totalPages: 1,
    totalItems: 0,
  });
  // const [, setEstadosSelecionados] = useState<Option[]>([]);
  // const [, setMunicipiosSelecionados] = useState<Option[]>([]);
  // const [, setLaboratoriosSelecionados] = useState<Option[]>([]);
  // const [, setResultadoCitologia] = useState<Option[]>([]);
  const [period, setPeriod] = useState('period');
  const [showTableData, setShowTableData] = useState(false);
  const [listFilterShow, setListFilterShow] = useState<any[]>([]);
  const [listFilter, setListFilter] = useState<any[]>(initialStateListFilter);
  const [selectedResultado, setSelectedResultado] = useState<any>();
  const [selectedLaboratorio, setSelectedLaboratorio] = useState<any>();

  const [dtColetaIni, setDataColetaIni] = useState<any>(null);

  const [dtColetaFinal, setDataColetaFinal] = useState<any>(null);

  const { setLoading } = useContext(LoadingContext);
  const location = useLocation();
  const { origin, payload } = location.state || {};

  const [selectedOptionsLaboratorio, setSelectedOptionsLaboratorio] = useState<
    any[]
  >([]);

  const [disabledProfissionalResultado, setDisabledProfissionalResultado] =
    useState(false);
  const [disabledLaboratorioResultado, setDisabledLaboratorioResultado] =
    useState(false);

  const [loadingTable, setLoadingTable] = useState(true);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      page: 1,
      size: 25,
    },
  });

  const {
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    disableInputEstado,
    disableMunicipioInput,
    disableEstabelecimentoInput,

    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    setDisableInputEstado,
    setDisabledMunicipioInput,
    setDisabledEstabelecimentoInput,
    setOptionsMunicipios,
    setEstadosOptions,
    setOptionsEstabelecimentos,
    setOptionsLaboratorios,
    setOptionsProfisisonaisLaboratorio,
  } = useStatesFields();

  const { getMunicipioDetails } = useGetDetailsFilters();

  const { handleSetFilter } = useHandleChangeFilters({
    setListFilter,
  });

  // const clearValuesInputs = () => {
  //   setEstabelecimentosSelected([]);
  //   setDataColetaFinal('');
  //   setDataColetaIni('');
  //   setSelectedResultado([]);

  //   setListFilter([
  //     { inputName: 'estado', value: estadosSelected[0] },
  //     { inputName: 'municipio', value: '' },
  //     { inputName: 'estabelecimento_saude', value: '' },
  //     { inputName: 'resultado', value: '' },
  //     { inputName: 'profissionalLaudoId', value: '' },
  //     { inputName: 'data_ini_coleta', value: '' },
  //     { inputName: 'data_fin_coleta', value: '' },
  //     { inputName: 'data_ini_resultado', value: '' },
  //     { inputName: 'data_fin_resultado', value: '' },
  //     { inputName: 'search', value: '' },
  //   ]);

  //   setValue('profissionalLaudoId', '');
  // };

  const { loadReportsCitologiaReflexo } = useRequestFilter({
    setTableData,
    setLoadingTable,
  });

  const { loadFiltersByPerfil } = useSetFilterByPerfil({
    loadReports: loadReportsCitologiaReflexo,
    setListFilter: setListFilter,
    setListFilterShow: setListFilterShow,
    setSelectedOptionsLaboratorio: setSelectedOptionsLaboratorio,
    setShowTableData: setShowTableData,
    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    setOptionsMunicipios,
    setEstadosOptions,
    setOptionsEstabelecimentos,
    setOptionsLaboratorios,
    setValue,
    setOptionsProfisisonaisLaboratorio,
    fieldProfissionalResultado: 'profissionalResultadoId',
    fieldLaboratorioResultado: 'estabelecimentoLaudoId',

    selectedLaboratorio,
    setSelectedLaboratorio,
  });

  useEffect(() => {
    loadFiltersByPerfil();
  }, []);

  // const setFiltersWhenPadrao = async (userToken) => {
  //   setShowTableData(true);
  //   setDisableInputEstado(true);
  //   setDisabledMunicipioInput(true);
  //   setDisabledEstabelecimentoInput(true);
  //   return new Promise(async (resolve) => {
  //     let payload = {};
  //     setEstadosSelected(['PE']);

  //     const optionCidade = [
  //       {
  //         id: userToken.endereco?.municipio_id,

  //         nome: userToken.endereco?.municipio_nome,
  //         codigo: userToken.endereco?.municipio_code,
  //         estadoSigla: userToken.endereco?.estado,
  //       },
  //     ];

  //     setOptionsMunicipios(optionCidade);
  //     setMunicipiosSelected([optionCidade[0].id]);
  //     setEstabelecimentosSelected([userToken.estabelecimentoId]);
  //     payload = {
  //       page: 1,
  //       size: 25,
  //       estados: 'PE',
  //       municipios: optionCidade[0].id,
  //       estabelecimentoSaudeId: userToken.estabelecimentoId,
  //     };

  //     setListFilterShow([
  //       {
  //         inputName: 'estado',
  //         value: 'Estado: PE',
  //       },
  //       {
  //         inputName: 'municipio',
  //         value: `Municipio: ${userToken.endereco?.municipio_nome}`,
  //       },
  //       {
  //         inputName: 'estabelecimento_saude',
  //         value: `Estabelecimento de Saúde: ${userToken.estabelecimento_saude?.nome_fantasia}`,
  //       },
  //     ]);

  //     setListFilter([
  //       { inputName: 'estado', value: 'Estado: PE' },
  //       {
  //         inputName: 'municipio',
  //         value: `Municipio: ${userToken.endereco?.municipio_nome}`,
  //       },
  //       {
  //         inputName: 'estabelecimento_saude',
  //         value: `Estabelecimento de Saúde: ${userToken.estabelecimento_saude?.nome_fantasia}`,
  //       },
  //       { inputName: 'resultado', value: '' },
  //       { inputName: 'profissionalLaudo', value: '' },
  //       { inputName: 'data_ini', value: '' },
  //       { inputName: 'data_fin', value: '' },
  //       { inputName: 'search', value: '' },
  //     ]);
  //     resolve(payload);
  //   });
  // };

  // const setFiltersToRequestByTable = async () => {
  //   setShowTableData(true);
  //   setEstadosSelected(payload.params.estados ? [payload.params.estados] : []);
  //   setMunicipiosSelected(
  //     payload.params.municipios ? [payload.params.municipios] : []
  //   );
  //   setEstabelecimentosSelected(
  //     payload.params.estabelecimentos ? [payload.params.estabelecimentos] : []
  //   );

  //   if (payload.params.periodo_inicio && payload.params.periodo_fim) {
  //     setDataColetaIni(payload.params.periodo_inicio);
  //     setDataColetaFinal(payload.params.periodo_fim);
  //   }

  //   const updatedListFilterShow = [
  //     { inputName: 'estado', value: `Estado: ${payload.params.estados || ''}` },
  //     {
  //       inputName: 'municipio',
  //       value: `Município: ${payload.params.municipios || ''}`,
  //     },
  //   ].filter(
  //     (item) => item.value !== 'Estado: ' && item.value !== 'Município: '
  //   );

  //   setListFilterShow(updatedListFilterShow);
  //   setListFilter([...updatedListFilterShow, ...initialStateListFilter]);

  //   return {
  //     ...payload.params,
  //     page: 1,
  //     size: 25,
  //   };
  // };

  // const setFiltersWhenMunicipal = async (userToken) => {
  //   setShowTableData(true);
  //   setDisableInputEstado(true);
  //   setDisabledMunicipioInput(true);
  //   setDisabledEstabelecimentoInput(false);

  //   return new Promise(async (resolve) => {
  //     let payload = {};
  //     setEstadosSelected(['PE']);

  //     const optionsCidades = await getCidades('PE');

  //     if (optionsCidades.length > 0) {
  //       setOptionsMunicipios(optionsCidades);
  //       const municipio = await getMunicipioDetails(
  //         userToken.endereco?.municipio_nome
  //       );

  //       setMunicipiosSelected([municipio[0].id]);

  //       payload = {
  //         page: 1,
  //         size: 25,
  //         estados: 'PE',
  //         municipios: municipio[0].id,
  //       };

  //       setListFilterShow([
  //         {
  //           inputName: 'estado',
  //           value: 'Estado: PE',
  //         },
  //         {
  //           inputName: 'municipio',
  //           value: `Municipio: ${userToken.endereco?.municipio_nome}`,
  //         },
  //         {
  //           inputName: 'estabelecimento_saude',
  //           value: ``,
  //         },
  //       ]);

  //       setListFilter([
  //         { inputName: 'estado', value: 'Estado: PE' },
  //         {
  //           inputName: 'municipio',
  //           value: `Municipio: ${userToken.endereco?.municipio_nome}`,
  //         },
  //         {
  //           inputName: 'estabelecimento_saude',
  //           value: ``,
  //         },
  //         { inputName: 'resultado', value: '' },
  //         { inputName: 'profissionalLaudo', value: '' },
  //         { inputName: 'data_ini', value: '' },
  //         { inputName: 'data_fin', value: '' },
  //         { inputName: 'search', value: '' },
  //       ]);
  //       resolve(payload);
  //     }
  //   });
  // };

  // const setFiltersWhenEstadual = async () => {
  //   setShowTableData(false);
  //   setDisableInputEstado(true);

  //   return new Promise(async () => {
  //     let payload = {};
  //     setEstadosSelected(['PE']);

  //     payload = {
  //       page: 1,
  //       size: 25,
  //       estados: 'PE',
  //     };

  //     setListFilterShow([
  //       {
  //         inputName: 'estados',
  //         value: 'Estado: PE',
  //       },
  //     ]);

  //     handleSetFilter('Estado: PE', 'estados');
  //     // setLoading(false);

  //     // reject();
  //   });
  // };

  // const userToken = getUserToken();

  // const getReportsResultadosCitologia = async (payloadToRequest) => {
  //   try {
  //     const response = await api.get('/reports/visualizar/citologia', {
  //       params: {
  //         ...payloadToRequest,
  //         tipo: 'Reflexo',
  //       },
  //     });
  //     setTableData(response.data);
  //     setShowTableData(true);
  //   } catch (error) {
  //     console.error('Erro ao buscar dados de citologia:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const {
  //   estabelecimentoId: estabelecimentoIdUser,
  //   estabelecimento_saude: {
  //     nome_fantasia: estabelecimentoNameUser,
  //     endereco: {
  //       municipio: {
  //         id: municipioUserId,
  //         nome: municipioUserName,
  //         estadoSigla: estadoUserSigla,
  //       },
  //     },
  //   },
  // } = userToken;

  // const setDefaultEstado = ({ sigla }) => {
  //   setEstadosOptions([
  //     {
  //       sigla: sigla,
  //     },
  //   ]);
  //   handleSetFilter(`Estado: ${sigla}`, 'estado');

  //   setEstadosSelected([sigla]);
  // };

  // const setDefaultMunicipio = ({ municipioId, municipioNome }) => {
  //   setOptionsMunicipios({
  //     id: municipioId,
  //     nome: municipioNome,
  //   });
  //   handleSetFilter(`Municipio: ${municipioNome}`, 'municipio');
  //   setMunicipiosSelected([municipioId]);
  // };

  // const setDefaultEstabelecimento = ({
  //   estabelecimentoId,
  //   estabelecimentoNome,
  // }) => {
  //   setOptionsEstabelecimentos({
  //     id: estabelecimentoIdUser,
  //     municipio: municipioUserName,
  //     municipio_id: municipioUserId,
  //     nome: estabelecimentoNameUser,
  //     label: estabelecimentoNameUser,
  //   });

  //   setEstabelecimentosSelected([estabelecimentoIdUser]);
  // };

  // const setDefaultLaboratorio = ({ laboratorioId, laboratorioName }) => {
  //   setOptionsLaboratorios([
  //     {
  //       label: laboratorioName,
  //       value: laboratorioId,
  //     },
  //   ]);
  //   setSelectedOptionsLaboratorio([
  //     {
  //       label: laboratorioName,
  //       value: laboratorioId,
  //     },
  //   ]);

  //   handleSetFilter(
  //     `Laboratório Responsável pelo Laudo: ${laboratorioName}`,
  //     'estabelecimentoLaudoId'
  //   );
  // };

  // const isSuperAdmin = userToken.permissao_atual?.nome === 'SuperAdmin';
  // const isEstadual = userToken.permissao_atual?.nome === 'Admin Estadual';
  // const isMunicipal = userToken.permissao_atual?.nome === 'Admin Municipal';
  // const isAdminLaboratorio =
  //   userToken.permissao_atual?.nome === 'Admin Estabelecimento';
  // const isPadraoEstabelecimento =
  //   userToken.permissao_atual?.contexto === 'UNIDADE DE SAÚDE';

  // const actionsWhenUserIsEstadual = () => {
  //   setDefaultEstado({
  //     sigla: estadoUserSigla,
  //   });
  // };

  // const actionsWhenUserIsMunicipal = () => {
  //   setDefaultEstado({
  //     sigla: estadoUserSigla,
  //   });
  //   setDefaultMunicipio({
  //     municipioId: String(municipioUserId),
  //     municipioNome: municipioUserName,
  //   });

  //   setListFilterShow((prev) => [
  //     ...prev,
  //     { inputName: 'estados', value: `Estados: ${estadoUserSigla}` },
  //     { inputName: 'municipio', value: `Município: ${municipioUserName}` },
  //   ]);

  //   setShowTableData(true);
  // };

  // const actionsWhenUserIsAdminLaboratorio = () => {
  //   setDefaultEstado({
  //     sigla: userToken.estabelecimento_saude?.endereco.municipio.estado.sigla,
  //   });

  //   setDefaultLaboratorio({
  //     laboratorioId: userToken.estabelecimentoId,
  //     laboratorioName: userToken.estabelecimento_saude?.nome_fantasia,
  //   });
  // };

  // const actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento = () => {
  //   actionsWhenUserIsMunicipal();

  //   setDefaultEstabelecimento({
  //     estabelecimentoId: estabelecimentoIdUser,
  //     estabelecimentoNome: estabelecimentoNameUser,
  //   });

  //   setListFilterShow((prev) => [
  //     ...prev,
  //     { inputName: 'estados', value: `Estados: ${estadoUserSigla}` },
  //     { inputName: 'municipio', value: `Município: ${municipioUserName}` },
  //     {
  //       inputName: 'estabelecimento_saude',
  //       value: `Estabelecimento de Saúde: ${estabelecimentoNameUser}`,
  //     },
  //   ]);
  // };

  // useEffect(() => {
  //   if (isSuperAdmin) {
  //     return;
  //   }

  //   if (isEstadual) {
  //     actionsWhenUserIsEstadual();
  //   }

  //   if (isMunicipal) {
  //     actionsWhenUserIsMunicipal();

  //     getReportsResultadosCitologia({
  //       estados: estadoUserSigla,
  //       municipios: municipioUserId,
  //       page: 1,
  //       size: 25,
  //     });
  //   }

  //   if (isAdminLaboratorio) {
  //     actionsWhenUserIsAdminLaboratorio();
  //   }

  //   if (isPadraoEstabelecimento) {
  //     actionsWhenUserIsMedicoOrEnfermeiroEstabelecimento();

  //     getReportsResultadosCitologia({
  //       estados: estadoUserSigla,
  //       municipios: municipioUserId,
  //       estabelecimentoSaudeId: estabelecimentoIdUser,
  //       page: 1,
  //       size: 25,
  //     });
  //   }
  // }, []);

  // const isMedicoLaboratorio =
  //   userToken.permissao_atual?.contexto === 'LABORATÓRIO' &&
  //   userToken.permissao_atual?.id === 7;

  // const isAdminLaboratorio =
  //   userToken.permissao_atual?.contexto === 'LABORATÓRIO' &&
  //   userToken.permissao_atual?.nome === 'Admin Estabelecimento';

  // const isOutrosLaboratorio =
  //   userToken.permissao_atual?.contexto === 'LABORATÓRIO' &&
  //   userToken.permissao_atual?.id === 8;

  // const isUserLaboratorio =
  //   userToken.permissao_atual?.contexto === 'LABORATÓRIO';
  // const setFiltersWhenMedicoLaboratorio = () => {
  //   setDisableInputEstado(true);
  //   setDisabledLaboratorioResultado(true);
  //   setDisabledProfissionalResultado(true);
  //   setShowTableData(true);
  //   return new Promise(async (resolve) => {
  //     let payload = {};
  //     setEstadosSelected(['PE']);

  //     const optionsCidades = await getCidades('PE');

  //     setOptionsMunicipios(optionsCidades);

  //     const getProfissional = await api.get(
  //       `/profissionais/estabelecimento/${userToken.estabelecimentoId}`
  //     );

  //     const dataOffProfissional = getProfissional.data?.find(
  //       (prof) => prof.idpessoa === userToken.pessoa.id
  //     );

  //     setValue('profissionalResultadoId', {
  //       ...dataOffProfissional,
  //       label: dataOffProfissional.nomeprofissional,
  //     });

  //     setSelectedOptionsLaboratorio([
  //       {
  //         label: userToken.estabelecimento_saude?.nome_fantasia,
  //         value: userToken.estabelecimentoId,
  //       },
  //     ]);
  //     setSelectedLaboratorio([
  //       {
  //         label: userToken.estabelecimento_saude?.nome_fantasia,
  //         value: userToken.estabelecimentoId,
  //       },
  //     ]);

  //     payload = {
  //       page: 1,
  //       size: 25,
  //       estados: 'PE',
  //       profissionalResultadoId: dataOffProfissional?.idprofissional,
  //       laboratorioLaudoId: userToken.estabelecimentoId,
  //     };

  //     setListFilterShow([
  //       {
  //         inputName: 'estado',
  //         value: 'Estado: PE',
  //       },
  //       {
  //         inputName: 'municipio',
  //         value: ``,
  //       },
  //       {
  //         inputName: 'laboratorio_responsavel',
  //         value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude?.nome_fantasia}`,
  //       },

  //       {
  //         inputName: 'profissional_responsavel',
  //         value: `Profissional Responsável pelo Laudo: ${userToken?.pessoa?.nome}`,
  //       },
  //     ]);

  //     setListFilter([
  //       { inputName: 'estado', value: 'Estado: PE' },
  //       {
  //         inputName: 'municipio',
  //         value: ``,
  //       },
  //       {
  //         inputName: 'laboratorio_responsavel',
  //         value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude?.nome_fantasia}`,
  //       },
  //       {
  //         inputName: 'profissional_responsavel',
  //         value: `Profissional Responsável pelo Laudo: ${dataOffProfissional?.pessoa?.nome}`,
  //       },
  //       { inputName: 'resultado', value: '' },
  //       { inputName: 'profissionalLaudo', value: '' },
  //       { inputName: 'data_ini', value: '' },
  //       { inputName: 'data_fin', value: '' },
  //       { inputName: 'search', value: '' },
  //     ]);
  //     resolve(payload);
  //   });
  // };

  // const setFiltersWhenAdminOrOthersLaboratorio = () => {
  //   setDisableInputEstado(true);
  //   setDisabledLaboratorioResultado(true);
  //   return new Promise(async (resolve) => {
  //     let payload = {};
  //     setEstadosSelected(['PE']);

  //     const optionsCidades = await getCidades('PE');

  //     if (optionsCidades.length > 0) {
  //       setOptionsMunicipios(optionsCidades);
  //       const municipio = await getMunicipioDetails(
  //         userToken.endereco?.municipio_nome
  //       );

  //       setMunicipiosSelected([municipio[0].id]);

  //       // setEstabelecimentosSelected([userToken.estabelecimentoId]);

  //       const labs = [
  //         {
  //           id: 1474,
  //           nome_fantasia: 'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA',
  //           cnes: 2712105,
  //           label: 'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA',
  //         },
  //         {
  //           id: 4691,
  //           nome_fantasia: 'US 376 POLICLINICA SALOMAO KELNER',
  //           cnes: 6897029,
  //           label: 'US 376 POLICLINICA SALOMAO KELNER',
  //         },
  //         {
  //           id: 158,
  //           nome_fantasia: 'US 144 POLICLINICA CLEMENTINO FRAGA',
  //           cnes: '0000647',
  //           label: 'US 376 POLICLINICA SALOMAO KELNER',
  //         },
  //         {
  //           id: 61,
  //           nome_fantasia: 'US 143 LABORATORIO MUNICIPAL DO RECIFE',
  //           cnes: '0000779',
  //           label: 'US 143 LABORATORIO MUNICIPAL DO RECIFE',
  //         },
  //       ] as any;

  //       const labFromUser = labs.find(
  //         (lab) => lab.id === userToken.estabelecimentoId
  //       );

  //       setSelectedOptionsLaboratorio(
  //         labs.filter((lab) => lab.id === userToken.estabelecimentoId)
  //       );
  //       setSelectedLaboratorio([
  //         { value: labFromUser.id, label: labFromUser.nome },
  //       ]);

  //       payload = {
  //         page: 1,
  //         size: 25,
  //         estados: 'PE',
  //         laboratorioLaudoId: userToken.estabelecimentoId,
  //       };

  //       setListFilterShow([
  //         {
  //           inputName: 'estado',
  //           value: 'Estado: PE',
  //         },
  //         {
  //           inputName: 'municipio',
  //           value: `Municipio: ${userToken.endereco?.municipio_nome}`,
  //         },
  //         {
  //           inputName: 'laboratorio_responsavel',
  //           value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude?.nome_fantasia}`,
  //         },
  //       ]);

  //       setListFilter([
  //         { inputName: 'estado', value: 'Estado: PE' },
  //         {
  //           inputName: 'municipio',
  //           value: `Municipio: ${userToken.endereco?.municipio_nome}`,
  //         },
  //         {
  //           inputName: 'laboratorio_responsavel',
  //           value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude?.nome_fantasia}`,
  //         },
  //         { inputName: 'resultado', value: '' },
  //         { inputName: 'profissional_responsavel', value: '' },
  //         { inputName: 'profissionalLaudo', value: '' },
  //         { inputName: 'data_ini', value: '' },
  //         { inputName: 'data_fin', value: '' },
  //         { inputName: 'search', value: '' },
  //       ]);
  //       resolve(payload);
  //     }
  //   });
  // };

  // const requestDataByUserPerfil = async () => {
  //   const userIsPadrao =
  //     userToken.permissao_atual?.id === 1 ||
  //     userToken.permissao_atual?.id === 6 ||
  //     userToken.permissao_atual?.id === 7 ||
  //     userToken.permissao_atual?.id === 8 ||
  //     userToken.permissao_atual?.id === 9 ||
  //     userToken.permissao_atual?.id === 10;
  //   if (userIsPadrao) {
  //     if (!isUserLaboratorio) {
  //       setFiltersWhenPadrao(userToken).then((payload) => {
  //         getReportsResultadosCitologia(payload);
  //       });
  //     } else {
  //       if (isMedicoLaboratorio) {
  //         setFiltersWhenMedicoLaboratorio().then((payload) => {
  //           getReportsResultadosCitologia(payload);
  //         });
  //       }

  //       if (isAdminLaboratorio || isOutrosLaboratorio) {
  //         setFiltersWhenAdminOrOthersLaboratorio();
  //       }
  //     }
  //   }

  //   if (userToken.permissao_atual?.id === 3) {
  //     setFiltersWhenMunicipal(userToken).then((payload) => {
  //       getReportsResultadosCitologia(payload);
  //     });
  //   }

  //   if (userToken.permissao_atual?.id === 4) {
  //     setFiltersWhenEstadual();
  //   }

  //   if (userToken.permissao_atual?.id === 2) {
  //     // setLoading(false);
  //   }

  //   return true;
  // };

  // useEffect(() => {
  //   if (estadosSelected.length === 1) {
  //     setDisabledMunicipioInput(false);
  //   } else {
  //     setDisabledMunicipioInput(true);
  //   }
  // }, [estadosSelected]);

  // useEffect(() => {
  //   if (municipiosSelected.length === 1) {
  //     setDisabledEstabelecimentoInput(false);
  //   } else {
  //     setDisabledEstabelecimentoInput(true);
  //   }
  // }, [municipiosSelected]);

  // useEffect(() => {
  //   setLoading(true);
  //   if (origin === 'tables') {
  //     setFiltersToRequestByTable().then((payloadToRequest) => {
  //       getReportsResultadosCitologia(payloadToRequest);
  //     });
  //   } else {
  //     requestDataByUserPerfil();
  //   }
  // }, [origin]);

  // useEffect(() => {
  //   setLoading(true);
  //   if (location.state) {
  //     const { payload } = location.state;
  //     if (payload && payload.params) {
  //       setFiltersFromPayload(payload.params);
  //       getReportsResultadosCitologia(payload.params);
  //     }
  //   } else {
  //     requestDataByUserPerfil();
  //   }
  // }, [location.state]);

  // const setFiltersFromPayload = (params) => {
  //   setEstadosSelected(params.estado ? params.estado.split(',') : []);
  //   setMunicipiosSelected(
  //     params.municipioId ? params.municipioId.split(',') : []
  //   );
  //   setEstabelecimentosSelected(
  //     params.estabelecimentoId ? params.estabelecimentoId.split(',') : []
  //   );
  //   setDataColetaIni(params.periodo_inicio);
  //   setDataColetaFinal(params.periodo_fim);

  //   if (params.resultado) {
  //     setSelectedResultado([
  //       { label: params.resultado, value: params.resultado },
  //     ]);
  //   }

  //   if (params.celula_atipica) {
  //     handleSetFilter(
  //       `Células Atípicas: ${params.celula_atipica}`,
  //       'celula_atipica'
  //     );
  //   }

  //   if (params.celula_glandular) {
  //     handleSetFilter(
  //       `Células Glandulares: ${params.celula_glandular}`,
  //       'celula_glandular'
  //     );
  //   }

  //   if (params.celula_escamosa) {
  //     handleSetFilter(
  //       `Células Escamosas: ${params.celula_escamosa}`,
  //       'celula_escamosa'
  //     );
  //   }
  // };

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton backPage="/home" />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography variant="h4" fontWeight="bold">
                Relatório Geral de Exames de Citologia - Reflexo
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
                sx={{ marginBottom: '24px' }}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/home"
                >
                  Home
                </Link>
                <Typography color="text.primary">Relatórios</Typography>

                <Typography key="3" color="text.primary">
                  Relatório Geral de Exames de Citologia - Reflexo
                </Typography>
              </Breadcrumbs>
            </div>
          </div>

          <Divider />
        </Box>

        <Box mt={5}>
          <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '30px', fontSize: '24px' }}
            >
              Filtros
            </Typography>
            <Divider />
            <p
              style={{
                fontSize: '12px',
                textAlign: 'right',
                color: '#d32f2f',
                margin: '0',
                marginTop: '20px',
                // marginBottom: '-20px',
              }}
            >
              <i>* Campo obrigatório</i>
            </p>

            <DisplayFilter style={{ marginTop: '24px' }}>
              {!listFilterShow?.find((item) => item.value !== '') && (
                <p style={{ margin: 0, width: '413px', color: '#838383' }}>
                  Os filtros selecionados aparecerão aqui.
                </p>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                  gap: '10px',
                  flexWrap: 'wrap',
                }}
              >
                {listFilterShow.map((item) => (
                  <>
                    {item.value ? (
                      <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                    ) : undefined}
                  </>
                ))}
              </div>
            </DisplayFilter>

            <FiltersReportsCitologiaReflexo
              initialStateListFilter={initialStateListFilter}
              setLoadingTable={setLoadingTable}
              errors={errors}
              clearErrors={clearErrors}
              disabledLaboratorioResultado={disabledLaboratorioResultado}
              disabledProfissionalResultado={disabledProfissionalResultado}
              selectedOptionsLaboratorio={selectedOptionsLaboratorio}
              setSelectedOptionsLaboratorio={setSelectedOptionsLaboratorio}
              disableMunicipioInput={disableMunicipioInput}
              disableEstabelecimentoInput={disableEstabelecimentoInput}
              disableInputEstado={disableInputEstado}
              showTableData={showTableData}
              setListFilterShow={setListFilterShow}
              selectedResultado={selectedResultado}
              setTableData={setTableData}
              watch={watch}
              setError={setError}
              setShowTableData={setShowTableData}
              dtColetaFinal={dtColetaFinal}
              setPeriod={setPeriod}
              dtColetaIni={dtColetaIni}
              setDataColetaFinal={setDataColetaFinal}
              setDataColetaIni={setDataColetaIni}
              setSelectedResultado={setSelectedResultado}
              handleSubmit={handleSubmit}
              setValue={setValue}
              control={control}
              estadosSelected={estadosSelected}
              setEstadosSelected={setEstadosSelected}
              municipiosSelected={municipiosSelected}
              setMunicipiosSelected={setMunicipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              setEstabelecimentosSelected={setEstabelecimentosSelected}
              period={period}
              listFilter={listFilter}
              setListFilter={setListFilter}
              selectedLaboratorio={selectedLaboratorio}
              setSelectedLaboratorio={setSelectedLaboratorio}
            />

            <TableReportsCitologiaReflexo
              loadingTable={loadingTable}
              setLoadingTable={setLoadingTable}
              tableData={tableData}
              setValue={setValue}
              watch={watch}
              setTableData={setTableData}
              estadosSelected={estadosSelected}
              municipiosSelected={municipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              equipesSelected={equipesSelected}
              showTableData={showTableData}
            />
          </Paper>
        </Box>
      </Container>
    </SideMenu>
  );
};
