import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { ButtonIcon } from '../../../../../components/ModalStatus/ModalStatus.style';
import CloseIcon from '@mui/icons-material/Close';
import { SeuTipoDeRegistro } from './AgendadaModal';

interface INaoElegivelModalProps {
  open: boolean;
  onClose: () => void;
  registro: SeuTipoDeRegistro;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 577,
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 24,
  padding: '16px 24px',
};

function NaoElegivel({ open, onClose, registro }: INaoElegivelModalProps) {
  const nome = registro?.pessoa?.nome;
  const histerectomizada = registro?.pessoa?.conv_histerectomizada;
  const inativo_sexo = registro?.pessoa?.conv_inativo_sexo;
  const fora_idade = registro?.pessoa?.conv_fora_idade;
  const outros_motivos = registro?.pessoa?.conv_outros;

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={onClose}
    >
      <Box sx={style}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h6"
            fontWeight="bold"
          >
            Busca ativa
          </Typography>
          <ButtonIcon onClick={onClose}>
            <CloseIcon fontSize="small" style={{ color: '#BDBDBD' }} />
          </ButtonIcon>
        </Box>
        <Box
          style={{
            border: '1px solid #bdbdbd',
            borderRadius: '8px',
            padding: '16px',
            marginBottom: '20px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
            <img src={'/exams.svg'} alt="exams" width={50} />
            <Typography fontWeight={700} fontSize={20}>
              {nome}
            </Typography>
          </Box>
          <Divider style={{ marginTop: 10 }} />
          <Typography fontWeight={400} fontSize={16} mt={2} mb={0}>
            Resultados da convocação <span style={{ color: '#f88078' }}>*</span>
          </Typography>
          <FormControl>
            <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
              <FormControlLabel
                style={{ marginRight: '14px' }}
                value="sim"
                disabled
                control={<Radio style={{ fontSize: '14px', padding: '3px' }} />}
                label={<Typography fontSize={16}>Agendada</Typography>}
              />
              <FormControlLabel
                value="natentida"
                control={<Radio />}
                disabled
                label={<Typography fontSize={16}>Não atendida</Typography>}
              />
              <FormControlLabel
                disabled
                checked
                value="ninelegivel"
                control={<Radio />}
                label={<Typography fontSize={16}>Não inelegível</Typography>}
              />
            </RadioGroup>
          </FormControl>
          <div>
            <Typography fontWeight={400} fontSize={16} mt={2} mb={0}>
              Motivo <span style={{ color: '#f88078' }}>*</span>
            </Typography>
            <FormControl>
              <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel
                  style={{ marginRight: '' }}
                  disabled
                  checked={inativo_sexo === 'sim' || inativo_sexo === null}
                  control={
                    <Radio style={{ fontSize: '14px', padding: '3px' }} />
                  }
                  label={
                    <Typography fontSize={16}>
                      Não iniciou atividade sexual
                    </Typography>
                  }
                />
                <FormControlLabel
                  disabled
                  checked={fora_idade === 'sim'}
                  value={fora_idade}
                  control={
                    <Radio style={{ fontSize: '14px', padding: '3px' }} />
                  }
                  label={
                    <Typography fontSize={16}>Fora da faixa-etária</Typography>
                  }
                />
                <FormControlLabel
                  style={{ marginRight: '13px' }}
                  disabled
                  checked={histerectomizada === 'sim'}
                  value={histerectomizada}
                  control={
                    <Radio style={{ fontSize: '14px', padding: '3px' }} />
                  }
                  label={
                    <Typography fontSize={16}>
                      Histerectomizada por patologia benigna
                    </Typography>
                  }
                />
                <FormControlLabel
                  style={{ marginLeft: '0px' }}
                  disabled
                  checked={outros_motivos === 'sim'}
                  value={outros_motivos}
                  control={
                    <Radio style={{ fontSize: '14px', padding: '3px' }} />
                  }
                  label={<Typography fontSize={16}>Outros</Typography>}
                />
              </RadioGroup>
            </FormControl>
            <TextField
              multiline
              style={{ marginTop: '28px' }}
              sx={{ width: '100%' }}
              label="Observação adicional"
            />
          </div>
        </Box>
        <Box></Box>
      </Box>
    </Modal>
  );
}

export default NaoElegivel;
