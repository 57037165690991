import { Box, Grid, Typography } from '@mui/material';
import { AreaChart } from '../components/areaChart';
import styles from './styles.module.css';
import { ChartCard } from '../components/chartCard';
import { AgeRangeChart } from '../components/ageRangeChart';
import { ExamCollectionOrigin } from '../components/examCollectionOrigin';
import { ExamResultsChart } from '../components/examResultsChart';
import { DetectableAndVirusType } from '../components/detectableAndVirusType';
import { ReasonsNotProcedureChart } from '../components/reasonsNotProcedureChart';
import { useForm } from 'react-hook-form';
import FiltersVisaoGeral from '../components/filters/FilterVisaoGeral';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';
import { useContext, useEffect, useState } from 'react';
import api from '../../../../api';
import ErrorsTotals from '../components/ErrorsTotals';
import { getUserToken } from '../../../../lib/auth';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import CardStatusResults from '../components/CardsStatusResults';
import CardPendingResults from '../components/CardPendingResults';
import { RealizationProcedurePosNotRealized } from '../components/RealizationProcedurePosNotRealized';
import { LoadingContext } from '../../../../context/LoadingContext';
import useStatesFields from '../../../../components/MultipleFilters/hooks/useStatesFields';
const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento_saude', value: '' },
  { inputName: 'equipe', value: '' },
  { inputName: 'periodo_inicio', value: '' },
  { inputName: 'periodo_fim', value: '' },
];

export function VisaoGeral() {
  const [needsRequiredFilters, setNeedsRequiredFilters] = useState(true);
  const [listFilter, setListFilter] = useState<any>(initialStateListFilter);
  const [showListFilter, setShowListFilter] = useState<any>([]);
  const [estadosSelected, setEstadosSelected] = useState<any[]>([]);
  const [municipiosSelected, setMunicipiosSelected] = useState<any>([]);
  const [estabelecimentosSelected, setEstabelecimentosSelected] = useState<any>(
    []
  );
  const [valuePeriodoModal, setValuePeriodoModal] = useState<any>({
    // label: 'Todos',
    // value: 'all',
  });
  const [nameEstabelecimentoSelected, setNameEstabelecimentoSelected] =
    useState('');
  const [nameMunicipioSelected, setNameMunicipioSelected] = useState('');
  const { control } = useForm();
  const [valueInputPeriod, setValueInputPeriod] = useState<any>();
  const [period, setPeriod] = useState<any>();

  const [periodToDisplay, setPeriodToDisplay] = useState('Todos os períodos');

  const [totalTestsPerMonth, setTotalTestsPerMonth] = useState<any>([]);

  const [categories, setCategories] = useState<any>(['NÃO HÁ DADOS']);
  const [ageRangeChartData, setageRangeChartData] = useState<any>([]);
  const [examResultsChartData, setExamResultsChartData] = useState<any>([]);
  const [examCollectionOriginData, setExamCollectionOriginData] = useState<any>(
    []
  );
  const [countLoading, setCountLoading] = useState(0);
  const [filtersData, setFiltersData] = useState<any>();
  const [cardsDataGal, setCardsDataGal] = useState<any>({});

  const {
    optionsMunicipios,
    setEstadosOptions,
    setOptionsMunicipios,
    setOptionsEstabelecimentos,
  } = useStatesFields();
  const userToken = getUserToken();

  const isMunicipal = userToken.permissao_atual?.id === 3;
  const isEstadual = userToken.permissao_atual?.id === 4;
  const isSuperAdmin = userToken.permissao_atual?.nome === 'SuperAdmin';
  const isAdminEstabelecimento = userToken.permissao_atual?.id === 6;

  const [payload, setPayload] = useState<any>();

  const [total, setTotal] = useState<any>();
  const [detectableAndVirusTypeData, setdetectableAndVirusTypeData] =
    useState<any>([0, 0, 0]);
  const [reasonsNotProcedureChartData, setreasonsNotProcedureChartData] =
    useState<any>([0, 0, 0, 0, 0]);

  const [realizedPosNotRealized, setRealizedPosNotRealized] = useState<any>([]);

  const [caardsQuantityNotifys, setCardsQuantityNotifys] = useState<any>({});

  const { setLoading } = useContext(LoadingContext);
  const getTestesPerMonth = async (cleared, payload) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/quantity-month',
      payload
    );

    const data = response.data.data.data;

    if (!Array.isArray(data) || data.length === 0) {
      setCategories(['NÃO HÁ DADOS']);
    } else {
      setCategories(
        response.data?.data.map((item, index) => {
          if (index === 0 || item?.month === 'Jan') {
            return [
              `${String(item?.month)?.toUpperCase()}`,
              `${String(item?.year)}`,
            ];
          } else {
            return String(item?.month).toUpperCase();
          }
        })
      );
    }

    setTotalTestsPerMonth(
      response.data?.data.map((item) => Number(item?.count))
    );

    setFiltersData(response.data.filters);

    setCountLoading((prev) => prev + 1);
  };

  const getDataCardsGal = async () => {
    try {
      const response = await api.get(
        '/dashboard/exames/hpv/panel/quantity-gal'
      );

      setCardsDataGal(response.data);
    } catch (error) {}
    setCountLoading((prev) => prev + 1);
  };
  const getDataQuantityNotifys = async () => {
    try {
      const response = await api.get(
        '/dashboard/exames/hpv/panel/quantity-notifys'
      );

      setCardsQuantityNotifys(response.data);
    } catch (error) {}
    setCountLoading((prev) => prev + 1);
  };

  const getAgeRange = async (cleared, payload) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/faixa',
      payload
    );

    setageRangeChartData([
      response.data?.data?.find((d) => d.faixa === '25 A 34')?.count || 0,
      response?.data?.data?.find((d) => d.faixa === '35 A 45')?.count || 0,
      response.data?.data?.find((d) => d.faixa === '46 A 64')?.count || 0,
      response.data?.data?.find((d) => d.faixa === 'FORA DA FAIXA')?.count || 0,
    ]);
    setCountLoading((prev) => prev + 1);
  };

  const getexamCollectionOrigin = async (cleared, payload) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/natureza',
      payload
    );

    setExamCollectionOriginData([
      response.data.data.autocoleta ? Number(response.data.data.autocoleta) : 0,
      response.data.data.profissional
        ? Number(response.data.data.profissional)
        : 0,
    ]);
    setCountLoading((prev) => prev + 1);
  };

  const getExamResultsChart = async (cleared, payload) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/resultados',
      payload
    );

    setExamResultsChartData([
      response.data.data.NEGATIVO_INDETECTADO
        ? Number(response.data.data.NEGATIVO_INDETECTADO)
        : 0,
      response.data.data.POSITIVO ? Number(response.data.data.POSITIVO) : 0,
      response.data.data.INVALIDO ? Number(response.data.data.INVALIDO) : 0,
    ]);
    setCountLoading((prev) => prev + 1);
  };

  const getdetectableAndVirusTypeData = async (cleared, payload) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/resultados-tipos',
      payload
    );

    setdetectableAndVirusTypeData([
      response.data.data.hpv_16 ? Number(response.data.data.hpv_16) : 0,
      response.data.data.hpv_18 ? Number(response.data.data.hpv_18) : 0,
      response.data.data.hpv_outros ? Number(response.data.data.hpv_outros) : 0,
    ]);
    setCountLoading((prev) => prev + 1);
  };

  const getreasonsNotProcedure = async (cleared, payload) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/nao-realizados',
      payload
    );

    setreasonsNotProcedureChartData([
      response.data.data.NAO_REALIZOU
        ? Number(response.data.data.NAO_REALIZOU)
        : 0,

      response.data.data.MENSTRUADA ? Number(response.data.data.MENSTRUADA) : 0,
      response.data.data.NAO_FAIXA_ETARIA
        ? Number(response.data.data.NAO_FAIXA_ETARIA)
        : 0,
      response.data.data.HISTERECTOMIZADA
        ? Number(response.data.data.HISTERECTOMIZADA)
        : 0,
      response.data.data.OUTRO ? Number(response.data.data.OUTRO) : 0,
    ]);
    setCountLoading((prev) => prev + 1);
  };
  const getRealizedPosNotRealized = async (cleared, payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/hpv/panel/nao-realizados/pos-realizados',
        payload
      );

      const objRealizedPosNotRealized = {
        NAO_REALIZOU: {
          realizado: response.data?.data['NAO_REALIZOU']?.realizado ?? 0,
          nao_realizado:
            response.data?.data['NAO_REALIZOU']?.nao_realizado ?? 0,
          total: response.data?.data['NAO_REALIZOU']?.total ?? 0,
        },
        MENSTRUADA: {
          realizado: response.data?.data['MENSTRUADA']?.realizado ?? 0,
          nao_realizado: response.data?.data['MENSTRUADA']?.nao_realizado ?? 0,
          total: response.data?.data['NAO_REALIZOU']?.total ?? 0,
        },
        NAO_FAIXA_ETARIA: {
          realizado: response.data?.data['NAO_FAIXA_ETARIA']?.realizado ?? 0,
          nao_realizado:
            response.data?.data['NAO_FAIXA_ETARIA']?.realizado ?? 0,
          total: response.data?.data['NAO_FAIXA_ETARIA']?.total ?? 0,
        },
        HISTERECTOMIZADA: {
          realizado: response.data?.data['HISTERECTOMIZADA']?.realizado ?? 0,
          nao_realizado:
            response.data?.data['HISTERECTOMIZADA']?.nao_realizado ?? 0,
          total: response.data?.data['HISTERECTOMIZADA']?.total ?? 0,
        },
        OUTRO: {
          realizado: response.data?.data['OUTRO']?.realizado ?? 0,
          nao_realizado: response.data?.data['OUTRO']?.nao_realizado ?? 0,
          total: response.data?.data['OUTRO']?.total ?? 0,
        },
      };

      setRealizedPosNotRealized(objRealizedPosNotRealized);
    } catch (error) {}
    setCountLoading((prev) => prev + 1);
  };

  useEffect(() => {
    setLoading(true);

    if (countLoading >= 9) {
      setLoading(false);
    }
  }, [countLoading]);

  const navigate = useNavigate();

  const redirectToReports = () => {
    localStorage.removeItem('result');
    localStorage.removeItem('typeVirus');
    localStorage.removeItem('reasonNotProcedure');
    localStorage.removeItem('collectionOrigin');
    localStorage.removeItem('collectionOrigin');
    localStorage.removeItem('chartName');
    //return
    navigate('/relatorios/hpv', {
      state: {
        origin: 'tables',
        table: 'totalValue',
        payload: payload,
        panelClicked: 'totalTests',
      },
    });
  };

  const getAllCharts = ({ cleared, payload }) => {
    setNeedsRequiredFilters(false);
    setCountLoading(0);
    localStorage.setItem('coleta_inicio', payload?.periodo_inicio);
    localStorage.setItem('coleta_fim', payload?.periodo_fim);
    setTotalTestsPerMonth([]);
    // setCategories([]);
    setageRangeChartData([]);
    setExamResultsChartData([]);
    setExamCollectionOriginData([]);
    setTotal('');
    setdetectableAndVirusTypeData([]);
    setreasonsNotProcedureChartData([]);

    getDataCardsGal();
    getDataQuantityNotifys();

    getTestesPerMonth(cleared, payload);
    getAgeRange(cleared, payload);
    getexamCollectionOrigin(cleared, payload);
    getExamResultsChart(cleared, payload);
    getdetectableAndVirusTypeData(cleared, payload);
    getreasonsNotProcedure(cleared, payload);
    getRealizedPosNotRealized(cleared, payload);
  };

  const setDefaultEstado = ({ sigla }) => {
    setEstadosOptions([
      {
        sigla: sigla,
      },
    ]);
    setEstadosSelected([sigla]);
  };

  const setDefaultMunicipio = ({ municipioId, municipioNome }) => {
    setOptionsMunicipios({
      id: municipioId,
      nome: municipioNome,
    });
    setMunicipiosSelected([municipioId]);
  };

  const setDefaultPeriod = () => {};

  useEffect(() => {
    const today = moment().format('YYYY-MM-DD');
    const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');

    if (isSuperAdmin) {
      setCountLoading(9);
      return;
    }

    if (isEstadual) {
      const listFilter = [
        {
          inputName: 'estado',
          value: `Estados: ${userToken.estabelecimento_saude?.endereco.municipio.estado.sigla}`,
        },
        {
          inputName: 'municipio',
          value: ``,
        },
        { inputName: 'estabelecimento_saude', value: '' },
        { inputName: 'equipe', value: '' },
        { inputName: 'periodo_inicio', value: 'Período: Todos os períodos' },
        { inputName: 'periodo_fim', value: '' },
      ];

      setListFilter(listFilter);

      setDefaultEstado({
        sigla: userToken.estabelecimento_saude?.endereco.municipio.estado.sigla,
      });

      setCountLoading(9);
    }

    if (isMunicipal) {
      const listFilter = [
        {
          inputName: 'estado',
          value: `Estados: ${userToken.estabelecimento_saude?.endereco.municipio.estado.sigla}`,
        },
        {
          inputName: 'municipio',
          value: `Município: ${userToken.estabelecimento_saude?.endereco.municipio.nome}`,
        },
        {
          inputName: 'estabelecimento_saude',
          value: 'Estabelecimento de Saúde: Vários',
        },
        { inputName: 'equipe', value: '' },
        { inputName: 'periodo_inicio', value: 'Período: Todos os períodos' },
        { inputName: 'periodo_fim', value: '' },
      ];

      setListFilter(listFilter);
      setShowListFilter(listFilter);
      setDefaultEstado({
        sigla: userToken.estabelecimento_saude?.endereco.municipio.estado.sigla,
      });
      setDefaultMunicipio({
        municipioId: String(
          userToken.estabelecimento_saude?.endereco.municipio.id
        ),
        municipioNome: userToken.estabelecimento_saude?.endereco.municipio.nome,
      });

      setValueInputPeriod('Todos');
      setValuePeriodoModal({
        label: 'Todos',
        value: 'all',
      });
      setPeriod({ periodo_inicio: '2022-01-01' });

      getAllCharts({
        cleared: false,
        payload: {
          params: {
            ...payload?.params,
            estados:
              userToken.estabelecimento_saude?.endereco.municipio.estado.sigla,
            municipios: userToken.estabelecimento_saude?.endereco.municipio.id,
          },
        },
      });
    }

    if (isAdminEstabelecimento) {
      const listFilter = [
        {
          inputName: 'estado',
          value: `Estados: ${userToken.estabelecimento_saude?.endereco.municipio.estado.sigla}`,
        },
        {
          inputName: 'municipio',
          value: ``,
        },
        { inputName: 'estabelecimento_saude', value: '' },
        { inputName: 'equipe', value: '' },
        { inputName: 'periodo_inicio', value: 'Período: Todos os períodos' },
        { inputName: 'periodo_fim', value: '' },
      ];

      setListFilter(listFilter);
      // setShowListFilter(listFilter);

      setDefaultEstado({
        sigla: userToken.estabelecimento_saude?.endereco.municipio.estado.sigla,
      });

      // getAllCharts({
      //   cleared: false,
      //   payload: {
      //     params: {
      //       ...payload?.params,
      //       estados:
      //         userToken.estabelecimento_saude?.endereco.municipio.estado.sigla,
      //     },
      //   },
      // });

      setCountLoading(9);
    }
  }, []);

  useEffect(() => {
    if (totalTestsPerMonth.length > 0) {
      setTotal(totalTestsPerMonth.reduce((prev, curr) => prev + curr, 0));
    } else {
      setTotal(null);
    }
  }, [totalTestsPerMonth]);

  useEffect(() => {
    if (!userToken.permissoes.includes('VIEW_DASHBOARD_HPV')) {
      navigate('/home');
    }
  }, []);

  return (
    <Box mt="31px">
      <Box sx={{ marginBottom: '42px' }}>
        <FiltersVisaoGeral
          period={period}
          setPeriod={setPeriod}
          valuePeriodoModal={valuePeriodoModal}
          setValuePeriodoModal={setValuePeriodoModal}
          setNeedsRequiredFilters={setNeedsRequiredFilters}
          showListFilter={showListFilter}
          // setNameEstabelecimentoSelected={setNameEstabelecimentoSelected}
          // setNameMunicipioSelected={setNameMunicipioSelected}
          setCardsDataGal={setCardsDataGal}
          setCardsQuantityNotifys={setCardsQuantityNotifys}
          setRealizedPosNotRealized={setRealizedPosNotRealized}
          setTotalTestsPerMonth={setTotalTestsPerMonth}
          setageRangeChartData={setageRangeChartData}
          setExamResultsChartData={setExamResultsChartData}
          setExamCollectionOriginData={setExamCollectionOriginData}
          setTotal={setTotal}
          setdetectableAndVirusTypeData={setdetectableAndVirusTypeData}
          setreasonsNotProcedureChartData={setreasonsNotProcedureChartData}
          setPeriodToDisplay={setPeriodToDisplay}
          payload={payload}
          valueInputPeriod={valueInputPeriod}
          setValueInputPeriod={setValueInputPeriod}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          setEstadosSelected={setEstadosSelected}
          setMunicipiosSelected={setMunicipiosSelected}
          municipiosSelected={municipiosSelected}
          estadosSelected={estadosSelected}
          estabelecimentosSelected={estabelecimentosSelected}
          control={control}
          setShowListFilter={setShowListFilter}
          setPayload={setPayload}
          getAllCharts={getAllCharts}
          setListFilter={setListFilter}
          initialStateListFilter={initialStateListFilter}
          listFilter={listFilter}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="2px">
        <Typography variant="h5" fontWeight="bold" fontSize="24px">
          Testes de HPV
        </Typography>
        <Typography variant="h6" fontWeight="bold" fontSize="20px">
          {periodToDisplay}
        </Typography>

        <DisplayFilter style={{ marginTop: '17px', marginBottom: '37px' }}>
          <p style={{ margin: 0, width: '138px' }}>Filtros aplicados:</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              gap: '10px',
              flexWrap: 'wrap',
            }}
          >
            {showListFilter.map((item) => (
              <>
                {item.value ? (
                  <>
                    <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                  </>
                ) : undefined}
              </>
            ))}
          </div>
        </DisplayFilter>

        {/* <div className={styles.filtersInfo}>
          Filtros aplicados: Estado: Todos - Município: Todos - Estabelecimento:
          Todos - Equipe: Todas - Período: Últimos 12 meses.
        </div> */}
      </Box>

      <div className={styles.testsDoneCard}>
        <div className={styles.testsDoneCardTitle}>
          <Typography variant="h5" fontWeight="bold">
            Testes de HPV <br />
            Quantidade de Testes Realizados
          </Typography>

          <p style={{ margin: 0, fontSize: '12px', color: '#9E9E9E' }}>
            {valueInputPeriod === 'Todos' || !valueInputPeriod
              ? 'Todos os períodos'
              : valueInputPeriod}
          </p>

          <Typography
            variant="h5"
            fontWeight="bold"
            style={{ cursor: 'pointer' }}
            onClick={() => redirectToReports()}
          >
            {total ? total?.toLocaleString('pt-BR') : ''}
          </Typography>
        </div>

        <AreaChart
          data={totalTestsPerMonth}
          categories={categories}
          payload={payload}
          total={total}
          needsRequiredFilters={needsRequiredFilters}
        />
        <p
          style={{
            marginBottom: '16px',
            marginRight: '16px',
            bottom: '0',
            right: '0',
            position: 'absolute',
            fontSize: '10px',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          Fonte dos Dados: JORDANA
        </p>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ChartCard
            title="Teste de HPV"
            subtitle="Resultados dos Testes"
            valueInputPeriod={valueInputPeriod}
          >
            <ExamResultsChart
              data={examResultsChartData}
              payload={payload}
              needsRequiredFilters={needsRequiredFilters}
            />
          </ChartCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartCard
            title="Teste de HPV"
            subtitle="Detectável x Tipo de Vírus"
            valueInputPeriod={valueInputPeriod}
          >
            <DetectableAndVirusType
              data={detectableAndVirusTypeData}
              payload={payload}
              needsRequiredFilters={needsRequiredFilters}
            />
          </ChartCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartCard
            title="Teste de HPV"
            subtitle="Quantidade de Testes Realizados por Faixa Etária"
            valueInputPeriod={valueInputPeriod}
          >
            <AgeRangeChart
              data={ageRangeChartData}
              payload={payload}
              needsRequiredFilters={needsRequiredFilters}
            />
          </ChartCard>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          gap="16px"
          display="flex"
          flexDirection="column"
        >
          <CardPendingResults
            subtitle="Pendência de Resultados por Divergência de Dados*"
            dateDatatest="date_mismatch_hpv"
            titleDatatest="title_mismatch_hpv"
            valueDatatest="value_mismatch_hpv"
            total={caardsQuantityNotifys?.MISMATCH_HPV_COLETA?.value}
            date={caardsQuantityNotifys?.MISMATCH_HPV_COLETA?.updated_at}
            needsRequiredFilters={needsRequiredFilters}
          />
          <CardPendingResults
            dateDatatest="date_missing_hpv"
            titleDatatest="title_missing_hpv"
            valueDatatest="value_missing_hpv"
            subtitle="Pendência de Resultados por Inexistência de Dados*"
            total={caardsQuantityNotifys?.MISSING_HPV_EXAM?.value}
            date={caardsQuantityNotifys?.MISSING_HPV_EXAM?.updated_at}
            needsRequiredFilters={needsRequiredFilters}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <ChartCard
            title="Teste de HPV"
            subtitle="Natureza da Coleta de Exames"
          >
            <ExamCollectionOrigin
              data={examCollectionOriginData}
              payload={payload}
            />
          </ChartCard>
        </Grid> */}

        <Grid item xs={12} md={6}>
          <ChartCard
            valueInputPeriod={valueInputPeriod}
            title="Teste de HPV"
            subtitle="Motivos para a Não Realização do Procedimento"
          >
            <ReasonsNotProcedureChart
              data={reasonsNotProcedureChartData}
              payload={payload}
              needsRequiredFilters={needsRequiredFilters}
            />
          </ChartCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartCard
            valueInputPeriod={valueInputPeriod}
            title="Teste de HPV"
            subtitle="Realização do Procedimento (Pós-Não Realização)"
            titleDatatest="title_hpv_realized_pos_not_realized"
          >
            <RealizationProcedurePosNotRealized
              data={realizedPosNotRealized}
              payload={payload}
              needsRequiredFilters={needsRequiredFilters}
            />
          </ChartCard>
        </Grid>

        {['SuperAdmin', 'Admin Estadual', 'Admin Estabelecimento'].includes(
          userToken.permissao_atual?.nome
        ) && (
          <>
            <CardStatusResults
              titleDatatest="title_waiting"
              dateDatatest="date_waiting"
              valueDatatest="value_waiting"
              color="brown"
              status="Aguardando Triagem"
              total={cardsDataGal?.triagem?.value}
              updatedAt={cardsDataGal?.triagem?.updated_at}
            />

            <CardStatusResults
              titleDatatest="title_analise"
              dateDatatest="date_analise"
              valueDatatest="value_analise"
              color="green"
              status="Em Análise"
              total={cardsDataGal?.analize?.value}
              updatedAt={cardsDataGal?.analize?.updated_at}
            />

            <CardStatusResults
              titleDatatest="title_results"
              dateDatatest="date_results"
              valueDatatest="value_results"
              color="lightGreen"
              status="Resultados Liberados"
              total={cardsDataGal?.liberados?.value}
              updatedAt={cardsDataGal?.liberados?.updated_at}
            />

            {/* <Grid item xs={12} md={6}>
          <ErrorsTotals />
        </Grid> */}
          </>
        )}
      </Grid>

      {['SuperAdmin', 'Admin Estadual', 'Admin Estabelecimento'].includes(
        userToken.permissao_atual?.nome
      ) && (
        <p
          style={{
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 0.38)',
            width: '100%',
            textAlign: 'right',
            marginTop: '24px',
            marginBottom: '40px',
          }}
        >
          * O quantitativo é atualizado por automatização do sistema com o GAL
        </p>
      )}
    </Box>
  );
}
