import { Collapse, Paper } from '@mui/material';
// import ScheduleItens from './ScheduleItens';
// import OhterConvocationItens from './OtherConvocationsItens';

const DetailCanceled = () => {
  const convocations = [
    {
      acs: 'Gustavo Brigido',
      resultConvocation: {
        status: 'Agendada',
        activeSearch: '2025-01-07T14:00:00Z',
      },
    },
  ];

  const schedules = [
    {
      wantSchedule: {
        is: true,
        when: '2025-01-07T14:00:00Z',
      },
      confirmed: true,
    },
  ];

  const hpv = [
    {
      present: true,
      receivedResult: '2025-01-07T14:00:00Z',
    },
  ];
  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '276px',
        flexDirection: 'row',
        width: '100%',
        borderColor: 'rgba(0, 0, 0, 0.23);',
        borderRadius: '10px',
        padding: '32px',
        marginBottom: '-12.5px',
      }}
    >
      {/* <OhterConvocationItens convocations={convocations} />
      <ScheduleItens schedules={schedules} /> */}
    </Paper>
  );
};

export default DetailCanceled;
