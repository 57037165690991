import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import { Controller } from 'react-hook-form';
import { StyledAsteriskTextField } from '../../../../../components/StyledAsteriskTextField/styles';
import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment';
import { getCidades } from '../../../../../lib/getCidadesEstabelecimentos';
import api from '../../../../../api';
import MultipleFilters from '../../../../../components/MultipleFilters';
import useStatesFields from '../../../../../components/MultipleFilters/hooks/useStatesFields';
import { getUserToken } from '../../../../../lib/auth';

const FiltersVisaoGeral = ({
  control,
  setPayload,
  payload,
  getAllCharts,
  setListFilter,
  initialStateListFilter,
  setShowListFilter,
  listFilter,
  estabelecimentosSelected,
  estadosSelected,
  municipiosSelected,

  setEstabelecimentosSelected,
  setEstadosSelected,
  setMunicipiosSelected,
  valueInputPeriod,
  setValueInputPeriod,
  setPeriodToDisplay,
  setTotalTestsPerMonth,
  setageRangeChartData,
  setExamResultsChartData,
  setExamCollectionOriginData,
  setTotal,
  setdetectableAndVirusTypeData,
  setreasonsNotProcedureChartData,
  setRealizedPosNotRealized,
  setCardsQuantityNotifys,
  setCardsDataGal,
  setNeedsRequiredFilters,
  valuePeriodoModal,
  setValuePeriodoModal,
  // setNameEstabelecimentoSelected,
  // setNameMunicipioSelected,

  showListFilter,
  period,
  setPeriod,
}) => {
  const [openModalPeriodo, setOpenModalPeriodo] = useState(false);
  const [year, setYear] = useState<any>('');
  const [endDayAndMonth, setEndDayAndMonth] = useState<any>();
  const [initialDayAndMonth, setInitialDayAndMonth] = useState<any>();

  const [valueInputQuadrimestreSemestre, setValueInputQuadrimestreSemestre] =
    useState<any>();

  const [firstLoadPage, setFirstLoadPage] = useState(true);

  const {
    acsSelected,
    arrayAcs,
    searchAcs,
    allAcsOptions,
    anchorElAcs,
    disableAcsInput,

    setAnchorElAcs,
    setArrayAcs,
    setAcsSelected,
    setAllAcsOptions,
    setDisableAcsInput,

    anchorElEstados,
    searchEstado,
    estadosOptions,
    allEstadosOptions,

    setAnchorElEstados,
    setSearchEstado,
    setEstadosOptions,
    setAllEstadosOptions,

    allMunicipiosOptions,
    searchMunicipio,
    anchorElMunicipios,
    optionsMunicipios,
    disableMunicipioInput,

    setAllMunicipiosOptions,
    setSearchMunicipio,
    setAnchorElMunicipios,
    setOptionsMunicipios,
    setDisabledMunicipioInput,

    optionsEstabelecimentos,
    allEstabelecimentosOptions,
    searchEstabelecimentos,
    anchorElEstabelecimentos,
    disableEstabelecimentoInput,

    setOptionsEstabelecimentos,
    setAllEstabelecimentosOptions,
    setSearchEstabelecimentos,
    setAnchorEstabelecimentos,
    setDisabledEstabelecimentoInput,

    allEquipesOptions,
    searchEquipes,
    anchorElEquipe,
    arrayEquipes,
    equipesSelected,
    disableEquipeInput,

    setAllEquipesOptions,
    setSearchEquipes,
    setAnchorElEquipe,
    setArrayEquipes,
    setEquipesSelected,
    setDisableEquipeInput,
    disableInputEstado,

    errorsFields,
    setErrorsFields,
  } = useStatesFields();

  const optionsPeriodo = [
    { label: 'Todos', value: 'all' },

    {
      label: 'Últimos 12 meses',
      value: 'twelve_months',
    },
    {
      label: 'Quadrimestral',
      value: 'four_months',
    },
    { label: 'Semestral', value: 'six_months' },
    { label: 'Anual', value: 'yearly' },
  ];

  const optionsQuadrimestre = [
    '1º Quadrimestre',
    '2º Quadrimestre',
    '3º Quadrimestre',
  ];
  const optionsSemestre = ['1º Semestre', '2º Semestre'];

  const setValuesInLastTwelveMonths = () => {
    setValueInputPeriod('Últimos 12 meses');
    setOpenModalPeriodo(false);
    const today = moment().format('YYYY-MM-DD');
    const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');

    setPeriod({
      periodo_fim: today,
      periodo_inicio: twelveMonths,
    });
  };

  useEffect(() => {
    if (period && period.periodo_inicio && period.periodo_fim) {
      handleSetFilter(
        moment(period.periodo_inicio, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        'periodo_inicio'
      );
      handleSetFilter(
        moment(period.periodo_fim, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        'periodo_fim'
      );
    } else {
      handleSetFilter('Período: Todos os períodos', 'periodo_inicio');
      handleSetFilter('', 'periodo_fim');
    }
  }, [period]);

  useEffect(() => {
    if (estabelecimentosSelected.length === 0) {
      handleSetFilter(
        'Estabelecimento de Saúde: Vários',
        'estabelecimento_saude'
      );
    }
  }, [estabelecimentosSelected]);

  const setValuesQuadrimestreSelected = () => {
    setValueInputPeriod('Quadrimestral');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-04-01');
    // setValueInputQuadrimestreSemestre('1º Quadrimestre');
    setPeriod(undefined);
  };

  const setValuesSemestreSelected = () => {
    setValueInputPeriod('Semestral');
    // setValueInputQuadrimestreSemestre('1º Semestre');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-06-01');
    setPeriod(undefined);
  };

  const setValuesAnualSelected = () => {
    setValueInputPeriod('Anual');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-12-01');

    setPeriod(undefined);
  };

  const setValuesAllPeriodSelected = () => {
    setValueInputPeriod('Todos');

    setPeriod({ periodo_inicio: '2022-01-01' });
  };

  const handleSelectPeriodModal = (newValue) => {
    setValuePeriodoModal(newValue);
    if (!newValue) {
      setPeriod(undefined);
      setYear(undefined);
      setValueInputPeriod(null);
    }
    switch (newValue?.value) {
      case 'twelve_months':
        setValuesInLastTwelveMonths();
        break;
      case 'four_months':
        setValuesQuadrimestreSelected();
        break;
      case 'six_months':
        setValuesSemestreSelected();

        break;
      case 'yearly':
        setValuesAnualSelected();
        break;
      case 'all':
        setValuesAllPeriodSelected();
        break;
      default:
        break;
    }
  };

  const validateInputsPeriod = () => {
    return new Promise((resolve, reject) => {
      if (valuePeriodoModal?.value === 'yearly' && !year) {
        resolve(false);
      }

      if (
        (valuePeriodoModal?.value === 'four_months' ||
          valuePeriodoModal?.value === 'six_months') &&
        (!valueInputQuadrimestreSemestre || !year)
      ) {
        resolve(false);
      }

      resolve(true);
    });
  };

  const handleClearFilter = () => {
    // setValuesAllPeriodSelected();
    setNeedsRequiredFilters(true);
    setPeriod(null);
    setValueInputPeriod(null);
    setValuePeriodoModal(null);
    setValueInputQuadrimestreSemestre(null);
    setYear(null);

    setPeriodToDisplay('');

    setTotalTestsPerMonth([]);
    setageRangeChartData([]);
    setExamResultsChartData([]);
    setExamCollectionOriginData([]);
    setTotal('');
    setdetectableAndVirusTypeData([0, 0, 0]);
    setreasonsNotProcedureChartData([0, 0, 0, 0, 0]);
    setRealizedPosNotRealized([]);
    setCardsQuantityNotifys({});
    setCardsDataGal({});

    // if (isSuperAdmin)

    setEstabelecimentosSelected([]);

    if (!isMunicipal) {
      setMunicipiosSelected([]);
      setShowListFilter([]);
    }
    if (isEstadual || isAdminEstabelecimento) {
      const listFilter = [
        {
          inputName: 'estado',
          value: `Estados: ${userToken.estabelecimento_saude?.endereco.municipio.estado.sigla}`,
        },
        {
          inputName: 'municipio',
          value: ``,
        },
        { inputName: 'estabelecimento_saude', value: '' },
        { inputName: 'equipe', value: '' },
        { inputName: 'periodo_inicio', value: '' },
        { inputName: 'periodo_fim', value: '' },
      ];

      setListFilter(listFilter);
    }

    if (isMunicipal) {
      const listFilter = [
        {
          inputName: 'estado',
          value: `Estados: ${userToken.estabelecimento_saude?.endereco.municipio.estado.sigla}`,
        },
        {
          inputName: 'municipio',
          value: `Município: ${userToken.estabelecimento_saude?.endereco.municipio.nome}`,
        },
        { inputName: 'estabelecimento_saude', value: '' },
        { inputName: 'equipe', value: '' },
        { inputName: 'periodo_inicio', value: '' },
        { inputName: 'periodo_fim', value: '' },
      ];

      setListFilter(listFilter);
      setShowListFilter(listFilter);
      getAllCharts({
        cleared: false,
        payload: {
          params: {},
        },
      });
    }

    if (isSuperAdmin) {
      setEstadosSelected([]);
    }
    setSearchEquipes('');
    setSearchEstabelecimentos('');
    setSearchEstado('');
    setSearchMunicipio('');
  };

  const userToken = getUserToken();
  const isAdminEstabelecimento = userToken.permissao_atual?.id === 6;
  const isMunicipal = userToken.permissao_atual?.id === 3;
  const isEstadual = userToken.permissao_atual?.id === 4;
  const isSuperAdmin = userToken.permissao_atual?.nome === 'SuperAdmin';

  const handleChangeWhenYearSelected = (newValue) => {
    setYear(newValue);
    // if (valueInputPeriod === 'Quadrimestral') {
    //   setValueInputQuadrimestreSemestre('1º Quadrimestre');
    // }

    // if (valueInputPeriod === 'Semestral') {
    //   setValueInputQuadrimestreSemestre('1º Semestre');
    // }
    setValueInputQuadrimestreSemestre('');
    if (valueInputPeriod === 'Anual') {
      setPeriod({
        periodo_fim: `${newValue}-12-31`,
        periodo_inicio: `${newValue}-01-01`,
      });
    }
  };

  const handleChangeSemestreOrQuadrimestre = (newValue) => {
    setValueInputQuadrimestreSemestre(newValue);
    switch (newValue) {
      case '1º Semestre':
        setPeriod({
          periodo_fim: `${year}-06-01`,
          periodo_inicio: `${year}-01-01`,
        });
        break;
      case '2º Semestre':
        setPeriod({
          periodo_fim: `${year}-12-01`,
          periodo_inicio: `${year}-06-01`,
        });
        break;
      case '1º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-05-01`,
          periodo_inicio: `${year}-01-01`,
        });
        break;
      case '2º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-09-01`,
          periodo_inicio: `${year}-05-01`,
        });
        break;
      case '3º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-12-31`,
          periodo_inicio: `${year}-09-01`,
        });
        break;
      default:
        break;
    }
  };

  const handleCloseModal = () => {
    setPeriod(null);
    // handleSetFilter(null, 'periodo');
    setOpenModalPeriodo(false);
    setPeriod(null);
    setYear(null);
    setValueInputPeriod(null);
    setEndDayAndMonth(null);
    setInitialDayAndMonth(null);
    setValuePeriodoModal(null);
    setValueInputQuadrimestreSemestre(null);
  };

  const handleSetFilter = (newValue: any, inputName: string) => {
    setListFilter((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const handleSetFiltersInMultiplesInputs = ({
    value,
    fieldName,
    arrayValues,
  }) => {
    const convertedFieldNames = {
      estado: 'Estados',
      municipio: 'Municípios',
      estabelecimento_saude: 'Estabelecimentos',
      equipe: 'Equipes',
    };
    if (arrayValues.length > 1) {
      handleSetFilter(`${convertedFieldNames[fieldName]}: Vários`, fieldName);
    } else {
      handleSetFilter(`${convertedFieldNames[fieldName]}: ${value}`, fieldName);
    }
  };

  const getSearch = () => {
    getAllCharts({ cleared: false, payload: payload });
    setShowListFilter(listFilter);

    setPeriodToDisplay(valueInputPeriod);
  };

  const verifyIfHasPeriodSelected = () => {
    if (!period) {
      setValuesAllPeriodSelected();
      setValuePeriodoModal({
        label: 'Todos',
        value: 'all',
      });
    }
  };

  const verifyIfHasEstabelecimentoSelected = () => {
    if (estabelecimentosSelected.length === 0) {
      const arrIds = optionsEstabelecimentos.map((estab) => estab.id);

      setEstabelecimentosSelected(arrIds);

      return arrIds;
    }
  };

  const handleSearch = () => {
    const selectedMoreThanOneEstado = estadosSelected.length > 1;
    const selectedAllEstados = estadosSelected.length >= estadosOptions.length;
    const emptyEstado = estadosSelected.length === 0;
    const emptyMunicipio = municipiosSelected.length === 0;

    if (isSuperAdmin) {
      if (emptyEstado) {
        setErrorsFields((prev) => [...prev, 'uf']);
      }

      if (
        emptyMunicipio &&
        (!selectedAllEstados || !selectedMoreThanOneEstado)
      ) {
        setErrorsFields((prev) => [...prev, 'municipio']);
      }

      if (
        !emptyEstado &&
        (!emptyMunicipio || selectedAllEstados || selectedMoreThanOneEstado)
      ) {
        setErrorsFields([]);
        verifyIfHasPeriodSelected();
        verifyIfHasEstabelecimentoSelected();
        getSearch();
      }
    }

    if (isEstadual) {
      if (emptyMunicipio) {
        setErrorsFields((prev) => [...prev, 'municipio']);
      } else {
        setErrorsFields([]);
        verifyIfHasPeriodSelected();
        verifyIfHasEstabelecimentoSelected();

        getSearch();
      }
    }

    if (isMunicipal) {
      setErrorsFields([]);
      verifyIfHasPeriodSelected();
      verifyIfHasEstabelecimentoSelected();

      getSearch();
    }

    if (isAdminEstabelecimento) {
      if (emptyMunicipio) {
        setErrorsFields((prev) => [...prev, 'municipio']);
      } else {
        setErrorsFields([]);
        verifyIfHasPeriodSelected();
        verifyIfHasEstabelecimentoSelected();

        getSearch();
      }
    }
  };

  useEffect(() => {
    if (equipesSelected.length > 0) {
      handleSetFiltersInMultiplesInputs({
        value: equipesSelected[0] ?? '',
        fieldName: 'equipe',
        arrayValues: equipesSelected,
      });
    } else {
      handleSetFilter(null, 'equipe');
    }
  }, [equipesSelected]);

  useEffect(() => {
    const getEquipes = async () => {
      const hasSelectedNoBind = !!estabelecimentosSelected.find(
        (item) => item === 'null'
      );
      if (estabelecimentosSelected.length === 1) {
        if (!hasSelectedNoBind) {
          const response = await api.get('/equipe/estabelecimento/get', {
            params: {
              estabelecimentoId: estabelecimentosSelected[0],
            },
          });
          setArrayEquipes([
            {
              id: 'null',
              municipio: '',
              nome: 'SEM VINCULAÇÃO',
              quantidade: '',
              label: '',
            },
            ...response.data,
          ]);
          setAllEquipesOptions([
            {
              id: 'null',
              municipio: '',
              nome: 'SEM VINCULAÇÃO',
              quantidade: '',
              label: '',
            },
            ...response.data,
          ]);
        }
      }

      if (estabelecimentosSelected.length === 0) {
        setArrayEquipes([]);
      }
    };

    getEquipes();
  }, [estabelecimentosSelected]);

  useEffect(() => {
    const loadEstados = async () => {
      try {
        const response = await api.get('/estados/hpv');
        const estados = response.data;
        setEstadosOptions(estados.filter((estado) => !estado.has_null));
        setAllEstadosOptions(estados.filter((estado) => !estado.has_null));
      } catch (error) {
        console.error('Erro ao carregar estados:', error);
        // setEstadosLoadError(true);
      }
    };

    if (isSuperAdmin) {
      loadEstados();
    }
  }, []);

  useEffect(() => {
    setPayload({
      params: {
        estados: !isSuperAdmin
          ? 'PE'
          : estadosSelected.length > 0
          ? estadosSelected.join(', ')
          : undefined,
        municipios: isMunicipal
          ? userToken.endereco?.municipio_id
          : municipiosSelected.length > 0
          ? municipiosSelected.join(', ')
          : undefined,
        estabelecimentos:
          estabelecimentosSelected.length > 0
            ? estabelecimentosSelected.join(', ')
            : undefined,
        equipe:
          equipesSelected.length > 0 ? equipesSelected.join(', ') : undefined,
        periodo_inicio: period?.periodo_inicio
          ? period.periodo_inicio
          : undefined,
        periodo_fim: period?.periodo_fim ? period.periodo_fim : undefined,
      },
    });
  }, [
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    equipesSelected,
    period,
  ]);

  useEffect(() => {
    const getOptionsCidades = async () => {
      if (estadosSelected.length === 1 && !isMunicipal) {
        const optionsCidades = await getCidades(estadosSelected[0]);
        setOptionsMunicipios(optionsCidades);
        setAllMunicipiosOptions(optionsCidades);
      }

      if (estadosSelected.length === 0) {
        // setOptionsMunicipios([]);
      }
    };

    getOptionsCidades();
  }, [estadosSelected]);

  useEffect(() => {
    const getOptionsEstabelecimentos = async () => {
      if (municipiosSelected.length === 1) {
        const optionsEstabelecimentos = await api.get(
          '/dashboard/exames/hpv/panel/estabelecimentos',
          {
            params: {
              estados:
                estadosSelected.length > 0
                  ? JSON.stringify(estadosSelected)
                  : undefined,
              municipios:
                municipiosSelected.length > 0
                  ? JSON.stringify(municipiosSelected)
                  : undefined,
            },
          }
        );

        setOptionsEstabelecimentos([
          {
            id: 'null',
            municipio: '',
            nome: 'SEM VINCULAÇÃO',
            quantidade: '',
            label: '',
          },
          ...optionsEstabelecimentos.data,
        ]);

        setAllEstabelecimentosOptions([
          {
            id: 'null',
            municipio: '',
            nome: 'SEM VINCULAÇÃO',
            quantidade: '',
            label: '',
          },
          ...optionsEstabelecimentos.data,
        ]);

        if (firstLoadPage) {
          setFirstLoadPage(false);
          const estabelecimentosIds = optionsEstabelecimentos.data.map(
            (estab) => estab.id
          );

          setEstabelecimentosSelected([...estabelecimentosIds, 'null']);
        }
      }

      if (municipiosSelected.length === 0) {
        setOptionsEstabelecimentos([]);
      }
    };

    getOptionsEstabelecimentos();
  }, [municipiosSelected]);

  // useEffect(() => {
  //   if (isAdminEstabelecimento || isMunicipal || isEstadual) {
  //     setDisableInputEstado(true);
  //   }
  //   const today = moment().format('YYYY-MM-DD');
  //   const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');
  //   setTimeout(() => {
  //     setPeriod({
  //       periodo_fim: today,
  //       periodo_inicio: twelveMonths,
  //     });
  //     getAllCharts({
  //       cleared: false,
  //       payload: {
  //         params: {
  //           ...payload,
  //           periodo_fim: today,
  //           periodo_inicio: twelveMonths,
  //         },
  //       },
  //     });
  //   }, 110);
  // }, []);

  return (
    <>
      <Dialog
        open={openModalPeriodo}
        onClose={() => setOpenModalPeriodo(false)}
      >
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            Tipo de Período
          </Typography>
          <DialogContent sx={{ padding: '0', marginTop: '30px' }}>
            <Paper
              variant="outlined"
              sx={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                Selecione o Período para Visualização dos Dados
              </Typography>
              <Autocomplete
                placeholder="Tipo de Período"
                value={valuePeriodoModal}
                onChange={(_, newValue: any) => {
                  handleSelectPeriodModal(newValue);
                }}
                options={optionsPeriodo}
                getOptionLabel={(option) => option.label ?? ''}
                renderInput={(params) => (
                  <StyledAsteriskTextField
                    {...params}
                    fullWidth
                    label="Tipo de Período"
                    variant="outlined"
                  />
                )}
              />
              {valueInputPeriod === 'Últimos 12 meses' ||
              valueInputPeriod === 'Todos' ||
              !valueInputPeriod ? undefined : (
                <Autocomplete
                  options={['2024', '2023', '2022']}
                  onChange={(_, newValue) => {
                    handleChangeWhenYearSelected(newValue);
                  }}
                  value={year ? year : ''}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecione o Ano" />
                  )}
                />
              )}
              {}

              {(valueInputPeriod === 'Quadrimestral' ||
                valueInputPeriod === 'Semestral') && (
                <Autocomplete
                  disabled={!year}
                  options={
                    valueInputPeriod === 'Quadrimestral'
                      ? optionsQuadrimestre
                      : optionsSemestre
                  }
                  value={
                    valueInputQuadrimestreSemestre
                      ? valueInputQuadrimestreSemestre
                      : ''
                  }
                  onChange={(_, newValue) => {
                    handleChangeSemestreOrQuadrimestre(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={!year}
                      label={
                        valueInputPeriod === 'Quadrimestral'
                          ? 'Selecione o Quadrimestre'
                          : 'Selecione o Semestre'
                      }
                    />
                  )}
                />
              )}
            </Paper>
          </DialogContent>
        </DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              handleCloseModal();
            }}
            startIcon={<ClearIcon />}
          >
            CANCELAR
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              const validated = await validateInputsPeriod();

              if (validated) {
                setOpenModalPeriodo(false);
              }
            }}
            endIcon={<CheckIcon />}
          >
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <MultipleFilters
          requiredMunicipio={estadosSelected.length > 1 ? false : true}
          setFirstLoadPage={setFirstLoadPage}
          routeGetEstabelecimentos="/dashboard/rastreamento/hpv/estabelecimentos"
          disableInputEstado={disableInputEstado}
          handleSetFilter={handleSetFilter}
          mdEstado={2.6}
          mdMunicipio={2.6}
          mdEstabelecimento={2.6}
          mdEquipe={2}
          // fieldEquipe
          acsSelected={acsSelected}
          arrayAcs={arrayAcs}
          searchAcs={searchAcs}
          allAcsOptions={allAcsOptions}
          setAllAcsOptions={setAllAcsOptions}
          anchorElAcs={anchorElAcs}
          disableAcsInput={disableAcsInput}
          setAnchorElAcs={setAnchorElAcs}
          setArrayAcs={setArrayAcs}
          setSearchAcs={setArrayAcs}
          setAcsSelected={setAcsSelected}
          anchorElEstados={anchorElEstados}
          searchEstado={searchEstado}
          estadosOptions={estadosOptions}
          allEstadosOptions={allEstadosOptions}
          estadosSelected={estadosSelected}
          setAnchorElEstados={setAnchorElEstados}
          setSearchEstado={setSearchEstado}
          setEstadosOptions={setEstadosOptions}
          setAllEstadosOptions={setAllEstadosOptions}
          setEstadosSelected={setEstadosSelected}
          allMunicipiosOptions={allMunicipiosOptions}
          searchMunicipio={searchMunicipio}
          anchorElMunicipios={anchorElMunicipios}
          municipiosSelected={municipiosSelected}
          optionsMunicipios={optionsMunicipios}
          disableMunicipioInput={disableMunicipioInput}
          setAllMunicipiosOptions={setAllMunicipiosOptions}
          setSearchMunicipio={setSearchMunicipio}
          setAnchorElMunicipios={setAnchorElMunicipios}
          setMunicipiosSelected={setMunicipiosSelected}
          setOptionsMunicipios={setOptionsMunicipios}
          optionsEstabelecimentos={optionsEstabelecimentos}
          allEstabelecimentosOptions={allEstabelecimentosOptions}
          searchEstabelecimentos={searchEstabelecimentos}
          anchorElEstabelecimentos={anchorElEstabelecimentos}
          estabelecimentosSelected={estabelecimentosSelected}
          disableEstabelecimentoInput={disableEstabelecimentoInput}
          setOptionsEstabelecimentos={setOptionsEstabelecimentos}
          setAllEstabelecimentosOptions={setAllEstabelecimentosOptions}
          setSearchEstabelecimentos={setSearchEstabelecimentos}
          setAnchorEstabelecimentos={setAnchorEstabelecimentos}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          allEquipesOptions={allEquipesOptions}
          searchEquipes={searchEquipes}
          anchorElEquipe={anchorElEquipe}
          arrayEquipes={arrayEquipes}
          equipesSelected={equipesSelected}
          disableEquipeInput={disableEquipeInput}
          setAllEquipesOptions={setAllEquipesOptions}
          setSearchEquipes={setSearchEquipes}
          setAnchorElEquipe={setAnchorElEquipe}
          setArrayEquipes={setArrayEquipes}
          setEquipesSelected={setEquipesSelected}
          setDisabledMunicipioInput={setDisabledMunicipioInput}
          setDisabledEstabelecimentoInput={setDisabledEstabelecimentoInput}
          setDisableEquipeInput={setDisableEquipeInput}
          setDisableAcsInput={setDisableAcsInput}
          errorsFields={errorsFields}
          setErrorsFields={setErrorsFields}
        />
        <Grid item xs={12} md={2}>
          <Controller
            name="periodo"
            control={control}
            render={({}) => {
              return (
                <StyledAsteriskTextField
                  data-testid="periodoInput"
                  fullWidth
                  size="small"
                  label="Período"
                  value={valueInputPeriod ? valueInputPeriod : ''}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ padding: 0 }}
                        onClick={() => setOpenModalPeriodo(true)}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                    ),
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} lg={1}>
          <Button
            variant="contained"
            data-testid="submitFilterPanelHpv"
            onClick={() => {
              handleSearch();
            }}
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={12} lg={1}>
          <Button
            data-testid="clearFilterPanelHpv"
            variant="outlined"
            color="error"
            onClick={() => {
              setNeedsRequiredFilters(true);
              handleClearFilter();
            }}
          >
            Limpar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FiltersVisaoGeral;
