import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import { PieChart } from '../pieChart';

interface WomenOutsideGroupProps {
  data: number[];
  categories?: String[];
  height?: number;
}

export function WomenOutsideGroup({ data }: WomenOutsideGroupProps) {
  const [chartData, setChartData] = useState<ApexOptions>();
  useEffect(() => {
    setChartData({
      series: data,
      chart: {
        width: 284,
        type: 'pie',
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['rgba(0,0,0,0.7)'],
        },
        background: {
          enabled: true,
          foreColor: '#ffffff',
          borderRadius: 6,
          borderColor: 'transparent',
        },
      },
      labels: [
        `Fora da faixa etária (${data[0]})`,
        `Dentro da faixa etária (${data[1]})`,
      ],
      responsive: [
        {
          breakpoint: 768,
          options: {
            legend: {
              position: 'bottom',
              offsetY: 0,
              horizontalAlign: 'left',
              width: 300,
            },
          },
        },
      ],
      colors: ['#F4284E', '#D9E4E5'],
      yaxis: {
        min: 0,
      },
      legend: {
        position: 'right',
        offsetY: 100,
        width: 160,
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: 16,
            minAngleToShowLabel: 1,
          },
        },
      },
    });
  }, [data]);

  return (
    <>
      {chartData ? <PieChart chartData={chartData} height={284} /> : undefined}
    </>
  );
}
