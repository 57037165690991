import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ICardProps {
  borderLeftColor: string;
}

export const CardDetailPatient = styled(Box)`
  width: 100%;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  padding: 32px 32px 32px 24px;
  margin-bottom: 24px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    width: 16px;
    height: 100%;
    border-radius: 10px;
    border-left: ${({ borderLeftColor }: ICardProps) =>
      borderLeftColor
        ? `8px solid ${borderLeftColor}`
        : '8px solid rgba(0, 0, 0, 0.38)'};
  }

  .card-date {
    flex: 1;
  }

  .card-type {
    flex: 2;
  }

  .card-status {
    flex: 1;
  }
`;
