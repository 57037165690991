import {
  Box,
  Breadcrumbs,
  Container,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { TabPanel } from '../Panels/hpv';
import ReturnButton from '../../components/ReturnButton';
import { SideMenu } from '../../components/SideMenu';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';
import ListConvocation from './tabs/List';
import GeneralView from './tabs/GeneralView';
import { Historic } from './tabs/Historic';

const Convocation = () => {
  const [tabSize, setTabSize] = useState('33%');

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <>
      <SideMenu>
        <Container>
          <Box mt={12}>
            <ReturnButton backPage="/home" />
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '30px' }}
            >
              Convocatória
            </Typography>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              style={{ marginBottom: '16px', marginTop: '16px' }}
              sx={{ marginBottom: '24px' }}
            >
              <Link
                style={{
                  textDecoration: 'none',
                  color: '#646464',
                }}
                to="/home"
              >
                Home
              </Link>

              <Typography color="text.primary">{'Convocatória'}</Typography>
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              marginTop: '25px',
            }}
          >
            <Tabs
              orientation={window.innerWidth < 768 ? 'vertical' : 'horizontal'}
              variant="scrollable"
              sx={{
                backgroundColor: '#ffffff',
                color: '#ffffff',

                '& .MuiTab-root': {
                  color: 'white',
                  maxWidth: `${window.innerWidth < 768 ? '100%' : '33%'}`,
                  backgroundColor: '#238884',
                },

                '&& .Mui-selected': {
                  backgroundColor: '#016460',
                  color: '#FFFFFF !important',
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: '#238884',
                  color: '#FFFFFF',
                },
              }}
              value={value}
              onChange={handleChange}
            >
              <Tab
                sx={{ width: tabSize }}
                label="VISÃO GERAL"
                {...a11yProps(0)}
              />
              <Tab sx={{ width: tabSize }} label="LISTAGEM" {...a11yProps(1)} />
              <Tab
                sx={{ width: tabSize }}
                // disabled
                label="HISTÓRICO"
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <GeneralView />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ListConvocation />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Historic />
          </TabPanel>
        </Container>
      </SideMenu>
    </>
  );
};

export default Convocation;
