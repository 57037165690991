import { Button, Grid, Paper } from '@mui/material';
import { SelectWithSearch } from '../../../../../components/SelectWithSearch';
import SearchByNameInput from '../../../../../components/Inputs/SearchByNameInput';
import { DisplayFilter } from '../../List/styles';

export const FiltersHistoric = ({ handleSetFilter, control }) => {
  const handleSetSelectedOptions =
    ({ setSelected, fieldName }) =>
    (options) => {
      setSelected(options);
      const optionLabel = () => {
        if (options.length === 0) {
          return '';
        }

        if (options.length === 1) {
          return `${fieldName}: ${options[0].label}`;
        }

        if (options.length > 1) {
          return `${fieldName}: Vários`;
        }
      };
      handleSetFilter(optionLabel(), 'resultado');
    };

  return (
    <form>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={4}>
          <SelectWithSearch
            // requestOptionsBySearch={(text) => {
            //   getProfissionaisList({ bySearchInput: false, nome: text });
            // }}
            loading={false}
            options={[]}
            isLaboratorio
            label="Estado"
            disabled={false}
            selectedOptions={[]}
            setSelectedOptions={() => null}
            setSelected={handleSetSelectedOptions({
              setSelected: () => null,
              fieldName: 'estado',
            })}
            dataTestIdModal="modalLaboratorioLaudoInput"
            dataTestIdInputSearch="inputSearchLaboratorioLaudo"
            dataTestSelectAll="selectAllLaboratorioLaudo"
            dataTestClearAll="clearLaboratorioLaudo"
            // emptyCondition={estabelecimentosSelected.length !== 1}
            // emptyMessage="Selecione um Estabelecimento de Saúde"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectWithSearch
            // requestOptionsBySearch={(text) => {
            //   getProfissionaisList({ bySearchInput: false, nome: text });
            // }}
            loading={false}
            options={[]}
            isLaboratorio
            label="Município"
            disabled={false}
            selectedOptions={[]}
            setSelectedOptions={() => null}
            setSelected={handleSetSelectedOptions({
              setSelected: () => null,
              fieldName: 'municipio',
            })}
            dataTestIdModal="modalLaboratorioLaudoInput"
            dataTestIdInputSearch="inputSearchLaboratorioLaudo"
            dataTestSelectAll="selectAllLaboratorioLaudo"
            dataTestClearAll="clearLaboratorioLaudo"
            // emptyCondition={estabelecimentosSelected.length !== 1}
            // emptyMessage="Selecione um Estabelecimento de Saúde"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectWithSearch
            // requestOptionsBySearch={(text) => {
            //   getProfissionaisList({ bySearchInput: false, nome: text });
            // }}
            loading={false}
            options={[]}
            isLaboratorio
            label="Estabelecimento"
            disabled={false}
            selectedOptions={[]}
            setSelectedOptions={() => null}
            setSelected={handleSetSelectedOptions({
              setSelected: () => null,
              fieldName: 'estabelecimento',
            })}
            dataTestIdModal="modalLaboratorioLaudoInput"
            dataTestIdInputSearch="inputSearchLaboratorioLaudo"
            dataTestSelectAll="selectAllLaboratorioLaudo"
            dataTestClearAll="clearLaboratorioLaudo"
            // emptyCondition={estabelecimentosSelected.length !== 1}
            // emptyMessage="Selecione um Estabelecimento de Saúde"
          />
        </Grid>
        <Grid item xs={9.83}>
          <SearchByNameInput
            control={control}
            errors={{}}
            handleSetFilter={handleSetFilter}
          />
        </Grid>
        <Grid item>
          <Button variant="contained">FILTRAR</Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="error">
            LIMPAR
          </Button>
        </Grid>
        <Grid item xs={12}>
          <DisplayFilter />
        </Grid>
      </Grid>
    </form>
  );
};
