import { TableCell, Typography } from '@mui/material';
import { getColorAndLabelStatus, ModalType, StatusEnum } from '../Rows';
import { useContext } from 'react';
import { PerfilContext } from '../../../../../../context/PerfilContext';
import CustomizedButton from '../../../../../../components/CustomizedButton';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddIcon from '@mui/icons-material/Add';

const FixedColumnConvocation = ({
  tableData,
  setModalAberto,
  setRegistroSelecionado,
}) => {
  const { hasPermission } = useContext(PerfilContext);

  const abrirModal = (status, registro) => {
    const modalTypes = Object.values(ModalType);

    if (modalTypes.includes(status)) {
      const modalType = ModalType[status];
      setModalAberto(modalType);
      setRegistroSelecionado(registro);
    } else {
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'absolute',
          right: '0',
          zIndex: '1',
          top: '0px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TableCell
            align="left"
            sx={{
              borderBottom: 'none',
              boxShadow: '-3px 0px 5px -1px rgba(0, 0, 0, 0.20)',
              backgroundColor: 'white',
              height: '74px',
              padding: '16px',
            }}
          >
            <Typography sx={{ fontSize: '14px' }} fontWeight="bold">
              STATUS
            </Typography>
          </TableCell>
          {tableData.rows.map((row) => {
            return (
              <>
                <TableCell
                  align="center"
                  sx={{
                    boxShadow: '-3px 0px 5px -1px rgba(0, 0, 0, 0.20)',
                    width: '162px',
                    padding: '16px',
                    height: '92px',
                    zIndex: '1',
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    color="white"
                    variant="body2"
                    sx={{
                      backgroundColor: getColorAndLabelStatus(row?.status)
                        .color,
                      borderRadius: '16px',
                      textAlign: 'center',
                      padding: '3px 6px',
                      // marginLeft: '14px',
                      // padding: '3px 15px',
                      // minWidth: '150px',
                      width: 'fit-content',
                      margin: '0 auto',
                    }}
                    fontSize="13px"
                  >
                    {getColorAndLabelStatus(row?.status).label}
                  </Typography>
                </TableCell>
              </>
            );
          })}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TableCell
            align="left"
            sx={{
              borderBottom: 'none',

              backgroundColor: 'white',
              height: '74px',
              padding: '16px',
            }}
          >
            <Typography
              sx={{ fontSize: '14px' }}
              fontWeight="bold"
            ></Typography>
          </TableCell>
          {tableData.rows.map((row) => {
            const labelButton = {
              0: 'AGENDADA',
              1: 'AGENDAR',
              2: 'MOTIVO',
              3: 'MOTIVO',
            };

            return (
              <>
                {((hasPermission('CREATE_CONVOCATORIA_BUSCA_ATIVA') &&
                  row?.status === StatusEnum.Pendente) ||
                  row?.status !== StatusEnum.Pendente) && (
                  <TableCell sx={{ backgroundColor: 'white', height: '92px' }}>
                    <CustomizedButton
                      variant="outlined"
                      onClick={() => abrirModal(row?.status, row)}
                      startIcon={
                        row?.status === StatusEnum.NaoElegivel ||
                        row?.status === StatusEnum.Agendada ||
                        row?.status === StatusEnum.NaoAtendida ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <AddIcon />
                        )
                      }
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: '13px',
                        padding: '4px 10px',
                      }}
                    >
                      {labelButton[row?.status]}
                    </CustomizedButton>
                  </TableCell>
                )}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FixedColumnConvocation;
