import { Box, Divider, Paper, Typography } from '@mui/material';
import FiltersConvocationGeneralView from './FiltersConvocationGeneralView';
import { useForm } from 'react-hook-form';
import DashboardConvocationGeneralView from './DashboardConvocationGeneralView';

const GeneralView = () => {
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      estado: [],
      municipio: [],
      estabelecimento: [],
      status: [],
      convocacao: [],
      page: 1,
      size: 1,
    },
  });

  const handleSetFilter = (newValue: any, inputName: string, origin?: any) => {
    // setListFilter((prev) => {
    //   return prev.map((item) =>
    //     item.inputName === inputName ? { ...item, value: newValue } : item
    //   );
    // });
  };

  return (
    <Box mt={5}>
      <FiltersConvocationGeneralView
        setValue={setValue}
        handleSubmit={handleSubmit}
        control={control}
        clearErrors={clearErrors}
        watch={watch}
        setError={setError}
        errors={errors}
        initialStateListFilter={null}
        listFilter={null}
        setListFilter={null}
        setListFilterShow={null}
        listFilterShow={null}
        setShowTableData={null}
        showTableData={null}
        handleSetFilter={handleSetFilter}
      />
      <DashboardConvocationGeneralView />
    </Box>
  );
};

export default GeneralView;
