import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

const HeadersTableConvocation = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento
            <br /> Vinculado
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento
            <br /> Vinculado
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento
            <br /> Vinculado
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento
            <br /> Vinculado
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento
            <br /> Vinculado
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento
            <br /> Vinculado
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento
            <br /> Vinculado
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento
            <br /> Vinculado
          </Typography>
        </TableCell>
        {/**--- */}
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '160px',
            }}
            fontWeight="bold"
          >
            Estabelecimento
            <br /> Vinculado
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{
              fontSize: '14px',
              width: '120px',
            }}
            fontWeight="bold"
          >
            Endereço da <br />
            Paciente
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography sx={{ fontSize: '14px' }} fontWeight="bold">
            {' '}
            ACS
            <br />
            Responsável
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography sx={{ fontSize: '14px' }} fontWeight="bold">
            Tipo de <br />
            Convocação
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography sx={{ fontSize: '14px' }} fontWeight="bold">
            Data da
            <br /> Convocação
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: 'none' }}>
          <Typography
            sx={{ fontSize: '14px', width: '160px' }}
            fontWeight="bold"
          >
            Data do <br /> Agendamento
          </Typography>
        </TableCell>
        {/* <TableCell
          align="left"
          sx={{
            borderBottom: 'none',
            boxShadow: '-3px 0px 5px -1px rgba(0, 0, 0, 0.20)',
            position: 'sticky',
          }}
        >
          <Typography sx={{ fontSize: '14px' }} fontWeight="bold">
            STATUS
          </Typography>
        </TableCell> */}
        <TableCell align="right" sx={{ borderBottom: 'none' }}></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default HeadersTableConvocation;
