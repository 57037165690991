import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import MultipleFilters from '../../../../../components/MultipleFilters';
import { SelectWithSearchV2 } from '../../../../../components/SelectWithSearchV2';
import { DisplayFilter } from '../styles';
import { Controller, useForm } from 'react-hook-form';
import PeriodoInput from '../../../../../components/Inputs/PeriodoInput';
import SearchByNameInput from '../../../../../components/Inputs/SearchByNameInput';
import { validateSearch } from '../../../../../utils/validateSearchInput';
import { useContext, useEffect, useState } from 'react';
import useSetFilterByPerfilV2 from '../../../../../utils/hooks/useSetFilterByPerfilV2';
import { getUserToken } from '../../../../../lib/auth';
import useActionsControlInputs from '../../../../../utils/hooks/useActionsControlInputs';
import { PerfilContext } from '../../../../../context/PerfilContext';
// import { PerfilContext } from '../../../../../context/PerfilContext';

const FiltersConvocation = ({
  handleSubmit,
  control,
  clearErrors,
  setError,
  watch,
  errors,
  setValue,
  initialStateListFilter,
  listFilter,
  setListFilter,
  setListFilterShow,
  listFilterShow,
  setShowTableData,
  showTableData,
}) => {
  const [disabledEstadoInput, setDisabledEstadoInput] = useState(false);
  const [disabledMunicipioInput, setDisabledMunicipioInput] = useState(false);
  const [disabledEstabelecimentoInput, setDisabledEstabelecimentoInput] =
    useState(false);

  const [optionsEstados, setOptionsEstados] = useState([]);
  const [optionsMunicipios, setOptionsMunicipios] = useState([]);
  const [optionsEstabelecimentos, setOptionsEstabelecimentos] = useState([]);
  const loadConvocation = () => {};
  const userToken = getUserToken();

  const handleSetFilter = (newValue: any, inputName: string) => {
    setListFilter((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const { isSuperAdmin, isMunicipal, isPadraoEstabelecimento } =
    useContext(PerfilContext);
  const {
    actionsEstadoSelected,
    actionsMunicipioSelected,
    actionsEstabelecimentoSelected,
  } = useActionsControlInputs({
    estadosSelected: watch('estado'),
    municipiosSelected: watch('municipio'),
    estabelecimentosSelected: watch('estabelecimento'),

    setValue,

    setDisabledEstadoInput,
    setDisabledMunicipioInput,
    setDisabledEstabelecimentoInput,

    handleSetFilter,
  });

  const { setFiltersByPerfil } = useSetFilterByPerfilV2({
    loadReports: loadConvocation,
    setListFilter: setListFilter,
    setListFilterShow: setListFilterShow,
    // setSelectedOptionsLaboratorio: setSelectedOptionsLaboratorio,
    setShowTableData: setShowTableData,
    // setEstadosSelected,
    // setMunicipiosSelected,
    // setEstabelecimentosSelected,
    setOptionsMunicipios,
    setOptionsEstados,
    setOptionsEstabelecimentos,
    // setOptionsLaboratorios: () => {
    //   return;
    // },
    setValue,
    // setOptionsProfisisonaisLaboratorio,
    fieldProfissionalResultado: 'profissionalResultadoId',
    fieldLaboratorioResultado: 'estabelecimentoLaudoId',
    setDisabledEstadoInput,
    setDisabledMunicipioInput,
    setDisabledEstabelecimentoInput,

    // selectedLaboratorio,
    // setSelectedLaboratorio,
  });

  useEffect(() => {
    setFiltersByPerfil();
  }, []);

  const submitForm = () => {
    const searchNameCpfCnsIsValid = validateSearch({
      clearErrors,
      setError,
      watch,
    });

    if (searchNameCpfCnsIsValid) {
      // setLoading(true);
      // setDataCentral([]);
      // setPayload().then((payload) => loadDataCentralNotification(payload));
      // setListFilterShow(listFilter);
    }
  };

  useEffect(() => {
    actionsEstadoSelected();
  }, [watch('estado'), isSuperAdmin, isMunicipal, isPadraoEstabelecimento]);

  useEffect(() => {
    actionsMunicipioSelected();
  }, [watch('municipio'), isSuperAdmin, isMunicipal, isPadraoEstabelecimento]);

  useEffect(() => {
    actionsEstabelecimentoSelected();
  }, [
    watch('estabelecimento'),
    isSuperAdmin,
    isMunicipal,
    isPadraoEstabelecimento,
  ]);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={3} mt="2px">
        <Grid xs={12} md={4} item>
          <SelectWithSearchV2
            options={optionsEstados}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Estado"
            required
            setValue={setValue}
            inputName="estado"
            selectedOptions={watch('estado')}
            // setSelected
            // selectedOptions
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch
            disabled={disabledEstadoInput}
            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <SelectWithSearchV2
            options={optionsMunicipios}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Município"
            required
            setValue={setValue}
            inputName="municipio"
            selectedOptions={watch('municipio')}
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch
            disabled={disabledMunicipioInput}
            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <SelectWithSearchV2
            options={optionsEstabelecimentos}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Estabelecimento"
            required
            inputName="estabelecimento"
            setValue={setValue}
            selectedOptions={watch('estabelecimento')}
            // setSelected
            // selectedOptions
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch
            disabled={disabledEstabelecimentoInput}
            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <SelectWithSearchV2
            options={[]}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Agente Comunitário de Saúde (ACS)"
            required
            // setSelected
            // selectedOptions
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch
            // disabled
            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <Autocomplete
            options={['25 Anos', '26 Anos', '27 Anos', 'Fora da Faixa Etária']}
            renderInput={(params) => (
              <TextField {...params} label="Idade Início" size="small" />
            )}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <Autocomplete
            options={['61 Anos', '62 Anos', '63 Anos']}
            renderInput={(params) => (
              <TextField {...params} label="Idade Final" size="small" />
            )}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <SelectWithSearchV2
            options={[
              { label: 'Pendentes', value: 'Pendentes' },
              { label: 'Não Atendidas', value: 'Não Atendidas' },
              { label: 'Agendada', value: 'Agendada' },
              { label: 'Não Elegíveis', value: 'Não Elegíveis' },
            ]}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Status"
            required
            inputName="status"
            setValue={setValue}
            selectedOptions={watch('status')}
            // status
            // selectedOptions
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch
            // disabled
            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <SelectWithSearchV2
            options={[
              { label: '1º Convocação', value: '1º Convocação' },
              { label: '2º Convocação', value: '2º Convocação' },
              { label: '3º Convocação', value: '3º Convocação' },
              { label: 'Outras Convocações', value: 'Outras Convocações' },
            ]}
            inputSearchPlaceholder="Pesquise..."
            inputSearchLabel="Pesquisar"
            label="Convocação"
            required
            inputName="convocacao"
            setValue={setValue}
            selectedOptions={watch('convocacao')}
            // setSelected
            // selectedOptions
            // setSelectedOptions
            // dataTestSelectAll
            // dataTestClearAll
            // dataTestIdInputSearch
            // disabled
            emptyMessage=""
            emptyCondition={false}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PeriodoInput control={control} handleSetFilter={handleSetFilter} />
        </Grid>
        <Grid xs={12} md={9.4} item>
          <SearchByNameInput
            control={control}
            // disableOtherInputs={disableOtherInputs}
            errors={errors}
            handleSetFilter={handleSetFilter}
          />
        </Grid>
        <Grid item xs={12} md={1.3}>
          <Button
            sx={{ padding: '6px 22px' }}
            variant="contained"
            type="submit"
            data-testid="submitFilterReportsHpv"
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={12} md={1.3}>
          <Button
            sx={{ padding: '6px 22px' }}
            variant="outlined"
            color="error"
            // onClick={clearValues}
            data-testid="clearFilterReportsHpv"
          >
            Limpar
          </Button>
        </Grid>
      </Grid>

      <DisplayFilter style={{ marginTop: '26px', marginBottom: '37px' }}>
        <p style={{ margin: 0, width: '138px' }}>Filtros ativos:</p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            gap: '10px',
            flexWrap: 'wrap',
          }}
        >
          {/* {showListFilter.map(
                      (item, index) =>
                        item.value && (
                          <ItemsDisplayFilter key={index}>
                            {item.value}
                          </ItemsDisplayFilter>
                        )
                    )} */}
        </div>
      </DisplayFilter>
    </form>
  );
};

export default FiltersConvocation;
