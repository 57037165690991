import { Divider, Paper, Tooltip, Typography } from '@mui/material';
import './customizedFunnelBarStyles.scss';
import { useState } from 'react';

export const CustomizedFunnelBar = ({ data }) => {
  const [indexTooltip, setIndexTooltip] = useState<any>();
  const colors = {
    0: '#238884',
    1: '#F44336',
    2: '#3F51B5',
    3: '#9E9E9E',
  };

  const configItems = {
    0: {
      color: '#238884',
      width: '100%',

      formatShadow: 'polygon(11% 0px, 89% 0px, 100% 100%, 0px 100%)',
      positionTooltip: '150px',
    },
    1: {
      color: '#F44336',
      width: '78%',

      formatShadow: 'polygon(14% 0px, 86% 0px, 100% 100%, 0px 100%)',
      positionTooltip: '20px',
    },
    2: {
      color: '#3F51B5',
      width: '57%',

      formatShadow: 'polygon(43% 0px, 57% 0px, 100% 100%, 0px 100%)',
      positionTooltip: '80px',
    },
    3: {
      color: '#9E9E9E',
      width: '8.5%',

      formatShadow: 'polygon(11% 0px, 89% 0px, 100% 100%, 0px 100%)',
      positionTooltip: '-100px',
    },
  };

  const totalValueChart = data
    .map((item) => item.total)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {data.map((item, index) => {
        const percentage = String((item.total / totalValueChart) * 100).slice(
          0,
          2
        );
        return (
          <div
            onMouseOver={() => setIndexTooltip(index)}
            onMouseLeave={() => setIndexTooltip(null)}
            style={{
              position: 'relative',
              width: configItems[index].width,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {indexTooltip === index && (
              // {true && (
              <Paper
                className={`tooltipFunnel`}
                sx={{ zIndex: 1, right: configItems[index].positionTooltip }}
              >
                <p
                  style={{
                    backgroundColor: '#d3d3d35c',
                    color: 'black',
                    fontSize: '10px',
                    margin: '0',
                    padding: '10px',
                  }}
                >
                  {item.label}
                </p>
                <Divider />
                <div
                  style={{
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    fontSize: '10px',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: configItems[index].color,
                      height: '10px',
                      width: '10px',
                      borderRadius: '50%',
                      opacity: '2',
                      marginRight: '5px',
                    }}
                  />
                  {item.total} ({percentage}%)
                </div>
              </Paper>
            )}
            <div
              className="itemFunnelBar"
              style={{
                width: '100%',
                backgroundColor: configItems[index].color,
              }}
            >
              {colors[index] === '#9E9E9E'
                ? `${item.label.slice(0, 3)}...`
                : `${item.label}: ${item.total} (${percentage}%)`}
              {index + 1 < data.length && (
                <div
                  className="shadowFunnelBar"
                  style={{
                    backgroundColor: configItems[index].color,
                    clipPath: configItems[index].formatShadow,
                  }}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
