import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { ButtonIcon } from '../../../../../components/ModalStatus/ModalStatus.style';
import CloseIcon from '@mui/icons-material/Close';
import { StyledAsteriskTextField } from '../../../../../components/StyledAsteriskTextField/styles';

export interface SeuTipoDeRegistro {
  id: number;
  procedimento: {
    id: number;
    created_at: string;
    updated_at: string;
    status: string;
    dt_coleta: string;
    categoria: {
      id: number;
      nome: string;
    };
  };
  agendado_em: string;
  convocado_em: string;
  n_convocacao: string;
  pessoa: {
    id: number;
    created_at: string;
    updated_at: string;
    nome: string;
    apelido: string | null;
    cpf: string | null;
    dt_nascimento: string;
    cns: string;
    raca: string | null;
    nacionalidade: string | null;
    nome_mae: string | null;
    sexo: string | null;
    contato_preferencial: string | null;
    escolaridade: string | null;
    orientacao: string | null;
    identidade_genero: string | null;
    conv_inativo_sexo: string | null;
    conv_mudou_endereco: string | null;
    conv_fora_idade: string | null;
    conv_histerectomizada: string | null;
    conv_outros: string | null;
    endereco: string | null;
    estabelecimento_saude: any;
  };
  profissional: {
    id: number;
    created_at: string;
    updated_at: string;
    cnes: string;
    cargo: string;
    cbo: string | null;
    pessoa: {
      id: number;
      created_at: string;
      updated_at: string;
      nome: string;
      apelido: string | null;
      cpf: string | null;
      dt_nascimento: string;
      cns: string;
      raca: string | null;
      nacionalidade: string | null;
      nome_mae: string | null;
      sexo: string | null;
      contato_preferencial: string | null;
      escolaridade: string | null;
      orientacao: number | null;
      identidade_genero: number | null;
      conv_inativo_sexo: boolean | null;
      conv_mudou_endereco: boolean | null;
      conv_fora_idade: boolean | null;
      conv_histerectomizada: boolean | null;
      conv_outros: string | null;
    };
  };
}

interface AgendadaModalProps {
  open: boolean;
  onClose: () => void;
  registro: SeuTipoDeRegistro;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 577,
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 24,
  padding: '16px 24px',
};

function AgendadaModal({ open, onClose, registro }: AgendadaModalProps) {
  const date = registro?.agendado_em;
  const dateFormatted = date?.split('T')[0].split('-').reverse().join('/');
  const hourFormatted = date?.split('T')[1].split('.')[0];
  const nome_profissional = registro?.profissional?.pessoa?.nome;
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h6"
            fontWeight="bold"
          >
            Busca ativa
          </Typography>
          <ButtonIcon onClick={onClose}>
            <CloseIcon fontSize="small" style={{ color: '#BDBDBD' }} />
          </ButtonIcon>
        </Box>
        <Box
          style={{
            border: '1px solid #bdbdbd',
            borderRadius: '8px',
            padding: '16px',
            marginBottom: '20px',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
            <img src={'/exams.svg'} alt="exams" width={50} />
            <Typography fontWeight={700} fontSize={20}>
              {registro?.pessoa?.nome}
            </Typography>
          </Box>
          <Divider style={{ marginTop: 10 }} />
          <Typography fontWeight={700} fontSize={20} style={{ marginTop: 10 }}>
            Dados da convocação
          </Typography>
          <Typography fontWeight={400} fontSize={16} mt={2} mb={0}>
            Resultados da convocação <span style={{ color: '#f88078' }}>*</span>
          </Typography>
          <FormControl>
            <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
              <FormControlLabel
                style={{ marginRight: '14px' }}
                value="sim"
                checked={true}
                control={<Radio style={{ fontSize: '14px', padding: '3px' }} />}
                label={<Typography fontSize={16}>Agendada</Typography>}
              />
              <FormControlLabel
                value="naoAtendida"
                disabled
                control={<Radio />}
                label={<Typography fontSize={16}>Não atendida</Typography>}
              />
              <FormControlLabel
                value="inelegivel"
                disabled
                control={<Radio />}
                label={<Typography fontSize={16}>Não inelegível</Typography>}
              />
            </RadioGroup>
          </FormControl>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <StyledAsteriskTextField
                required
                label="Paciente"
                variant="outlined"
                value={registro?.pessoa?.nome}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <StyledAsteriskTextField
                required
                label="Exame"
                variant="outlined"
                value={'HPV'}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <StyledAsteriskTextField
                required
                label="UBS"
                value={registro?.pessoa?.estabelecimento_saude?.nome_fantasia}
                variant="outlined"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <StyledAsteriskTextField
                required
                label="Profissional"
                value={nome_profissional ?? '-'}
                variant="outlined"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sx={{ marginTop: '10px' }}>
              <StyledAsteriskTextField
                required
                label="Data"
                variant="outlined"
                value={dateFormatted ?? '-'}
                disabled
              />
            </Grid>
            <Grid item xs={6} sx={{ marginTop: '10px' }}>
              <StyledAsteriskTextField
                required
                label="Horário"
                variant="outlined"
                value={hourFormatted ?? '-'}
                disabled
              />
            </Grid>
          </Grid>
        </Box>
        <Box></Box>
      </Box>
    </Modal>
  );
}

export default AgendadaModal;
